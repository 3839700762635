import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import TermsPage from '../../scenes/shared/terms';

export default function ExternalLinkViewer({ open, fileUrl, setOpen }) {
    return (
        <Dialog
            onClose={() => setOpen(false)}
            maxWidth={"xl"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
                "& .MuiPaper-root": {
                    margin: 0,
                    width: "calc(100% - 20px)",
                    zIndex: 9999999,
                    background: 'transparent'
                },
            }}
            open={open}>
            <DialogTitle>
                <div className='dialog-head mb-4'>
                    <div className='dialog-actions close-only'
                        style={{ left: '50%', top: '5px', transform: 'translateX(-50%)' }}>
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                setOpen(false);
                            }}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                                background: '#fff'
                            }}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                {fileUrl === "terms" ? (
                    <div style={{ height: '100vh', overflow: 'auto', border: "none", background: '#fff' }}>
                        <TermsPage popup />
                    </div>
                ) : (
                    <>
                        <iframe
                            src={fileUrl}
                            width="100%"
                            style={{ height: '100vh', border: "none" }}
                        >
                        </iframe>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
