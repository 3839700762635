import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAgentsForCompany } from "../../../../api/userApi";
import Avatar from "@mui/material/Avatar";
import LoadingData from "../../../../components/global-components/loading-data";
import NoData from "../../../../components/global-components/no-data";
import { Buildings, Mail, Pen, Tel } from "../../../../constants/icons";
import { useAuth } from "../../../../helpers/context";
import { ClickAwayListener, Grid, Grow, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import AgentCardForAdmin from "../../../admin/agents/agent-card-for-admin";
import { useTranslation } from "react-i18next";
import ManagePermissionsDialog from "./manage-permissions-dialog";

const AgentsPage = ({ managePermissions, ...props }) => {
  const { token, vendor } = useAuth();
  const [Agents, setAgents] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { setTotalCount } = props;
  const isAdmin = window.location.href.includes('admin');
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState();
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [permissionsDialog, setPermissionsDialog] = useState({
    open: false,
    id: undefined,
    createdAt: undefined
  });

  useEffect(() => {
    if (token)
      getAgentsForCompany({ superUserId: token, language: i18n.language === "ar" ? '0' : '1' }).then((users) => {
        setAgents(users.response.userArray);
        setLoadingData(false);
        setTotalCount?.(users.response.userArray.length);
      });
  },// eslint-disable-next-line
    [token]);

  return (
    loadingData ? <LoadingData /> : isAdmin ? (
      <Grid container gap={2} sx={{width:'100%',margin:0}} alignItems={"center"} justifyContent={"center"}>
        {Agents?.length > 0 ? (
          <>
            {Agents.map((item, i) => (
              <React.Fragment key={i}>
                <Grid item xs={11} md={6} lg={4} xl={3} key={i} className="custom-grid-item">
                  <AgentCardForAdmin
                    item={item}
                    managePermissions={managePermissions}
                    setOpenPopper={setOpenPopper}
                    setAnchorEl={setAnchorEl}
                    openPopper={openPopper}
                    setPermissionsDialog={setPermissionsDialog}
                    anchorEl={anchorEl}
                    setSelectedAgent={setSelectedAgent}
                  />
                </Grid>
                {!managePermissions && (
                  <Popper
                    sx={{
                      zIndex: 100,
                      offset: '55px 0 0 5px',
                    }}
                    open={openPopper}
                    role={undefined}
                    transition
                    anchorEl={anchorEl}
                    disablePortal={false}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={(event) => {
                            if (event.target.nodeName === 'BODY' && event.type === 'click') {
                              return;
                            } else {
                              setAnchorEl(null); setOpenPopper(false);
                            }
                          }}>
                            <List>
                              <ListItem disablePadding>
                                <ListItemButton
                                  sx={{ padding: '2px 15px' }}
                                  onClick={() => {
                                    history(`/admin/user-profile/${selectedAgent.userId}`)
                                  }}>
                                  <ListItemText primary={t("ViewDetails")} />
                                </ListItemButton>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemButton onClick={() => {
                                  history(`/admin/edit-agent/${selectedAgent.userId}`)
                                }}
                                  sx={{ padding: '2px 15px' }}
                                >
                                  <ListItemText primary={t("Edit")} />
                                </ListItemButton>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemButton onClick={() => {
                                  setPermissionsDialog({
                                    open: true,
                                    id: selectedAgent.userId,
                                    createdAt: selectedAgent.createdAt
                                  });
                                }}
                                  sx={{ padding: '2px 15px' }}
                                >
                                  <ListItemText primary={t("ManagePermissions")} />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
              </React.Fragment>
            ))}
            {selectedAgent?.userId && (
              <ManagePermissionsDialog
                dialog={permissionsDialog}
                setDialog={setPermissionsDialog}
              />
            )}
          </>) :
          <NoData msg={t("NoAgentsFound")} />
        
        }
      </Grid>
    ) : (
      <div className='row justify-content-center agents-wrapper'>
        {Agents?.length > 0 ? (
          Agents.map((item, i) => (
            <div key={i} className='col-12 col-md-6 col-lg-4 col-xl-3 mb-4'>
              <div className='company-box'>
                <Link
                  className="d-block h-100 position-relative"
                  to={`/${vendor === 'amakkn' ? 'user-profile' : 'admin/user-profile'}/${item.userId}`}>
                  <>
                    <div className="company-logo">
                      {item.avatar ? (
                        <Avatar alt={item.name} src={item.avatar} sx={{ bgcolor: '#fff' }} variant='circular' />
                      ) : (
                        <Avatar variant='circular' >{item.name.substring(0, 1)}</Avatar>
                      )}
                    </div>
                    <h4 className='company-name'>{item.name}</h4>
                    <div className="company-statistics">
                      <p><Mail />&nbsp; {item.email || t("NotAvailable")}</p>
                      <p><Tel />&nbsp;
                        <a
                          style={{ direction: 'ltr', display: 'inline-block' }}
                          rel="noreferrer"
                          href={`tel:${item.countryCode === "+966" ? "0" : item.countryCode} ${item.phone}`}
                          target='_blank'>{item.countryCode === "+966" ? "0" : item.countryCode}{item.phone}
                        </a>
                      </p>
                      <p><Buildings />{item.activeProperties}</p>
                    </div>
                    <Link
                      className="block-btn"
                      to={`/${vendor === 'amakkn' ? 'edit-agent' : 'admin/edit-agent'}/${item.userId}`}>
                      <Pen /> {t("Edit")}
                    </Link>
                  </>
                </Link>
              </div>
            </div>
          ))) :
          <NoData msg={t("NoAgentsFound")} />
        }
      </div>
    )
  );
}

export default AgentsPage;
