import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../Config/Firebase";

import LoginPopup from "../user-components/login-popup";
import { toast } from "react-toastify";
import { getUserProile, setLanguageForUser } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import {
  Buildings,
  Check,
  MessageQuestion,
  Messages,
  ProIcon,
  Question,
  User,
  Users,
} from "../../constants/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import VerifyPopup from "../user-components/verify-popup";
import Compare from "@mui/icons-material/Compare";
import { useAuth } from "../../helpers/context";
import {
  AppBar,
  Box,
  Container,
  MenuItem,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Avatar,
  Grow,
  Divider,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RedeemIcon from "@mui/icons-material/Redeem";
import { ThemeProvider } from "@mui/styles";
import { Close } from "@mui/icons-material";
import {
  AppleStoreBtn,
  GooglePlayBtn,
  HuaweiAppGalleryBtn,
} from "../../constants/assets";
import i18next from "i18next";
import LanguageIcon from "@mui/icons-material/Language";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";

const publicUrl = process.env.REACT_APP_URL + "/";

export function LinkTo(props) {
  return (
    <Link
      to={props.linkTo}
      className={`btn menu-item ${
        props.activationLinks &&
        props.activationLinks.includes(window.location.pathname)
          ? "active"
          : ""
      } ${props.component ? "default-color" : ""} `}>
      <span className='right '>
        {typeof props.icon === "string" ? (
          <i className={props.icon} />
        ) : props.component ? (
          props.icon
        ) : (
          <props.icon />
        )}
      </span>
      <span>{props.name}</span>
    </Link>
  );
}

function LinkTo2(props) {
  return (
    <Link
      to={props.linkTo}
      className={`${props.classes || ""} ${
        props.activationLinks?.includes("/")
          ? props.activationLinks?.includes(window.location.pathname)
            ? "active-link"
            : ""
          : props.activationLinks.filter((i) =>
              window.location.pathname.includes(i)
            )?.length > 0
          ? "active-link"
          : ""
      } ${props.component ? "default-color" : ""} `}>
      {
        <span>
          {typeof props.icon === "string" ? (
            <i className={props.icon} />
          ) : props.component ? (
            props.icon
          ) : (
            <props.icon />
          )}
        </span>
      }
      <span>{props.name}</span>
    </Link>
  );
}

export function LinkToMenu(props) {
  const { token } = useAuth();
  return (
    <Link
      to={props.linkTo}
      className={
        props.activationLinks &&
        props.activationLinks.filter(
          (i) => window.location.href.indexOf(i) > -1
        ).length > 0
          ? `btn menu-item ${
              token ? "v2" : props.withoutPadding ? "" : "v2"
            } active`
          : `btn ${token ? "v2" : props.withoutPadding ? "" : "v2"} menu-item`
      }>
      <span className='right '>
        {typeof props.icon === "string" ? (
          <i className={props.icon} />
        ) : (
          <props.icon />
        )}
      </span>
      <span>{props.name}</span>
    </Link>
  );
}

export default function AmakknNavbar({ regaNavMode }) {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const { token, setToken, setUserId, setUserType, UserType } = useAuth();
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [accountType, setaccountType] = useState();
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [user, setUser] = useState();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  // if (token)
  //   onMessageListener()
  //     .then((payload) => {
  //       toast.dark(
  //         payload.notification.title + ": " + payload.notification.body
  //       );
  //     })
  //     .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (token) {
      getUserProile({
        userId: token,
        language: i18n.language === "ar" ? "0" : "1",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setUser(_userInfo.response.userProfile);
          setUserType(_userInfo.response.userProfile.userType);
          setaccountType(_userInfo.response.userProfile.accountType);
          if (_userInfo.response.userProfile.userType !== "1")
            if (_userInfo.response.userProfile.isUserVerified !== "3") {
              setIsUserVerified(false);
            }
          // if (_userInfo.response.userProfile.isWLUser)
          //   setISWLUser(_userInfo.response.userProfile.isWLUser);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const renderLoginPopup = (path, text, icon) => {
    return (
      <LoginPopup
        setToken={setToken}
        setUserId={setUserId}
        settext={text}
        icon={icon}
        path={path}
      />
    );
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) setScrolled(true);
    else setScrolled(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const links = [
    // {
    //   linkTo: "/",
    //   activationLinks: ["/"],
    //   icon: HomeIcon,
    //   name: t("Home"),
    //   hideForAgents: false,
    //   needAuth: false
    // },
    {
      linkTo: "/my-favorite",
      activationLinks: ["/my-favorite"],
      icon: FavoriteBorderIcon,
      name: t("Favorites"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/amakkn-pro",
      activationLinks: ["/amakkn-pro"],
      icon: ProIcon,
      name: t("AmakknPro"),
      hideForAgents: false,
      needAuth: false,
    },
    {
      linkTo: "/plans-and-prices",
      activationLinks: ["/plans-and-prices"],
      icon: WorkspacePremiumIcon,
      name: t("PlansAndPricing"),
      hideForAgents: true,
      needAuth: false,
    },
    {
      linkTo: "/Contact",
      activationLinks: ["/Contact"],
      icon: Question,
      name: t("ContactUs"),
      hideForAgents: false,
      needAuth: false,
    },
  ];

  const extraLinks = [
    {
      linkTo: "/my-profile",
      activationLinks: ["/my-profile"],
      icon: User,
      name: t("MyProfile2"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/my-subscription",
      activationLinks: ["/my-subscription"],
      icon: AppRegistrationIcon,
      name: t("MySubscription"),
      hideForAgents: true,
      needAuth: true,
    },
    {
      linkTo: "/my-listing",
      activationLinks: ["/my-listing"],
      icon: Buildings,
      name: t("MyProperties"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/comparison-list",
      activationLinks: ["/comparison-list"],
      icon: Compare,
      name: t("Comparison"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/verify",
      activationLinks: ["/verify"],
      icon: Check,
      name: t("AccountVerification"),
      hideForAgents: false,
      needAuth: true,
      needVerification: true,
    },
    {
      linkTo: "/agents",
      activationLinks: ["/agents"],
      icon: Users,
      name: t("Agents"),
      hideForAgents: true,
      needAuth: true,
      accountType: "2",
    },
    {
      linkTo: "/Chats",
      activationLinks: ["/Chats"],
      icon: Messages,
      name: t("Messages"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/Tickets",
      activationLinks: ["/Tickets"],
      icon: MessageQuestion,
      name: t("HelpCenter"),
      hideForAgents: false,
      needAuth: true,
    },
    {
      linkTo: "/promo-codes",
      activationLinks: ["/promo-codes"],
      icon: RedeemIcon,
      name: t("PromoCodes"),
      hideForAgents: false,
      needAuth: true,
    },
  ];

  const handleSignOut = () => {
    setOpenPopper(false);
    setOpenDrawer(false);
    toast.success(t("YouHaveSuccessfullyLoggedOut"));
    history("/");
    setToken(undefined);
    setUserId(undefined);
    setUserType(undefined);
    setaccountType(undefined);
    document.cookie.split(";").forEach(function (c) {
      if (!c.includes("AmmaknConsent"))
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    sessionStorage?.clear?.();
    localStorage?.clear?.();
  };

  const ProfilePopper = () => (
    <Popper
      sx={{
        zIndex: 100,
        offset: "65px 0 0",
      }}
      open={openPopper}
      role={undefined}
      transition
      anchorEl={anchorEl2}
      // disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "center bottom" }}>
          <Paper>
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl2(null);
                setOpenPopper(false);
              }}>
              <Box sx={{ minWidth: "250px" }}>
                {token && (
                  <>
                    <Box
                      className='d-flex flex-column align-items-center justify-content-center'
                      sx={{ padding: "15px 10px", gap: "10px" }}>
                      <Avatar
                        src={user?.avatar}
                        variant='circular'
                        sx={{
                          width: 80,
                          height: 80,
                          background: user?.avatar ? "#fff" : "#E4E4E4",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                        className='avatar-contained'
                        alt={user?.name || "profile image"}
                      />
                      <Box
                        className='d-flex flex-column align-items-center justify-content-center'
                        sx={{ gap: "10px" }}>
                        {user?.name}
                      </Box>
                    </Box>
                    <Divider variant='inset' className='w-100' />
                  </>
                )}
                <MenuList autoFocusItem className='profile-menu-items'>
                  {!regaNavMode ? (
                    <>
                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/my-profile")}>
                          {t("MyProfile2")}
                          <User />
                        </MenuItem>
                      )}
                      {token && UserType !== "5" && isUserVerified && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/my-subscription")}>
                          {t("MySubscription")}
                          <AppRegistrationIcon />
                        </MenuItem>
                      )}

                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/my-listing")}>
                          {t("MyProperties")}
                          <Buildings />
                        </MenuItem>
                      )}

                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/comparison-list")}>
                          {t("Comparison")}
                          <Compare />
                        </MenuItem>
                      )}
                      {!isUserVerified && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/verify")}>
                          {t("AccountVerification")}
                          <Check />
                        </MenuItem>
                      )}
                      {accountType === "2" && UserType && UserType !== "5" && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/agents")}>
                          {t("Agents")}
                          <Users />
                        </MenuItem>
                      )}

                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/Chats")}>
                          {t("Messages")}
                          <Messages />
                        </MenuItem>
                      )}

                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/Tickets")}>
                          {t("HelpCenter")}
                          <MessageQuestion />
                        </MenuItem>
                      )}
                      {token && (
                        <MenuItem
                          className='profile-menu-item'
                          onClick={() => history("/promo-codes")}>
                          {t("PromoCodes")}
                          <RedeemIcon />
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {token && (
                    <MenuItem
                      className='profile-menu-item text-danger'
                      onClick={handleSignOut}>
                      {t("LogOut")}
                      <LogoutIcon />
                    </MenuItem>
                  )}
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const getMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  function handleGoogleClick() {
    // logEvent(analytics, "play_store_website", { platform: "website" });
    window.open(
      "https://play.google.com/store/apps/details?id=com.amakknapp.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      "_blank"
    );
  }

  function handleAppleClick() {
    // logEvent(analytics, "app_store_website", { platform: "website" });
    window.open(
      "https://itunes.apple.com/us/app/amakkn/id1220303072",
      "_blank"
    );
  }

  function handleHuaweiClick() {
    window.open("https://url.cloud.huawei.com/kcZJ8WHuHm", "_blank");
  }

  return (
    <>
      {!isLargeScreen ? (
        <>
          <AppBar
            position='fixed'
            className={`navbar-area ${scrolled ? "scrolled" : ""}`}
            sx={{
              bgcolor: "#fff",
              boxShadow: scrolled
                ? "0px 4px 6px 0px rgba(12, 0, 46, 0.06)"
                : "none",
            }}>
            <Container maxWidth='xl' sx={{ padding: "0 !important" }}>
              <Toolbar
                disableGutters
                sx={{ width: "100%" }}
                className='nav-container'>
                <div />
                <div className={`logo readeal-top amakkn`}>
                  <Link to='/'>
                    <img
                      src={
                        i18n.language === "ar"
                          ? `/assets/img/logo/amakkn-logo.svg`
                          : `/assets/img/logo/amakkn-logo-en.png`
                      }
                      alt='logo'
                      style={i18n.language === "ar" ? {} : { width: "130px" }}
                      className='preview-logo-img'
                    />
                  </Link>
                </div>
                <div />
              </Toolbar>
            </Container>
          </AppBar>
          <nav className='bottom-navigation'>
            <ul className='list'>
              <li>
                <LinkTo2
                  linkTo='/'
                  activationLinks={["/"]}
                  icon={<HomeIcon />}
                  component
                  classes='link'
                  name={t("Home")}
                />
              </li>
              <li>
                <LinkTo2
                  linkTo='/my-favorite'
                  activationLinks={["/my-favorite"]}
                  icon={<FavoriteBorderIcon />}
                  component
                  classes='link'
                  name={t("Favorites")}
                />
              </li>
              <li>
                <LinkTo2
                  linkTo='/amakkn-pro'
                  activationLinks={["/amakkn-pro"]}
                  icon={<ProIcon />}
                  component
                  classes='link' //hideForAgents
                  name={t("AmakknPro")}
                />
              </li>
              {UserType !== "5" && (
                <>
                  <li>
                    <LinkTo2
                      linkTo='/plans-and-prices'
                      activationLinks={["/plans-and-prices"]}
                      icon={<WorkspacePremiumIcon />}
                      component
                      classes='link' //hideForAgents
                      name={t("Plans1")}
                    />
                  </li>
                </>
              )}

              <li>
                <button
                  className='expand'
                  style={{ gap: "10px" }}
                  onClick={() => setOpenDrawer(true)}>
                  <span className='expand-icon mx-auto'>
                    <AppsIcon />
                  </span>
                  <span style={{ fontSize: "14px", color: "#212121" }}>
                    {t("More")}
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <>
          <AppBar
            position='fixed'
            className={`navbar-area ${scrolled ? "scrolled" : ""}`}
            sx={{
              bgcolor: "#fff",
              boxShadow: scrolled
                ? "0px 4px 6px 0px rgba(12, 0, 46, 0.06)"
                : "none",
            }}>
            <Container maxWidth='xl' sx={{ padding: "0 !important" }}>
              <Toolbar
                disableGutters
                sx={{ width: "100%" }}
                className='nav-container'>
                {!regaNavMode && (
                  <Box sx={{ display: { xs: "flex", lg: "none" } }}>
                    <IconButton
                      size='large'
                      aria-label='collapse menu'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={() => setOpenDrawer(true)}
                      sx={{ color: "var(--main-color-one)", padding: 0 }}>
                      <MenuIcon sx={{ width: "1.25em", height: "1.25em" }} />
                    </IconButton>
                  </Box>
                )}

                <div className={`logo readeal-top amakkn`}>
                  <Link to='/'>
                    <img
                      src={
                        i18n.language === "ar"
                          ? `/assets/img/logo/amakkn-logo.svg`
                          : `/assets/img/logo/amakkn-logo-en.png`
                      }
                      alt='logo'
                      style={i18n.language === "ar" ? {} : { width: "130px" }}
                      className='preview-logo-img'
                    />
                  </Link>
                </div>

                {!regaNavMode && (
                  <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                    {links.map((link) =>
                      UserType === "5" && link.hideForAgents ? null : (
                        <Button
                          key={link.name}
                          sx={{ my: 2, color: "white", display: "block" }}>
                          {token || (!link.needAuth && !token) ? (
                            <LinkTo
                              linkTo={link.linkTo}
                              activationLinks={link.activationLinks}
                              icon={link.icon}
                              name={link.name}
                            />
                          ) : (
                            !token &&
                            renderLoginPopup(link.linkTo, link.name, link.icon)
                          )}
                        </Button>
                      )
                    )}

                    {/* {process.env.REACT_APP_API_URL?.includes('devsa2') && ( */}
                    <Button
                      key={"lng"}
                      sx={{ my: 2, display: "block" }}
                      onClick={() => {
                        if (i18n.language === "ar") {
                          i18next.changeLanguage("en");
                          i18n.language = "en";
                          i18n.options.lng = "en";
                          localStorage?.setItem("i18nextLng", "en");
                          document.documentElement.setAttribute("lang", "en");
                        } else {
                          i18next.changeLanguage("ar");
                          i18n.language = "ar";
                          i18n.options.lng = "ar";
                          localStorage?.setItem("i18nextLng", "ar");
                          document.documentElement.setAttribute("lang", "ar");
                        }
                        if (token)
                          setLanguageForUser({
                            language: i18n.language === "ar" ? "0" : "1",
                            userId: token,
                          });
                      }}>
                      <span className='btn menu-item without-stroke'>
                        <span className='right '>
                          <LanguageIcon />
                        </span>
                        <span>
                          {i18n.language === "ar" ? "English" : "العربيّة"}
                        </span>
                      </span>
                    </Button>
                    {/* )} */}
                  </Box>
                )}

                <ProfilePopper />

                {!regaNavMode && (
                  <div
                    className='d-none d-lg-flex align-items-center mx-0'
                    style={{ gap: "10px" }}>
                    {token ? (
                      isUserVerified ? (
                        <Link
                          className='btn btn-yellow btn-rounded d-flex'
                          to={"/add-property"}>
                          {t("AddProperty")}
                        </Link>
                      ) : (
                        <VerifyPopup
                          openVerifyModal={openVerifyModal}
                          setOpenVerifyModal={setOpenVerifyModal}
                          desc={t(
                            "YouMustVerifyYourAccountInOrderToAddYourRealEstateAD"
                          )}
                          trigger={
                            <button
                              className='btn btn-yellow btn-rounded d-flex'
                              onClick={() => setOpenVerifyModal(true)}>
                              {t("AddProperty")}
                            </button>
                          }
                        />
                      )
                    ) : (
                      <LoginPopup
                        setToken={setToken}
                        setUserId={setUserId}
                        path='/add-property'>
                        <span className='btn btn-yellow btn-rounded'>
                          {t("AddProperty")}
                        </span>
                      </LoginPopup>
                    )}

                    {!token ? (
                      <LoginPopup setToken={setToken} setUserId={setUserId}>
                        <IconButton
                          edge={i18n.language === "ar" ? "start" : "end"}
                          sx={{ p: 0 }}>
                          <Avatar
                            variant='circular'
                            sx={{
                              color: "#333",
                              background: user?.avatar ? "#fff" : "#E4E4E4",
                              width: "45px",
                              borderRadius: "inherit",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              height: "45px",
                            }}
                            className='avatar-contained'
                            alt='profile image'
                          />
                        </IconButton>
                      </LoginPopup>
                    ) : (
                      <IconButton
                        edge={i18n.language === "ar" ? "start" : "end"}
                        sx={{ p: 0 }}
                        onClick={(e) => {
                          setOpenPopper(!openPopper);
                          setAnchorEl2(anchorEl2 ? null : e.target);
                        }}>
                        <Avatar
                          variant='circular'
                          sx={{
                            color: "#333",
                            background: user?.avatar ? "#fff" : "#E4E4E4",
                            width: "45px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            borderRadius: "inherit",
                            height: "45px",
                          }}
                          src={user?.avatar}
                          className='avatar-contained'
                          alt={user?.name || "profile image"}
                        />
                      </IconButton>
                    )}
                  </div>
                )}
                {regaNavMode && token && (
                  <IconButton
                    edge={i18n.language === "ar" ? "start" : "end"}
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      setOpenPopper(!openPopper);
                      setAnchorEl2(anchorEl2 ? null : e.target);
                    }}>
                    <Avatar
                      variant='circular'
                      sx={{
                        color: "#333",
                        background: user?.avatar ? "#fff" : "#E4E4E4",
                        width: "45px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        borderRadius: "inherit",
                        height: "45px",
                      }}
                      src={user?.avatar}
                      className='avatar-contained'
                      alt={user?.name || "profile image"}
                    />
                  </IconButton>
                )}
                {!regaNavMode && <div className='d-flex d-lg-none' />}
              </Toolbar>
            </Container>
          </AppBar>
        </>
      )}
      <ThemeProvider
        theme={(outerTheme) => ({
          ...outerTheme,
          direction: i18n.language === "ar" ? "ltr" : "rtl",
        })}>
        <Drawer
          anchor={"right"}
          sx={{ zIndex: 999999 }}
          className='nav-drawer'
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}>
          <Close
            onClick={() => setOpenDrawer(false)}
            sx={{
              zIndex: 99,
              top: "26px",
              position: "absolute",
              left: "20px",
              cursor: "pointer",
            }}
          />
          <div className='main-sidebar'>
            <MenuItem
              className='main-li'
              key={"login"}
              sx={{
                padding: 0,
                width: "100%",
                minHeight: "100px !important",
                height: "auto",
              }}>
              <div
                className='d-flex w-100 py-5 align-items-center justify-content-center'
                style={{ gap: "10px" }}>
                <Avatar
                  src={user?.avatar}
                  variant='rounded'
                  sx={{
                    width: 105,
                    height: 105,
                    background: user?.avatar ? "#fff" : "#E4E4E4",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                  className='avatar-contained'
                  alt={user?.name || "profile image"}
                />

                <div
                  className='d-flex flex-column w-100 '
                  style={{ gap: "10px" }}>
                  {token ? (
                    <>
                      <span style={{ fontWeight: 600 }}>{user?.name}</span>
                      <span>
                        {user?.userType === "3"
                          ? t("IndividualsAccount")
                          : t("BusinessAccount")}
                        {user?.isUserVerified >= 2 && (
                          <img
                            src={publicUrl + "assets/img/icons/verfiy.svg"}
                            alt='image2'
                            style={{ margin: "0 2px" }}
                          />
                        )}
                      </span>
                      <span style={{ display: "block", direction: "ltr" }}>
                        {(user?.countryCode ? `(${user?.countryCode}) ` : "") +
                          user?.phone}
                      </span>
                    </>
                  ) : (
                    <>
                      <LoginPopup
                        setToken={setToken}
                        block
                        onOK={() => setOpenDrawer(false)}
                        setUserId={setUserId}>
                        <button className='btn btn-primary w-100'>
                          {t("Login")}
                        </button>
                      </LoginPopup>

                      <Link
                        className='btn outlined-btn w-100 d-flex justify-content-center small-raduis'
                        to={"/registration"}>
                        {t("Register")}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </MenuItem>

            {[...links, ...extraLinks].map((link) =>
              (UserType === "5" && link.hideForAgents) ||
              (isUserVerified && link.needVerification) ||
              (link.accountType === "2" && accountType !== "2") ? null : (
                <MenuItem sx={{ padding: 0 }} key={link.name}>
                  {token || (!link.needAuth && !token) ? (
                    <LinkTo
                      linkTo={link.linkTo}
                      activationLinks={link.activationLinks}
                      icon={link.icon}
                      name={link.name}
                    />
                  ) : (
                    link.needAuth &&
                    !token &&
                    renderLoginPopup(link.linkTo, link.name, link.icon)
                  )}
                </MenuItem>
              )
            )}

            {/* {process.env.REACT_APP_API_URL?.includes('devsa2') && ( */}
            <MenuItem
              key={"lang"}
              sx={{ padding: 0 }}
              onClick={() => {
                if (i18n.language === "ar") {
                  i18next.changeLanguage("en");
                  i18n.language = "en";
                  i18n.options.lng = "en";
                  localStorage?.setItem("i18nextLng", "en");
                  document.documentElement.setAttribute("lang", "en");
                } else {
                  i18next.changeLanguage("ar");
                  i18n.language = "ar";
                  i18n.options.lng = "ar";
                  localStorage?.setItem("i18nextLng", "ar");
                  document.documentElement.setAttribute("lang", "ar");
                }
                if (token)
                  setLanguageForUser({
                    language: i18n.language === "ar" ? "0" : "1",
                    userId: token,
                  });
              }}>
              <span className='btn menu-item without-stroke'>
                <span className='right'>
                  <LanguageIcon />
                </span>
                <span>{i18n.language === "ar" ? "English" : "العربيّة"}</span>
              </span>
            </MenuItem>
            {/* )} */}

            {token && (
              <MenuItem
                key={"logout"}
                sx={{ padding: 0 }}
                onClick={handleSignOut}>
                <span className='btn menu-item text-danger'>
                  <span className='right'>
                    <LogoutIcon />
                  </span>
                  <span>{t("LogOut")}</span>
                </span>
              </MenuItem>
            )}
            <MenuItem
              key={"download"}
              sx={{ padding: 0, gap: "15px" }}
              dir='rtl'
              className='d-flex flex-column h-100 align-items-end justify-content-start'>
              <h4 style={{ margin: "20px 0 0" }}>{t("DownloadAmakknApp")}</h4>
              <div
                className='btns-wrapper d-flex flex-column align-items-start justify-content-start mb-3'
                style={{ gap: "10px", width: "150px" }}>
                {getMobileOS() !== "unknown" ? (
                  getMobileOS() === "iOS" ? (
                    <AppleStoreBtn onClick={handleAppleClick} />
                  ) : (
                    <GooglePlayBtn onClick={handleGoogleClick} />
                  )
                ) : (
                  <>
                    <GooglePlayBtn onClick={handleGoogleClick} />
                    <AppleStoreBtn onClick={handleAppleClick} />
                    <HuaweiAppGalleryBtn onClick={handleHuaweiClick} />
                  </>
                )}
              </div>
            </MenuItem>
          </div>
        </Drawer>
      </ThemeProvider>
    </>
  );
}
