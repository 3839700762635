import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../helpers/context";
import { getUserProile } from "../../../api/userApi";
import AddNew from "./components/add-new";
import Forbidden from "../../shared/403";
import AppLayout from "../../../components/layouts/app-layout";

const AddUnlicensedAD = () => {
  const { token, setToken, setAccountType, setUserId, setUserType } = useAuth();
  let { propertyType, listingType, userId } = useParams();
  const { t, i18n } = useTranslation();
  const [UserVerified, setUserVerified] = useState({
    show: true,
    text: "",
    to: "",
  });
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState();
  
  const getUserData = (id) => {

    getUserProile({
      userId: id,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setToken(id);
        setUser(_userInfo.response.userProfile);
        setAccountType(_userInfo.response.userProfile?.accountType);
        setUserId(_userInfo.response.userProfile?.userId);
        setUserType(_userInfo.response.userProfile?.userType);
        
        if (_userInfo.response.userProfile.userType !== "1") {
          if (_userInfo.response.userProfile.isUserVerified === "3") {
            setMsg("Done");
            setUserVerified({
              show: false,
              text: _userInfo.response.userProfile.userType === '5' ? t("GoToHomePage") : t("UpgradeThePlan"),
              to: _userInfo.response.userProfile.userType === '5' ? '/' : "/plans-and-prices",
            });
               
          } else {
            setMsg(t("YourAccountIsNotVerified"));
            setUserVerified({
              show: false,
              text: t("AccountVerification"),
              to: "/verify",
            });
          }
        } else {
          setMsg(
            "أنت مستخدم عادي! يجب عليك تغير نوع المستخدم (لمالك عقار / مُسوّق مُفوّض / حساب أعمال) لكي تتمكن من إضافة إعلانك العقاري جديد في أماكن"
          );
          setUserVerified({
            show: false,
            text: t("MyProfile2"),
            to: "/my-profile",
          });
        }
      } else {
        setMsg(t("UserNotFound"));
        setUserVerified({
          show: false,
          text: t("HomePage"),
          to: "/",
        });
        setToken(undefined);
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
        setTimeout(() => {
          let cookiesWrapper = document.querySelector(".CookieConsent");
          if (cookiesWrapper) cookiesWrapper.style.display = "none";
        }, 200);
      } else if (token) {
        getUserData(token);
      }
    }, // eslint-disable-next-line
    [token, userId]
  );

  useEffect(
    () => {
      if (userId) {
        let cookiesWrapper = document.querySelector(".CookieConsent");
        if (cookiesWrapper) cookiesWrapper.style.display = "none";
      }
    }, // eslint-disable-next-line
    [userId]
  );

  return (
    <AppLayout needAuth pageTitle={t("AddRealEstateAD")} withoutNav={true}>
      {msg === "Done" ? (
        <div
          className='add-new-property-area px-4 pb-4'
          style={{ marginTop: 0 }}
        >
          <AddNew
            user={user}
            userId={token}
            isAdmin={true}
            withoutNavbar={userId ? true : false}
            propertyType={propertyType || ""}
            listingType={listingType || ""}
          />
        </div>
      ) : (
        UserVerified.show === false && (
          <Forbidden
            btnLink={UserVerified.to}
            btnText={UserVerified.text}
            desc={msg}
          />
        )
      )}
    </AppLayout>
  );
}

export default AddUnlicensedAD;
