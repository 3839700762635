// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

// import firebase from "firebase/app";
// import "firebase/messaging";

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
// import { isWebView } from "../helpers";

export const firebaseConfig = {
    //   // apiKey: "AIzaSyBHEdJYUhC4TUpnv2J1dnJ_2S7WBV2uIY0",
    //   // authDomain: "amakkn-59b4c.firebaseapp.com",
    //   // databaseURL: "https://amakkn-59b4c.firebaseio.com",
    //   // projectId: "amakkn-59b4c",
    //   // storageBucket: "amakkn-59b4c.appspot.com",
    //   // messagingSenderId: "438426910927",
    //   // appId: "1:438426910927:web:9875bb6de4cc7b80",
    //   // measurementId: "G-0PH7EQ92TM",
    apiKey: "AIzaSyB6UKifDM6HiICSdZigTkb264LK079_YP0",
    authDomain: "amakkn-59b4c.firebaseapp.com",
    databaseURL: "https://amakkn-59b4c.firebaseio.com",
    projectId: "amakkn-59b4c",
    storageBucket: "amakkn-59b4c.appspot.com",
    messagingSenderId: "438426910927",
    appId: "1:438426910927:web:d930ebd1130fa1ab5fb7ba",
    measurementId: "G-3M5M7X3DRZ"
};

export const VapidKey = "BFRdqlGeBkFONm3scpmU_jKp-L6jZJ3-FOULDR2mSxfa_4ZL27vHg-IxuB84jHIK-1h2mtiPph0tYcqrFH9Y0AM";

// // Dev
// // const firebaseConfig = {
// //   apiKey: "AIzaSyCYbX7lYFkr1d-DjBwKywnPSxMLKno3Mg0",
// //   authDomain: "amakkn-dev.firebaseapp.com",
// //   databaseURL: "https://amakkn-dev.firebaseio.com",
// //   projectId: "amakkn-dev",
// //   storageBucket: "amakkn-dev.appspot.com",
// //   messagingSenderId: "210365158326",
// //   appId: "1:210365158326:web:f094ac042516bf37e5a717",
// //   measurementId: "G-X24KTYJYEC",
// // };
// // const VapidKey =  "BOg_Ej5eN1gm5mtOQ3A_KZIcy9w0RblqbVBBeDSYbc7bNoAfx3UJuGb-7ojaXbMfDLb64D6AVkgapvpxMhuMOrI";

// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// const analytics = getAnalytics(app);

// // Listen for incoming messages
// export const listenForNotifications = () => {
//   onMessage(messaging, (payload) => {
//     console.log("Message received. ", payload);
//     // Handle the notification payload here (show the message to the user)
//   });
// };

// export const requestPermission = async (setTokenFound) => {
//     try {
//         const permission = await Notification.requestPermission();
//         if (permission === "granted") {
//             // const token = await messaging.getToken({
//             //     vapidKey: VapidKey,
//             // });
//             const token = await getToken(messaging, {
//                 vapidKey: VapidKey,
//               });
//             console.log("FCM Token:", token);
//             setTokenFound(token);
//             return token; // Return the token for sending notifications from the backend
//         } else {
//             console.log("Notification permission denied");
//         }

//         // const permission = await Notification.requestPermission();
//         // if (permission === "granted") {
//         //   console.log("Notification permission granted.");
//         //   messaging.getToken({ vapidKey: VapidKey }).then((token) => {
//         //     if (token) {
//         //       console.log("FCM Token:", token);
//         //       setTokenFound(token)
//         //     }
//         //   });
//         // } else {
//         //   console.log("Notification permission denied.");
//         // }
//     } catch (error) {
//         console.error("Error getting notification permission", error);
//     }
// };

// // export const getToken = (setTokenFound) => {
// //   if (messaging)
// //     return getTKN(messaging, {
// //       vapidKey: VapidKey,
// //     })
// //       .then((currentToken) => {
// //         if (currentToken) {
// //           console.log("current token for client: ", currentToken);
// //           setTokenFound(currentToken);
// //           // Track the token -> client mapping, by sending to backend server
// //           // show on the UI that permission is secured
// //         } else {
// //           console.log(
// //             "No registration token available. Request permission to generate one."
// //           );
// //           setTokenFound(currentToken);
// //           // shows on the UI that permission is required
// //         }
// //       })
// //       .catch((err) => {
// //         console.log("An error occurred while retrieving token. ", err);
// //         // catch error while creating client token
// //       });
// // };

// export { analytics, messaging };

// firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();

// let messaging = "";

// if (!isWebView()) {
//     const app = initializeApp(firebaseConfig);
//     messaging = getMessaging(app);
// }

// export { messaging };