import React, { useState, useEffect } from "react";
import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import AppLayout from "../../components/layouts/app-layout";
import { useAuth } from "../../helpers/context";
import { getDomainInfo } from "../../api/userApi";
import LoadingData from "../../components/global-components/loading-data";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessDenied from "../shared/access-denied";
import NoData from "../../components/global-components/no-data";
import { useTranslation } from "react-i18next";

const AllowedUsers = [
  "155668",
  "34882",
  "32955",
  "34296",
  "32387",
  "21532",
  "12818",
  "161438",
  "165199",
  "1"
];

const pageSize = 10;

const WLUsers = () => {
  const { token, UserId } = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    if (token && AllowedUsers.includes(UserId)) {
      setLoadingData(true);
      getDomainInfo()
        .then((result) => {
          if (result.resCode === 0) {
            setData(result.response);
            setFilteredData(result.response);
          } else {
            toast.error(result.resStr);
          }
        })
        .finally(() => setLoadingData(false));
    }
  }, [token, UserId]);


  return (
    <AppLayout
      pageTitle={t("SubscribersToTheAmakknProService")}
      needAuth
      regaNavMode>
      {token && AllowedUsers.includes(UserId) ? (
        <div className='page-width favorite-area pd-top-90 mg-bottom-100 text-center'>
          {!loadingData ? (
            <>
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "center !important",
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#000",
                }}>
                {t("SubscribersToTheAmakknProService")}
              </Box>
              <div className='row align-items-center justify-content-end pd-top-30'>
                <div
                  style={{ width: "100%", display: "flex" }}>
                  <TextField
                    id='standard-search'
                    style={{ width: "320px", marginBottom: 15 }}
                    label={t("SearchHere")}
                    placeholder={t("EnterIDCommercialRegisterOrFALLicense")}
                    type='search'
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      let temp = data;
                      temp = temp.filter(
                        (i) =>
                          i.advertiserId?.includes(value) ||
                          i.advertiserNumber.includes(value)
                      );
                      setFilteredData(temp);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', height: '500px', width: '100%', flexDirection: 'column' }}>
                  <TableContainer className="table-container responsive-table" component={Paper}>
                    <Table sx={{ minWidth: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">{t("SubscriberName")}</TableCell>
                          <TableCell align="right">{t("Domain")}</TableCell>
                          <TableCell align="right">{t("IDNumberCommercialRegister")}</TableCell>
                          <TableCell align="right">{t("FalLicense")}</TableCell>
                          <TableCell align="right">{t("NumberExpiryDateSaudiBusinessCenterCertificate")}</TableCell>
                          <TableCell align="right">{t("Actions")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loadingData ? <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        ><TableCell colSpan={5}><LoadingData /></TableCell></TableRow> :
                          filteredData?.length > 0 ? filteredData.slice(0).splice((page * pageSize) - pageSize, pageSize).map((row) => (
                            <TableRow
                              key={row.domain}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 0.7, pb: 0.7 } }}
                            >
                              <TableCell align="right" component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">
                                <a
                                  target='_blank'
                                  rel='noreferrer'
                                  style={{ color: "var(--main-color-one)" }}
                                  href={`https://${row.domain}`}>
                                  {row.domain}
                                </a>
                              </TableCell>
                              <TableCell align="right">{row.advertiserId || t("NotAvailable")}</TableCell>
                              <TableCell align="right">{row.advertiserNumber || t("NotAvailable")}</TableCell>
                              <TableCell align="right">{row.crNumber ? row.crNumber + ' / ' + row.expriyDate : t("NotAvailable")}</TableCell>
                              <TableCell align="right">
                                <div className='actions-wrapper'>
                                  <Tooltip title={t("SubscriberDetails")} placement='top'>
                                    <button className='custom-btn custom-btn2 btn-blue'>
                                      <Link to={`/pro-users/${row.domain}`}>
                                        <VisibilityIcon />
                                      </Link>
                                    </button>
                                  </Tooltip>
                                </div>
                              </TableCell>

                            </TableRow>
                          )) :
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            ><TableCell colSpan={5}><NoData msg={t("NoUsers")} /></TableCell></TableRow>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {filteredData?.length > pageSize && (
                    <Pagination
                      sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px' } }}
                      count={parseInt(Math.ceil(filteredData?.length / pageSize))}
                      page={page}
                      onChange={(event, value) => {
                        setPage(+value);
                      }}
                      color="primary" />
                  )}
                </div>
              
             
              </div>
            </>
          ) : (
            <LoadingData />
          )}
        </div>
      ) : (
        <AccessDenied
          btnLink='/'
          btnText={t("BackToHomePage")}
          desc={t("YouCannotAccessThisPage")}
        />
      )}
    </AppLayout>
  );
}

export default WLUsers;
