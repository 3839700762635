import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../helpers/context";
import { connectWithWhatsApp, getCustomerProps, getSimiliarCustomers, getWLProfile, sendWhatsAppMessage } from "../../../../api/userApi";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import SendWhatsAppMsg from "../../../../components/send-whatsapp-msg";
import LoadingData from "../../../../components/global-components/loading-data";
import NoData from "../../../../components/global-components/no-data";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionsPopper from "../../../../components/global-components/actions-popper";
import { DISTRICTS } from "../../../../constants";
import { Close } from "@mui/icons-material";
import moment from "moment";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { ListArrow } from "../../../../constants/icons";
import { renderFeaturesForWhatsAppMsg, renderInterestingType, renderPropertyType, resolvePhonePrefix, trimPhone } from "../../../../helpers";
import CustomerDetails from "../customer-details";
import ConnectWithWhatsApp from "../../addons/components/connect-with-whatsapp";

const pageSize = 10;
const publicUrl = process.env.REACT_APP_URL + "/";
const similarPageSize = 10;

const MatchedDataDialog = ({ matchedDataDialog, setMatchedDataDialog, companyName, superToken }) => {
    const { t, i18n } = useTranslation();
    const [loadingCustomersData, setLoadingCustomersData] = useState(true);
    const [loadingPropertiesData, setLoadingPropertiesData] = useState(true);
    const [matchedCustomers, setMatchedCustomers] = useState([]);
    const [matchedProperties, setMatchedProperties] = useState([]);
    const [similarPage, setSimailarPage] = useState([
        { key: 0, page: 1 }
    ]);
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const [propertiesPage, setPropertiesPage] = useState(1);
    const [propertiesTotalCount, setPropertiesTotalCount] = useState(0);

    const [matchedUnlicensedProperties, setMatchedUnlicensedProperties] = useState([]);
    const [unlicesedPropertiesTotalCount, setUnlicesedPropertiesTotalCount] = useState(0);

    const { token, vendor, UserType } = useAuth();
    const history = useNavigate();
    // const [openPopper, setOpenPopper] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [row, setRow] = useState();
    const [openPopper2, setOpenPopper2] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [row2, setRow2] = useState();
    const [whatsAppDialog, setWhatsAppDialog] = useState({
        visible: false,
        message: '',
        numbers: [],
        multiple: false,
        customers: []
    });
    const [siteName, setSiteName] = useState('');
    const [WLWhatsapp, setWLWhatsapp] = useState('');
    const [isSendingWhatsAppMsg, setIsSendingWhatsAppMsg] = useState(false);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [openQRPopup, setOpenQRPopup] = useState(false);
    const [client, setClient] = useState();
    
    // const citiesOptions = useMemo(
    //     () => {
    //         return CITIES.map((city) => ({
    //             id: +city.CITY_ID,
    //             label: i18n.language === "ar" ? city.CITYNAME_AR: city.CITYNAME_EN,
    //             regionId: +city.REGION_ID,
    //         }))
    //     },// eslint-disable-next-line
    //     [i18n.language]);
    
    const districtsOptions = useMemo(
        () => {
            return DISTRICTS.map((district) => ({
                id: +district.DISTRICT_ID,
                label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
                regionId: +district.REGION_ID,
                cityId: +district.CITY_ID,
            }));
        }, // eslint-disable-next-line
        [i18n.language]);
    
    useEffect(() => {
        getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
                setSiteName(_userInfo.response.wlUser.briefDescription);
                setWLWhatsapp(_userInfo.response.wlUser.whatsapp);
            }
        });
          
    },// eslint-disable-next-line
        [vendor]);
    
    const getMatchedCustomersData = async (search) => {
        setLoadingCustomersData(true);
        getSimiliarCustomers({
            page: '1',
            search,
            userId: token,
            type: matchedDataDialog?.customer?.type === "باحث" ? 'باحث' : matchedDataDialog?.customer?.type === "مالك" ? 'مالك' : 'مالك,باحث'
        }).then((res) => {
            if (res.resCode === 0) {
                setMatchedCustomers(res.response.array);
            } else {
                toast.error(res.resStr);
            }
        }).finally(() => setLoadingCustomersData(false));
    };

    const getMatchedPropertiesData = async (search, status) => {
        setLoadingPropertiesData(true);
        getCustomerProps({
            page: propertiesPage,
            search,
            status,
            language: i18n.language === "ar" ? "0" : "1",
            userId: UserType === "5" ? superToken : token
        }).then((res) => {
            if (res.resCode === 0) {
                if (status === 1) {
                    setMatchedProperties(res.response.array);
                    setPropertiesTotalCount(res.response.totalCount);
                } else {
                    setMatchedUnlicensedProperties(res.response.array);
                    setUnlicesedPropertiesTotalCount(res.response.totalCount);
                }
                
            } else {
                toast.error(res.resStr);
            }
        }).finally(() => setLoadingPropertiesData(false));
    }

    useEffect(() => {
        if (matchedDataDialog.visible) {
            getMatchedCustomersData(matchedDataDialog?.customer?.phone);
        }
    },// eslint-disable-next-line
        [matchedDataDialog, i18n.language]);

    useEffect(() => {
        if (matchedDataDialog.visible && (UserType !== '5' || (UserType === "5" && superToken))) {
            getMatchedPropertiesData(matchedDataDialog?.customer?.phone,1);
            getMatchedPropertiesData(matchedDataDialog?.customer?.phone, -2);
        }
    }, // eslint-disable-next-line
        [matchedDataDialog, propertiesPage, superToken, UserType, i18n.language]);

    function delay(t, data) {
        return new Promise(resolve => {
            setTimeout(resolve, t, data);
        });
    }

    async function runSequence(array, delayT, fn) {
        for (let item of array) {
            await fn(item);
            await delay(delayT);
        }
        return null;
    }

    return (<>
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={matchedDataDialog.visible}
            className="custom-dialog model"
            onClose={() => setMatchedDataDialog({ visible: false, customer: undefined, properties: [], similar: [] })}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>{t("ListOfMatchedCustomersAndADs")}</h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={() => setMatchedDataDialog({ visible: false, customer: undefined, properties: [], similar: [] })} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>

            </DialogTitle>
            <DialogContent>
                <SendWhatsAppMsg
                    dialog={whatsAppDialog}
                    setDialog={setWhatsAppDialog}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                            {t("ListOfMatchingRealEstateADs")} ({loadingPropertiesData ? <>&nbsp;&nbsp;&nbsp;</> : propertiesTotalCount})
                        </Divider>
                        {propertiesTotalCount > 0 && (
                            <LoadingButton
                                classes="primary-btn btn whatsapp-button px-3 mb-3 py-2"
                                style={{ width: 'fit-content', height: '40px' }}
                                handleClick={() => {
                                    setIsSendingWhatsAppMsg(true);
                                    connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(res => {
                                        if (res.resCode === 0) {
                                            if (res.response.message === 'Already logged in') {
                                                sendWhatsAppMessage({
                                                    userId: token,
                                                    number: resolvePhonePrefix(WLWhatsapp),
                                                    to: [resolvePhonePrefix(matchedDataDialog?.customer?.phone)],
                                                    message: `السيد ${matchedDataDialog?.customer?.name},\nالسلام عليكم,\n\nمتوفّر لدينا ${propertiesTotalCount > 1 ? `${propertiesTotalCount} إعلانات عقاريّة قد تكون مناسبة لكم ` : 'إعلان عقاري قد يكون مناسب لكم'}.`,
                                                }).then(res => {
                                                    if (res.resCode === 0) {
                                                        runSequence(matchedProperties, 2000, (item) => {
                                                            sendWhatsAppMessage({
                                                                userId: token,
                                                                number: resolvePhonePrefix(WLWhatsapp),
                                                                to: [resolvePhonePrefix(matchedDataDialog?.customer?.phone)],
                                                                message: `${item.property.propertyTypeName} ${item.property.listedFor === '1' ? t("ForRent") : t("ForSale")} في${item?.property.fullAddress?.split(",")?.[1]}، حي${item?.property.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(item.property.defaultPrice)} ريال${item.property.listedFor === '1' ? `/${item.property.defaultPriceType.name}` : ''}\n\n${renderFeaturesForWhatsAppMsg(item.property)}\nرقم ترخيص الإعلان: ${item.property.additionalInfo?.filter(i => i.key === "10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${item.property.idToShare}\n\n${siteName}\nرخصة فال: ${item.property.additionalInfo?.filter(i => i.key === "21")?.[0]?.valueText || t("NotAvailable")}`
                                                            }).then(res => {
                                                                if (res.resCode !== 0) toast.error(res.resStr);
                                                            });
                                                        }).then(() => {
                                                            setIsSendingWhatsAppMsg(false);
                                                            toast.success(t("MessageSentSuccessfully"));
                                                        }).catch(() => setIsSendingWhatsAppMsg(false));
                                                    
                                                    }
                                                    else
                                                        toast.error(res.resStr);
                                        
                                                }).catch(() => setIsSendingWhatsAppMsg(false));
                                            }
                                            else {
                                                setOpenQRPopup(true);
                                                toast.error(t("WhatsAppHasNotBeenLinkedPleaseConnectAndTryAgain"));
                                                setIsSendingWhatsAppMsg(false);
                                            }
                                        } else {
                                            toast.error(t("TheServiceIsNotAvailablePleaseTryAgainLater"));
                                            setIsSendingWhatsAppMsg(false);
                                        }
                                    }).catch(() => setIsSendingWhatsAppMsg(false));
                                }}
                                loading={isSendingWhatsAppMsg}
                                OKdisabled={isSendingWhatsAppMsg}
                                label={t("ContactViaWhatsApp")}
                                icon={<WhatsAppIcon />}
                            />
                        )}
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                            <TableContainer className="table-container" component={Paper}>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">{t("Property")}</TableCell>
                                            <TableCell align="right">{t("PropertyAddress")}</TableCell>
                                            <TableCell align="right">{t("PropertyPrice")} ({t("SAR")})</TableCell>
                                            <TableCell align="right">{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadingPropertiesData ?
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell colSpan={5}><LoadingData /></TableCell>
                                            </TableRow> : matchedProperties.length > 0 ?
                                                matchedProperties.map((row) => (
                                                    <TableRow key={row?.property?.propertyId}>
                                                        <TableCell align="right">
                                                            <div className="d-flex align-items-center" style={{ gap: '5px', cursor: 'pointer' }} onClick={() => history(`/admin/property-details/${row?.property?.idToShare}`)}>
                                                                <Avatar
                                                                    sx={{ width: 48, height: 48, borderRadius: '6px' }}
                                                                    variant="square"
                                                                    src={row?.property?.photos.split(',')[0] ? row?.property?.photos.split(',')[0] :
                                                                        `${publicUrl}assets/img/defimgs/${row?.property?.propertyType}.svg`} />
                                                                {row?.property?.propertyTypeName} {row?.property?.listedFor === '2' ? t("ForSale") : t("ForRent")}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right">{row?.property?.address}</TableCell>
                                                        <TableCell align="right">{Intl.NumberFormat("en").format(row?.property?.defaultPrice)}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    setOpenPopper2(!openPopper2);
                                                                    setAnchorEl2(anchorEl2 ? null : e.target);
                                                                    setRow2(row?.property);
                                                                }}
                                                                sx={{
                                                                    background: '#fff',
                                                                    zIndex: 99,
                                                                    color: 'var(--main-color-one)',
                                                                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                                                    '&:hover,&:focus': {
                                                                        color: '#fff',
                                                                        background: 'var(--main-color-one)'
                                                                    }
                                                                }}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                )) :
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                ><TableCell colSpan={5}><NoData msg={t("NoRealEstateADsWereFoundThatMatchThisCustomerInterests")} /></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ActionsPopper
                                openPopper={openPopper2}
                                anchorEl={anchorEl2}
                                setAnchorEl={setAnchorEl2}
                                setOpenPopper={setOpenPopper2}
                                items={
                                    [
                                        {
                                            title: t("ContactViaWhatsApp"),
                                            action: () => {
                                                connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(res => {
                                                    if (res.resCode === 0) {
                                                        if (res.response.message === 'Already logged in') {
                                                            sendWhatsAppMessage({
                                                                userId: token,
                                                                number: resolvePhonePrefix(WLWhatsapp),
                                                                to: [resolvePhonePrefix(matchedDataDialog?.customer?.phone)],
                                                                message: `السيد ${matchedDataDialog?.customer?.name}،\nالسلام عليكم،\n\nمتوفّر لدينا  ${row2.propertyTypeName} ${row2.listedFor === '1' ? t("ForRent") : t("ForSale")} في${row2.fullAddress?.split(",")?.[1]}، حي${row2.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(row2.defaultPrice)} ريال${row2.listedFor === '1' ? `/${row2.defaultPriceType.name}` : ''}\n\n${renderFeaturesForWhatsAppMsg(row2)}\nرقم ترخيص الإعلان: ${row2.additionalInfo?.filter(i => i.key === "10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${row2.idToShare}\n\n${siteName}\nرخصة فال: ${row2.additionalInfo?.filter(i => i.key === "21")?.[0]?.valueText || t("NotAvailable")}`
                                                            }).then(res => {
                                                                if (res.resCode === 0) {
                                                                    toast.success(t("MessageSentSuccessfully"));
                                                                }
                                                                else
                                                                    toast.error(res.resStr);
                                                            });
                                                        }
                                                        else
                                                            toast.error(t("WhatsAppHasNotBeenLinkedPleaseConnectAndTryAgain"));
                                                    } else {
                                                        toast.error(t("TheServiceIsNotAvailablePleaseTryAgainLater"));
                                                    }
                                                });
                                            }
                                        }
                                    ]
                                }
                            />
                        </div>
            
                        {propertiesTotalCount > pageSize && (
                            <Pagination
                                sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px', justifyContent: 'start' } }}
                                count={parseInt(Math.ceil(propertiesTotalCount / pageSize))}
                                page={propertiesPage}
                                onChange={(event, value) => setPropertiesPage(value)}
                                color="primary" />
                        )}
                    </Grid>


                    <Grid item xs={12}>
                        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                            {t("ListOfMatchingUnlisencedRealEstateADs")} ({loadingPropertiesData ? <>&nbsp;&nbsp;&nbsp;</> : unlicesedPropertiesTotalCount})
                        </Divider>
                        
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                            <TableContainer className="table-container" component={Paper}>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">{t("Property")}</TableCell>
                                            <TableCell align="right">{t("PropertyAddress")}</TableCell>
                                            <TableCell align="right">{t("PropertyPrice")} ({t("SAR")})</TableCell>
                                            <TableCell align="right">{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadingPropertiesData ?
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell colSpan={5}><LoadingData /></TableCell>
                                            </TableRow> : matchedUnlicensedProperties.length > 0 ?
                                                matchedUnlicensedProperties.map((row) => (
                                                    <TableRow key={row?.property?.propertyId}>
                                                        <TableCell align="right">
                                                            <div className="d-flex align-items-center" style={{ gap: '5px', cursor: 'pointer' }} onClick={() => history(`/admin/unlicensed-ads/details/${row?.property?.idToShare}`)}>
                                                                <Avatar
                                                                    sx={{ width: 48, height: 48, borderRadius: '6px' }}
                                                                    variant="square"
                                                                    src={row?.property?.photos.split(',')[0] ? row?.property?.photos.split(',')[0] :
                                                                        `${publicUrl}assets/img/defimgs/${row?.property?.propertyType}.svg`} />
                                                                {row?.property?.propertyTypeName} {row?.property?.listedFor === '2' ? t("ForSale") : t("ForRent")}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right">{row?.property?.address}</TableCell>
                                                        <TableCell align="right">{Intl.NumberFormat("en").format(row?.property?.defaultPrice)}</TableCell>
                                                    </TableRow>
                                                )) :
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                ><TableCell colSpan={5}><NoData msg={t("NoUnlicensedRealEstateADsWereFoundThatMatchThisCustomerInterests")} /></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
            
                        {propertiesTotalCount > pageSize && (
                            <Pagination
                                sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px', justifyContent: 'start' } }}
                                count={parseInt(Math.ceil(propertiesTotalCount / pageSize))}
                                page={propertiesPage}
                                onChange={(event, value) => setPropertiesPage(value)}
                                color="primary" />
                        )}
                    </Grid>


                    <Grid item xs={12}>
                        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                            {t("ListOfMatchedCustomers")}
                        </Divider>
                        {loadingCustomersData ? <LoadingData /> : matchedCustomers?.[0]?.interests?.length > 0 ? matchedCustomers?.[0]?.interests?.map((item, index) => (
                            <Box sx={{ margin: 1 }} key={item.id}>
                                <Typography variant="h6" gutterBottom component="div" style={{ margin: '15px 0' }}>
                                    <ListArrow style={i18n.language === "en" ? { transform: 'rotate(180deg)' } : {}} fill='black' /> {`${renderInterestingType(item.interestingType)} ${renderPropertyType(item.propertyType)} - ${districtsOptions.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label} `}
                                    <span style={{ fontSize: '14px' }}>{`(${t("Area")}: ${item?.areaFrom ? Intl.NumberFormat("en").format(item.areaFrom) + t("M2") : t("NotAvailable")} - ${t("Price")}: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ${t('SAR')})`}</span>
                                </Typography>
                                {item?.similar?.length > 0 ? (
                                    <div style={{ display: 'flex', maxHeight: '400px', width: '100%', flexDirection: 'column', marginBottom: 30 }}>
                                        {/* <div className="d-flex align-items-center mb-3 justify-content-between" style={{ gap: '5px' }}>
                                            <p style={{ fontSize: '15px', fontWeight: '600' }}>{`${t("InterestedCount")}: ${item?.similar?.length ? Intl.NumberFormat("en").format(item?.similar?.length) : t("NotAvailable")}`}</p>
                                            {matchedDataDialog?.customer?.type !== 'باحث' && <LoadingButton
                                                classes="primary-btn btn whatsapp-button px-3 mb-3 py-2"
                                                style={{ width: 'fit-content', height: '40px' }}
                                                handleClick={() =>
                                                    setWhatsAppDialog({
                                                        message: `السلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions.filter((i) => +i.id === +item?.city)?.[0]?.label || null}، ${districtsOptions.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\n\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`,
                                                        numbers: item?.similar?.map(item => item.phone),
                                                        visible: true,
                                                        multiple: true,
                                                        customers: item?.similar
                                                    })
                                                }
                                                label={t("ContactAllViaWhatsApp")}
                                                icon={<WhatsAppIcon />}
                                            />}
                                        </div> */}
                    
                                        <TableContainer className="table-container" component={Paper}>
                                            <Table sx={{ minWidth: '100%' }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right">{t("Name")}</TableCell>
                                                        <TableCell align="right">{t("PhoneNumber")}</TableCell>
                                                        <TableCell align="right">{t("Seriousness")}</TableCell>
                                                        <TableCell align="right">{t("AdditionDate")}</TableCell>
                                                        <TableCell align="right" style={{ maxWidth: '100px' }}>{t("Notes")}</TableCell>
                                                        {/* <TableCell align="right">{t("Actions")}</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {item?.similar?.length > 0 && item?.similar?.slice(0).splice((similarPage.filter(p => p.key === item.id)?.[0]?.page * similarPageSize) - similarPageSize, similarPageSize)?.map((row1) => (
                                                        <TableRow
                                                            key={row1.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="right" component="th" scope="row">
                                                                <span style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer' }} onClick={() => {
                                                                    setClient(row1);
                                                                    setOpenCustomerDialog(true);
                                                                }}>{`${row1.name} - ${row1.type}`}</span>
                                                            </TableCell>
                                                            <TableCell align="right"><a rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"} href={`tel:${trimPhone(row1.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(row1.phone)}</a></TableCell>
                                                            <TableCell align="right">{row1.seriousness || t("Undefined")}</TableCell>
                                                            <TableCell align="right">{row1.createdAt ? moment(row1.createdAt).locale('en').format("DD-MM-YYYY") : t("Undefined")}</TableCell>
                                                            <TableCell align="right">
                                                                <p
                                                                    title={row1.notes ? row1.notes.length > 70 ? row1.notes : '' : ''}
                                                                    className='text-more'
                                                                    style={{ whiteSpace: "pre-line" }}>
                                                                    {row1.notes
                                                                        ? row1.notes.length > 70
                                                                            ? row1.notes.substr(0, 70) + "... "
                                                                            : row1.notes : t("Undefined")}
                                                                </p>
                                                            </TableCell>
                                                            {/* <TableCell align="right">
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        setOpenPopper(!openPopper);
                                                                        setAnchorEl(anchorEl ? null : e.target);
                                                                        setRow(row1);
                                                                    }}
                                                                    sx={{
                                                                        background: '#fff',
                                                                        zIndex: 99,
                                                                        color: 'var(--main-color-one)',
                                                                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                                                        '&:hover,&:focus': {
                                                                            color: '#fff',
                                                                            background: 'var(--main-color-one)'
                                                                        }
                                                                    }}>
                                                                    <MoreVertIcon />
                                                                </IconButton> */}
                                                            {/* </TableCell> */}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <CustomerDetails
                                            openCustomerDialog={openCustomerDialog}
                                            setOpenCustomerDialog={setOpenCustomerDialog}
                                            setClient={setClient}
                                            companyName={companyName}
                                            client={client} />
                                        {/* <ActionsPopper
                                            openPopper={openPopper}
                                            anchorEl={anchorEl}
                                            setAnchorEl={setAnchorEl}
                                            setOpenPopper={setOpenPopper}
                                            items={
                                                [
                                                    {
                                                        title: t("ContactViaWhatsApp"),
                                                        action: () => setWhatsAppDialog({
                                                            numbers: [row?.phone],
                                                            message: `السيد ${row?.type === "مالك" ? matchedDataDialog?.customer?.name : row?.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions.filter((i) => +i.id === +item?.city)?.[0]?.label || null}، ${districtsOptions.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\n\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`,
                                                            visible: true,
                                                            multiple: false,
                                                            customers: []
                                                        })
                                                    },
                                                    // {
                                                    //     title: "اتصال مباشر",
                                                    //     action: () => window.open(`tel:${trimPhone(row?.phone)}`, '_blank')
                                                    // },
                                                    ((row?.type === "مالك" && matchedDataDialog?.customer?.email) || (row?.type !== "مالك" && row?.email)) && {
                                                        title: t("ContactViaEmail"),
                                                        action: () => window.open(`mailto:${row?.type === "مالك" ? matchedDataDialog?.customer?.email : row?.email}?subject=${companyName}&body=السيد ${row?.type === "مالك" ? matchedDataDialog?.customer?.name : row?.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions.filter((i) => +i.id === +item?.city)?.[0]?.label || null}، ${districtsOptions.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\n\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`, '_blank')
                                                    }
                                                ]
                                            }
                                        /> */}
                                    </div>
                                ) : (<p>{t("NoCustomersMatchThisInterest")}</p>)}
               
                                {item?.similar?.length > similarPageSize && (
                                    <Pagination
                                        sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
                                        count={parseInt(Math.ceil(item?.similar?.length / similarPageSize))}
                                        page={similarPage?.filter(p => p.key === item.id)?.[0]?.page}
                                        onChange={(event, value) => {
                                            let temp = [];
                                            similarPage?.map(p => p.key === item.id ? temp.push({ key: item.id, page: value }) : temp.push(p));
                                            setSimailarPage(temp);
                                        }}
                                        color="primary" />
                                )}
                                {matchedCustomers?.[0]?.interests?.length === index + 1 ? null :
                                    <div style={{ width: '100%', height: 1, backgroundColor: '#ededed', marginTop: 15, marginBottom: 20 }} />
                                }
                            </Box>
                        )) : <p>{t("NoCustomersWereFoundWithInterestsMatchingThisCustomer")}</p>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
   
        <Dialog
            onClose={() => setOpenQRPopup(false)}
            maxWidth={"lg"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
                "& .MuiPaper-root": {
                    margin: 0,
                    width: "calc(100% - 20px)",
                    zIndex: 9999999,
                },
            }}
            open={openQRPopup}>
            <DialogTitle>
                <div className='dialog-head mb-4'>
                    <div className='dialog-actions close-only'>
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                setOpenQRPopup(false);
                            }}
                            sx={{
                                top: '7px',
                                position: 'relative',
                                color: (theme) => theme.palette.grey[500],
                            }}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <ConnectWithWhatsApp setPopup={setOpenQRPopup} />
            </DialogContent>
        </Dialog>
    </>
    );
}

export default MatchedDataDialog;