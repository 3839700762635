import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getWlPermission, saveWlPermissions } from '../../../../api/userApi';
import { useAuth } from '../../../../helpers/context';
import md5 from 'md5';
import LoadingButton from '../../../../components/global-components/loading-btn';
import { toast } from 'react-toastify';
import { ExpandMore } from '@mui/icons-material';

export default function ManagePermissionsDialog({ dialog, setDialog, onOK }) {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [checked, setChecked] = React.useState([
        // { key: 'Dashboard', value: false },
        { key: 'Projects', value: false },

        { key: 'Properties', value: false, base: true },
        { key: 'RealEstateADs', value: false, subOf: 'Properties' },
        { key: 'AddAD', value: false, subOf: 'Properties' },
        { key: 'InterestedCustomers', value: false, subOf: 'Properties' },
        { key: 'UnlicensedADs', value: false, subOf: 'Properties' },
        { key: 'VIPADs', value: false, subOf: 'Properties' },

        { key: 'CustomersManagement', value: false, base: true },
        { key: 'CustomersList', value: false, subOf: "CustomersManagement" },
        { key: 'AddCustomer', value: false, subOf: "CustomersManagement" },
        { key: 'MatchedCustomers', value: false, subOf: "CustomersManagement" },
        { key: 'Deals', value: false, subOf: "CustomersManagement" },
        { key: 'Tasks', value: false },
        { key: 'PropertiesRequests', value: false },
        { key: 'Blog', value: false },

        { key: 'WebsiteManagement', value: false, base: true },
        { key: 'Styles', value: false, subOf: "WebsiteManagement" },
        { key: 'Templates', value: false, subOf: "WebsiteManagement" },
        { key: 'HomePage', value: false, subOf: "WebsiteManagement" },
        { key: 'AboutUS', value: false, subOf: "WebsiteManagement" },
        { key: 'Addons', value: false, subOf: "WebsiteManagement" },
        { key: 'MyProfile', value: false },

        { key: 'Messages', value: false, base: true },
        { key: 'CustomersMessages', value: false, subOf: "Messages" },
        { key: 'AmakknUsersMessages', value: false, subOf: "Messages" },
        
        { key: 'ADsReports', value: false },
        { key: 'CustomerSupport', value: false },
        // { key: 'Settings', value: false }
    ]);

    const [timeStamp, setTimeStamp] = useState(new Date());
    const [isSubmittingData, setIsSubmittingData] = useState(false);

    useEffect(() => {
        if (dialog.open) {
            getWlPermission({
                userId: md5(dialog.createdAt + dialog.id)
            }).then(res => {
                if (res.resCode === 0) {
                    let temp = checked;
                    for (let permission of res.response.permissions) {
                        temp.map((item, index) => {
                            if (item.key === permission) {
                                temp[index].value = true;
                            }
                            return null;
                        });
                    }
                    setChecked(temp);
                    setTimeStamp(new Date());

                }
            });
        } else {
            setChecked([
                // { key: 'Dashboard', value: false },
                { key: 'Projects', value: false },

                { key: 'Properties', value: false, base: true },
                { key: 'RealEstateADs', value: false, subOf: 'Properties' },
                { key: 'AddAD', value: false, subOf: 'Properties' },
                { key: 'InterestedCustomers', value: false, subOf: 'Properties' },
                { key: 'UnlicensedADs', value: false, subOf: 'Properties' },
                { key: 'VIPADs', value: false, subOf: 'Properties' },

                { key: 'CustomersManagement', value: false, base: true },
                { key: 'CustomersList', value: false, subOf: "CustomersManagement" },
                { key: 'AddCustomer', value: false, subOf: "CustomersManagement" },
                { key: 'MatchedCustomers', value: false, subOf: "CustomersManagement" },
                { key: 'Deals', value: false, subOf: "CustomersManagement" },
                { key: 'Tasks', value: false },
                { key: 'PropertiesRequests', value: false },
                { key: 'Blog', value: false },

                { key: 'WebsiteManagement', value: false, base: true },
                { key: 'Styles', value: false, subOf: "WebsiteManagement" },
                { key: 'Templates', value: false, subOf: "WebsiteManagement" },
                { key: 'HomePage', value: false, subOf: "WebsiteManagement" },
                { key: 'AboutUS', value: false, subOf: "WebsiteManagement" },
                { key: 'Addons', value: false, subOf: "WebsiteManagement" },
                { key: 'MyProfile', value: false },

                { key: 'Messages', value: false, base: true },
                { key: 'CustomersMessages', value: false, subOf: "Messages" },
                { key: 'AmakknUsersMessages', value: false, subOf: "Messages" },
        
                { key: 'ADsReports', value: false },
                { key: 'CustomerSupport', value: false },
                // { key: 'Settings', value: false }
            ]);
        }
    },
        // eslint-disable-next-line
        [dialog, token]);

  
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={dialog.open}
            className="custom-dialog model"
            onClose={() => {
                setDialog({ ...dialog, open: false });
                onOK?.();
            }}>
            <DialogTitle>
                <div className="dialog-head flex-column flex-md-row">
                    <div>
                        <h2>{t("ManagePermissions")}</h2>
                        <p>{t("YouCanManageAgentPermissions")}</p>
                    </div>
                    <div className="dialog-actions flex-row">
                        <LoadingButton classes="px-3 primary-btn" handleClick={() => {
                            setIsSubmittingData(true);
                            saveWlPermissions({
                                adminId: token,
                                userId: md5(dialog.createdAt + dialog.id),
                                permissions: checked?.filter(i => i.value && !i.base)?.map(i => i.key)
                            }).then(res => {
                                setDialog({ ...dialog, open: false });
                                toast.success(t("DoneSuccessfully"));
                                onOK?.();
                            }).finally(() => setIsSubmittingData(false));
                        }}
                            loading={isSubmittingData}
                            label={t("Save")}
                        />
                        
                        <button className="px-3 outlined-btn" onClick={() => {
                            setDialog({ ...dialog, open: false });
                            onOK?.();
                        }}>
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <FormControlLabel
                        label={t("All")}
                        sx={{ marginInlineStart: -2 }}
                        control={
                            <Checkbox
                                checked={checked?.filter(i => !i.value)?.length === 0}
                                indeterminate={checked?.filter(i => !i.value)?.length > 0 && checked?.filter(i => i.value)?.length > 0}
                                onChange={(e) => {
                                    let temp = checked;
                                    temp?.map(i => {
                                        i.value = e.target.checked;
                                        return null;
                                    });
                                    setChecked(temp);
                                    setTimeStamp(timeStamp + new Date())
                                }}
                            />
                        }
                    />
                    <div className='row mx-0'>
                        {checked?.map((item) => {
                            return item.base ?
                                <div key={item.key} className='col-12'>
                                    <Accordion
                                            
                                        sx={{
                                            padding: 0,
                                            margin: 0,
                                            boxShadow: 'none'
                                        }}>
                                        <AccordionSummary expandIcon={<ExpandMore />} id={item.key} sx={{
                                            padding: 0,
                                            margin: 0,
                                            "& .MuiAccordionSummary-content": {
                                                margin: 0
                                            },
                                            '& .MuiAccordionSummary-expandIconWrapper': {
                                                position: 'absolute',
                                                right: '-12px',
                                                top: '10px'
                                            },
                                            '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper': {
                                                top:'15px'
                                            }
                                        }} >
                                            <FormControlLabel
                                                label={t(item.key)}
                                                sx={{ marginInlineStart: 0 }}

                                                control={
                                                    <Checkbox
                                                        checked={checked?.filter(i => i.subOf === item.key && !i.value)?.length === 0}
                                                        indeterminate={checked?.filter(i => i.subOf === item.key && !i.value)?.length > 0 && checked?.filter(i => i.subOf === item.key && i.value)?.length > 0}
                                                        onChange={(e) => {
                                                            let temp = checked;
                                                            temp?.map(i => {
                                                                if (i.subOf === item.key)
                                                                    i.value = e.target.checked;
                                                                return null;
                                                            });
                                                            setChecked(temp);
                                                            setTimeStamp(timeStamp + new Date())
                                                        }}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails className='flex flex-column mt-0 pt-0'>
                                            {checked.map(i => i.subOf === item.key && (
                                                <FormControlLabel
                                                    label={t(i.key)}
                                                    sx={{ marginInlineStart: i.subOf ? 2 : 0, width: '100%' }}
                                                    control={
                                                        <Checkbox
                                                            checked={i.value}
                                                            onChange={(e) => {
                                                                let temp = checked;
                                                                temp?.map((o, index) => {
                                                                    if (o.key === i.key)
                                                                        temp[index].value = e.target.checked;
                                                                    return null;
                                                                });
                                                                setChecked(temp);
                                                                setTimeStamp(timeStamp + new Date())
                                                         
                                                            }}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                
                                : !item.subOf && (
                                    <div key={item.key} className='col-12'> <FormControlLabel
                                        label={t(item.key)}
                                        sx={{ marginInlineStart: item.subOf ? 4 : 0 }}
                                        control={
                                            <Checkbox
                                                checked={item.value}
                                                onChange={(e) => {
                                                    let temp = checked;
                                                    temp?.map((i, index) => {
                                                        if (i.key === item.key)
                                                            temp[index].value = e.target.checked;
                                                        return null;
                                                    });
                                                    setChecked(temp);
                                                    setTimeStamp(timeStamp + new Date())
                                                 
                                                }}
                                            />
                                        }
                                    />
                                    </div>
                                )
                        })}                               
                        
                        
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
