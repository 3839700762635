/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../Config/Firebase";

import {
  AppleStoreBtn,
  GooglePlayBtn,
  HuaweiAppGalleryBtn,
} from "../../constants/assets";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useAuth } from "../../helpers/context";
import { Twitter } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import {
  resolveFacebookLink,
  resolveInstagramLink,
  resolveTwitterLink,
} from "../../helpers";

const imgattr = "Footer logo";
const PublicUrl = process.env.REACT_APP_URL + "/";

export default function Footer_v1(props) {
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { vendor } = useAuth();
  const { t, i18n } = useTranslation();
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  const accordionStyles = {
    background: "transparent",
    padding: 0,
  };

  // useEffect(() => {
  //   if(vendor && vendor !== 'amakkn')
  //   getWLProfile({ userName: vendor, language: "0" }).then((_userInfo) => {
  //     if (_userInfo.resCode === 0)
  //       setuserInfo(_userInfo.response.wlUser);
  //   });
  // }, [vendor]);

  function handleGoogleClick() {
    // logEvent(analytics, "play_store_website", { platform: "website" });
    window.open(
      "https://play.google.com/store/apps/details?id=com.amakknapp.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      "_blank"
    );
  }

  function handleAppleClick() {
    // logEvent(analytics, "app_store_website", { platform: "website" });
    window.open(
      "https://itunes.apple.com/us/app/amakkn/id1220303072",
      "_blank"
    );
  }

  function handleHuaweiClick() {
    window.open("https://url.cloud.huawei.com/kcZJ8WHuHm", "_blank");
  }

  const getCompanyLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link to='/about'>
            {vendor === "amakkn" ? t("AboutAmakkn") : t("AboutUS")}
          </Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link to='/contact'>{t("ContactUs")}</Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to='/terms'>{t("TermsOfUse")}</Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link to='/privacy-policy'>{t("PrivacyPolicy")}</Link>
        </li>
        <li className='readeal-top' key='5'>
          <a
            aria-label='rega pdf'
            href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/rega.pdf'>
            {t("RealEstateADRegulations")}
          </a>
        </li>
      </ul>
    );
  };

  const getPopularSeachLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link
            to={`/search-map/2/1/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("LandForSaleInRiyadh")}
          </Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link
            to={`/search-map/2/3/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("ApartmentsForSaleInRiyadh")}
          </Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to={`/search-map/1/3/21.4362767/39.7064624/default/12/1`}>
            {t("ApartmentsForRentInMakkah")}
          </Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link to={`/search-map/2/3/21.4505289/38.9309635/default/12/1`}>
            {t("ApartmentsForSaleInJeddah")}
          </Link>
        </li>
        <li className='readeal-top' key='5'>
          <Link to={`/search-map/2/1/26.3628008/49.8524566/default/12/1`}>
            {t("LandForSaleInDammam")}
          </Link>
        </li>
        <li className='readeal-top' key='6'>
          <Link to={`/search-map/2/4/24.4713203/39.4774735/default/12/1`}>
            {t("VillasForRentInMadina")}
          </Link>
        </li>
        <li className='readeal-top' key='7'>
          <Link to={`/search-map/2/3/26.3628008/49.8524566/default/12/1`}>
            {t("ApartmentsForSaleInDammam")}
          </Link>
        </li>
        <li className='readeal-top' key='8'>
          <Link to={`/search-map/1/3/24.4713203/39.4774735/default/12/1`}>
            {t("ApartmentsForRentInMadina")}
          </Link>
        </li>
      </ul>
    );
  };

  const getDiscoverPropertiesLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link
            to={`/search-map/1/3/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("ApartmentsForRentInRiyadh")}
          </Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link to={`/search-map/2/1/21.4505289/38.9309635/default/12/1`}>
            {t("LandForSaleInJeddah")}
          </Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to={`/search-map/1/3/21.4362767/39.7064624/default/12/1`}>
            {t("ApartmentsForRentInMakkah")}
          </Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link
            to={`/search-map/2/3/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("ApartmentsForSaleInRiyadh")}
          </Link>
        </li>
        <li className='readeal-top' key='5'>
          <Link to={`/search-map/1/4/26.3628008/49.8524566/default/12/1`}>
            {t("VillasForRentInDammam")}
          </Link>
        </li>
        <li className='readeal-top' key='6'>
          <Link to={`/search-map/1/3/24.4713203/39.4774735/default/12/1`}>
            {t("ApartmentsForRentInMadina")}
          </Link>
        </li>
        <li className='readeal-top' key='7'>
          <Link
            to={`/search-map/2/1/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("LandForSaleInRiyadh")}
          </Link>
        </li>
        <li className='readeal-top' key='8'>
          <Link
            to={`/search-map/2/4/24.716199523004914/46.671776478222675/default/12/1`}>
            {t("VillasForSaleInRiyadh")}
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <footer className='footer-area' style={props.style ? props.style : {}}>
      <div className='footer-wrapper'>
        <div className='footer-top'>
          <a className='footer-logo' href='/' aria-label='amakkn logo'>
            <LazyLoadImage
              src={
                i18n.language === "ar"
                  ? PublicUrl + footerdata.footerlogo
                  : `/assets/img/logo/logo-en-white.png`
              }
              alt={imgattr}
            />
          </a>
          {/* {vendor === "amakkn" ? ( */}
          <p>
            {t("RealEstatePlatformLicensedByGeneralAuthorityForRealEstate")}
          </p>
          {/* ) : (
            <>
              <p>{userInfo.briefDescription}</p>
              <p>{userInfo.address}</p>
            </>
          )} */}
        </div>
        <div className='footer-center'>
          <div
            className='row m-0'
            style={
              i18n.language === "en" ? { flexDirection: "row-reverse" } : {}
            }>
            <div className='col-lg-4 col-md-6 col-12 mx-auto m-lg-0'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("FollowUsOnSocialMedia")}</h4>
                <ul className='social-icon'>
                  <li>
                    <a
                      href={resolveInstagramLink(isLargeScreen, "amakkn")}
                      target={isLargeScreen ? "_blank" : "_self"}
                      rel='noreferrer'
                      aria-label='social icon'>
                      <InstagramIcon
                        sx={{ fontSize: "34px", cursor: "pointer" }}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={resolveTwitterLink(isLargeScreen, "amakkn")}
                      target={isLargeScreen ? "_blank" : "_self"}
                      rel='noreferrer'
                      aria-label='social icon'>
                      <Twitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href={resolveFacebookLink(isLargeScreen, "amakkn")}
                      target={isLargeScreen ? "_blank" : "_self"}
                      rel='noreferrer'
                      aria-label='social icon'>
                      <FacebookIcon
                        sx={{ fontSize: "34px", cursor: "pointer" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title' style={{ marginBottom: "10px" }}>
                  {t("DownloadApp")}
                </h4>
                <div className='btns-wrapper'>
                  <GooglePlayBtn onClick={handleGoogleClick} />
                  <AppleStoreBtn onClick={handleAppleClick} />
                  <HuaweiAppGalleryBtn onClick={handleHuaweiClick} />
                </div>
              </div>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title' style={{ marginBottom: "10px" }}>
                  {t("Licensing")}
                </h4>
                <a
                  className={`footer-a d-flex ic_rega ${
                    i18n.language === "ar" ? "mr-lg-0" : "ml-lg-0"
                  } mx-auto justify-content-center justify-content-lg-end position-relative`}
                  aria-label='amakkn certificate image'
                  href='https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08db650b-a2ef-400c-8604-c3bbb4bbe0ba'>
                  {/* 210000357 */}
                  <LazyLoadImage
                    src={PublicUrl + "assets/img/rega.png"}
                    alt={imgattr}
                  />
                  <img
                    src={PublicUrl + "assets/img/qr.png"}
                    alt='license qr'
                    style={{
                      position: "absolute",
                      width: 50,
                      right: 129,
                      top: 20,
                    }}
                  />
                </a>
              </div>
            </div>
            <Accordion
              sx={accordionStyles}
              className='d-lg-none widget widget_nav_menu col-12'
              expanded={expanded1}
              onChange={() => {
                setExpanded1(!expanded1);
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
                <h4 className='widget-title m-0'>{t("Company")}</h4>
              </AccordionSummary>
              <AccordionDetails>{getCompanyLinks()}</AccordionDetails>
            </Accordion>
            <Accordion
              sx={accordionStyles}
              className='d-lg-none widget widget_nav_menu col-12'
              expanded={expanded2}
              onChange={() => {
                setExpanded2(!expanded2);
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
                <h4 className='widget-title m-0'>{t("PopularSearches")}</h4>
              </AccordionSummary>
              <AccordionDetails>{getPopularSeachLinks()}</AccordionDetails>
            </Accordion>
            <Accordion
              sx={accordionStyles}
              className='d-lg-none widget widget_nav_menu col-12'
              expanded={expanded3}
              onChange={() => {
                setExpanded3(!expanded3);
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
                <h4 className='widget-title m-0'>
                  {t("ExploreRealEstateADs")}
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                {getDiscoverPropertiesLinks()}
              </AccordionDetails>
            </Accordion>
            <div className='col-lg-3 d-lg-block d-none'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("ExploreRealEstateADs")}</h4>
                {getDiscoverPropertiesLinks()}
              </div>
            </div>
            <div className='col-lg-3 d-lg-block d-none'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("PopularSearches")}</h4>
                {getPopularSeachLinks()}
              </div>
            </div>
            <div className='col-lg-2 d-lg-block d-none m-0'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{t("Company")}</h4>
                {getCompanyLinks()}
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className='footer-bottom copy-right text-center'
          dangerouslySetInnerHTML={{
            __html: footerdata.copyrighttext,
          }}></div> */}
        <div className='footer-bottom copy-right text-center'>
          {t("Copyright")} {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}
