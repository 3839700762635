import { Autocomplete, Box, Chip, ClickAwayListener, Divider, FormControl, Grow, InputAdornment, MenuItem, Pagination, Paper, Popper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, debounce, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { useNavigate } from "react-router-dom";
import { preventString, renderInterestingType, renderPropertyType, trimPhone } from "../../../helpers";
import TuneIcon from '@mui/icons-material/Tune';
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { getRequests } from "../../../api/userApi";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";

const pageSize = 10;

function getFormatedPrice(price) {
  let value = price?.toString()?.replace(/[,]+/g, "");
  return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

const Row = ({ districtsOptions, row }) => {
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const history = useNavigate();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" align="right">
          {row.name}
        </TableCell>
        <TableCell align="right"><a rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"} href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline',direction:'ltr',display:'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
        <TableCell align="right" style={{ whiteSpace: 'pre-line', cursor:row?.status === 0 ? 'pointer':'auto' }} onClick={() => {
          if (row?.status === 0)
          history(`/admin/edit-request/${row.id}`)
        }}>
          {renderInterestingType(row.interestingType) + ' '+ renderPropertyType(row.propertyType)}
          {row.neighbourhood?.length > 0 ? ` ${t('In')}` : ''}{row.neighbourhood?.map((i,index) => `${index>0?` ${t("OR")} `:' '}` + districtsOptions.filter(
               (opt) => +i === +opt.id)?.[0]?.label)}
              {`${t("PriceBetween")} ${getFormatedPrice(row?.priceFrom)} - ${getFormatedPrice(row?.priceTo)} ${t("SAR")}`}
              {`\n${t("AreaBetween")} ${getFormatedPrice(row?.areaFrom)} - ${getFormatedPrice(row?.areaTo)} ${t("M2")}`}
        </TableCell>
        <TableCell align="right">
          <Chip label={row?.status === 0 ? t("New"): row?.status === 1? t("Accepted"):t("Rejected")} size="medium" style={{
                color: row?.status === 0 ? 'gold' : row?.status === 1 ? 'green' : '#d50000',
                borderColor: row?.status === 0 ? 'gold' : row?.status === 1 ?  'green' : '#d50000',
                background: '#fff',
                borderWidth: '1px',
                borderStyle: 'solid'
            }} />
          
</TableCell>
      </TableRow>
     
    </React.Fragment>
  );
}

const PropertiesRequestsPage = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { token } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    clientType: '',
    interestingType: 'all',
    priceFrom: '',
    priceTo: '',
    propertyType: 'all',
    area: '',
    city: '',
    neighbourhood: [],
    agentId: "all",
    updatedAt: 'all',
    status: 'all'
  });
  const [openPopper2, setOpenPopper2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const { t, i18n } = useTranslation();

  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const propertiesOptions = [
    {
      id: 'all',
      label: t("All"),
    },
    {
      id: 'أرض',
      label: t("Land"),
    },
    {
      id: 'دور',
      label: t("Floor"),
    },
    {
      id: 'شقة',
      label: t("Apartment"),
    },
    {
      id: 'فيلا',
      label: t("Villa"),
    },
    {
      id: 'استوديو',
      label: t("Studio"),
    },
    {
      id: 'غرفة',
      label: t("Room"),
    },
    {
      id: 'استراحة',
      label: t("Resort"),
    },
    {
      id: 'معرض',
      label: t("Showroom"),
    },
    {
      id: 'مكتب',
      label: t("Office"),
    },
    {
      id: 'مستودع',
      label: t("Warehouse"),
    },
    {
      id: 'مزرعة',
      label: t("Farm"),
    },
    {
      id: 'عمارة',
      label: t("Building"),
    }
  ];

  useEffect(() => {
    getData(token, (res) => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
      } else {
        toast.error(res.resStr);
      }
      setLoadingData(false);
    });
  },
    // eslint-disable-next-line
    [token, page, search, filters, i18n.language]);
     
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const hasFilter = () => {
    return filters.updatedAt !== 'all' || (filters.interestingType && filters.interestingType !== "all") || filters.priceFrom || filters.priceTo || (filters.propertyType && filters.propertyType !== "all") || filters.area || filters.city || filters.neighbourhood?.length > 0;
  }
    
  const renderFilterationBox = () => {
    return (
      <Box sx={{ backgroundColor: '#fff', width: '300px', display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
        <div className="filters-box">
          <h6 className="mt-2">{t("Desire")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.interestingType}
                inputProps={{
                  name: "interestingType",
                  id: "interestingType",
                }}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    interestingType: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='شراء'>{t("Buying")}</MenuItem>
                <MenuItem value='استئجار'>{t("Rent")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />

          <h6 className="mt-2">{t("PropertyType")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                options={propertiesOptions}
                value={
                  propertiesOptions.filter(
                    (i) => filters?.propertyType?.includes(i.id)
                  )?.[0] || undefined
                }
                sx={{ width: "100%" }}
                onChange={(event, newValue) => {
                 
                  setFilters({
                    ...filters,
                    propertyType: newValue?.id || undefined,
                  });
   
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    variant='outlined'
                  />
                )}
              />
 
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          
          <h6 className="mt-2">{t("Status")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.status}
                inputProps={{
                  name: "status",
                  id: "status",
                }}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    status: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value={0}>{t("New")}</MenuItem>
                <MenuItem value={1}>{t("Accepted")}</MenuItem>
                <MenuItem value={2}>{t("Rejected")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />

          
          <h6 className="mt-2 mb-3">{t("PriceRange")}</h6>
          <div className="col-12 mb-3 p-0">
            <TextField
              type="number"
              label={t("From")}
              sx={{ width: "50%" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={filters.priceFrom}
              size='small'
              onChange={(v) => {
                setFilters({
                  ...filters,
                  priceFrom: Math.abs(preventString(v.currentTarget.value)),
                });
              }}
            />
            <TextField
              type="number"
              label={t("To")}
              sx={{ width: "50%" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={filters.priceTo}
              size='small'
              onChange={(v) => {
                setFilters({
                  ...filters,
                  priceTo: Math.abs(preventString(v.currentTarget.value)),
                });
              }}
            />
          </div>

          
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("UpdatedAt")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.updatedAt}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    updatedAt: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='month'>{t("AMonthAgo")}</MenuItem>
                <MenuItem value='quarter'>{t("3MonthsAgo")}</MenuItem>
                <MenuItem value='year'>{t("AYearAgo")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("Location")}</h6>
          <div className="col-12 mb-3 mt-3 p-0">
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                options={regionsOptions}
                value={regionsOptions.filter(
                  (i) => +i.id === +filters?.area
                )?.[0] || null}
                sx={{ width: "100%" }}
                onChange={(v, newValue) => {
                  setFilters({
                    ...filters,
                    area: newValue?.id || "",
                    neighbourhood: [],
                    city: ''
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Region")}
                  />
                )}
              />
 
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                disabled={!filters?.area}
                options={citiesOptions.filter(
                  (i) => +i.regionId === +filters?.area
                )}
                value={
                  citiesOptions.filter(
                    (i) => +i.id === +filters?.city
                  )?.[0] || null
                }
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                onChange={(event, newValue) => {
                  setFilters({
                    ...filters,
                    city: newValue?.id || "",
                    neighbourhood: [],
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("City")}
                  />
                )}
              />
 
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                multiple
                disabled={!filters?.city}
                options={districtsOptions.filter(
                  (i) =>
                    +i.cityId === +filters?.city &&
                    +i.regionId === +filters?.area
                )}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                value={
                  districtsOptions.filter(
                    (i) => filters?.neighbourhood?.includes(+i.id)
                  ) || []
                }
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                onChange={(event, newValue) => {
                  const ids = newValue?.map(i => i.id);
                  setFilters({
                    ...filters,
                    neighbourhood: ids,
                  });
   
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Neighborhood")}
                  />
                )}
              />

            </FormControl>
          </div>
        </div>
        <div className="col-12 mb-3 p-0 mt-3 mx-0 row justify-content-center" style={{ gap: 15 }}>
         
          <button
            className={`btn action-btn primary-btn`}
            onClick={() => {
              setFilters({
                clientType: '',
                interestingType: 'all',
                priceFrom: '',
                priceTo: '',
                propertyType: 'all',
                area: '',
                city: '',
                neighbourhood: [],
                agentId: 'all',
                updatedAt: 'all',
                status: 'all'
              });
            }
            }
          >{t("Reset")}</button>
        </div>
      </Box>
    );
  }
  
  const getData = React.useMemo(
    () =>
      debounce((request, callback) => {
        setLoadingData(true);
        getRequests({
          userId: token,
          page: page,
          language: i18n.language === "ar" ? '0' : '1',
          search: search?.startsWith('05') ? search?.substring(1) : search,
          interestingType: filters.interestingType === 'all' ? '' : filters.interestingType,
          status: filters.status === 'all' ? undefined : filters.status,
          propertyType: filters.propertyType === "all" ? undefined : filters.propertyType,
          priceFrom: filters.priceFrom,
          area: filters.area,
          city: filters.city,
          neighbourhood: filters.neighbourhood,
          priceTo: filters.priceTo,
          // owner: filters.agentId === "all" ? undefined : filters.agentId,
          // updatedAt: filters.updatedAt === "all" ? '' : filters.updatedAt
        }).then(callback);
      }, 500),
    [token, page, search, filters, i18n.language],
  );

  return (
    <>
      <SubHeader OKElement={<></>} />
  
      <div style={{ display: 'flex', minHeight: '500px', width: '100%', flexDirection: 'column', marginBottom: '50px' }}>
        <div
          style={{ width: "100%", display: "flex", gap: 15, alignItems: 'center', marginBottom: 15 }}>
          <TextField
            id='standard-search'
            style={{ width: "80%", background: '#fff' }}
            placeholder={t("SearchByNameOrPhoneNumber")}
            type='search'
            size='small'
            value={search}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
               
          <button className={`btn ${hasFilter() ? 'primary-btn' : 'outlined-btn'} action-btn`} style={{ width: '20%', minWidth: 120 }} onClick={(e) => {
            setOpenPopper(!openPopper);
            setAnchorEl(anchorEl ? null : e.target);
          }}>
            {t("Filter")}   <TuneIcon />
          </button>
          <Popper
            sx={{
              zIndex: 100,
              offset: '55px 0 0 5px',
            }}
            open={openPopper}
            role={undefined}
            transition
            anchorEl={anchorEl}
            disablePortal={false}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => {
                    if (event.target.nodeName === 'BODY' && event.type === 'click') {
                      return;
                    } else {
                      setAnchorEl(null); setOpenPopper(false);
                    }
                       
                  }}>
                    {renderFilterationBox()}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
             
        </div>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell align="right">{t("Name")}</TableCell>
                <TableCell align="right">{t("PhoneNumber")}</TableCell>
                <TableCell align="right">{t("Request")}</TableCell>
                <TableCell align="right">{t("Status")}</TableCell>
                {/* <TableCell align="right">{t("Actions")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingData ? <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              ><TableCell colSpan={4}><Loadingdata /></TableCell></TableRow> :
                data?.length > 0 ? data.map((row) => (
                    
                  <Row
                    key={row.id}
                    row={row}
                    openPopper={openPopper2}
                    anchorEl={anchorEl2}
                    setAnchorEl={setAnchorEl2}
                    setOpenPopper={setOpenPopper2}
                    regionsOptions={regionsOptions}
                    citiesOptions={citiesOptions}
                    districtsOptions={districtsOptions}
                    />
                )) :
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  ><TableCell colSpan={4}><NoData msg={t("NoRequestsFound")} /></TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </div>
    </>
  );
}

export default PropertiesRequestsPage;
