import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Chip,
  ImageList,
  ImageListItem,
  useMediaQuery,
  DialogActions,
  Stack,
  Avatar,
  FormControl,
  TextField
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import moment from "moment";
import ImageModal from "../../../components/global-components/image-modal";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import CustomerDetails from "../customers/customer-details";
import { createDownloadLink, trimPhone } from "../../../helpers";
import { getTask, saveTaskComment } from "../../../api/userApi";
import { useAuth } from "../../../helpers/context";
import LoadingButton from "../../../components/global-components/loading-btn";
import { toast } from "react-toastify";

const publicUrl = process.env.REACT_APP_URL + "/";

const TaskDetails = ({ getData, openTaskDialog, setOpenTaskDialog, setTask, task, companyName }) => {
    const [selectedAttachment, setSelectedAttachment] = React.useState(undefined);
    const [imageModalOpened, setImageModalOpened] = React.useState(false);
    const matchDownMd = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const matchDownLg = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const { t, i18n } = useTranslation();
    const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
    const { token } = useAuth();
    const [content, setContent] = useState('');
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));


    const resolveStatusName = (status) => {
        const badge = (statusName) => {
            return <Chip label={statusName} size="medium" style={{
                color: status === '0' ? 'gold' : status === '1' ? "blue" : status === '3' ? 'purple' : status === '2' ? 'black' : '#d50000',
                borderColor: status === '0' ? 'gold' : status === '1' ? "blue" : status === '3' ? 'purple' : status === '2' ? 'black' : '#d50000',
                background: '#fff',
                borderWidth: '1px',
                borderStyle: 'solid'
            }} />;
        };
        switch (status) {
            case '0': return badge(t("New"));
            case '1': return badge(t("InProgress"));
            case '3': return badge(t("Done"));
            case '2': return badge(t("Postponed"));
            default: return '';
        }
    }
    
    return task?.id && (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={task?.propertyObject ? "xl" : "lg"}
                open={openTaskDialog}
                className="custom-dialog model"
                onClose={() => { setOpenTaskDialog(false); setTask({}); }}>
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{task.summary} {resolveStatusName(task.status)} <br />
                                <span style={{ fontSize: '13px' }}> {task.createdAt ? moment(new Date(task.createdAt)).add(3, 'hours').fromNow() : <></>}
                                    {` - ${t("AssignedTo")}: ` + (task.assignedTo === companyName ? t("MainAccount") : task.assignedTo)}
                                </span>
                            </h2>
                        </div>
                        <div className="dialog-actions">
                            <span onClick={() => { setOpenTaskDialog(false); setTask({}); }} style={{ cursor: 'pointer' }}>
                                <Close />
                            </span>
                        </div>
                    </div>
  
                </DialogTitle>
                <DialogContent>
                    <div className='card-body px-0'>
                        <div className="row">
                            <div className={`col-12 ${task?.propertyObject ? "col-lg-8" : ""}`}>
                           
                                <div className='row  mx-3'>
                                    {[
                                        {
                                            label: t("Description"),
                                            value: task.description
                                        },
                                        {
                                            label: t("Customer"),
                                            value: task.customerObject?.name || ''
                                        },
                                        {
                                            label: t("Deadline"),
                                            value: task.deadline ? moment(task.deadline).locale('en').format("YYYY-MM-DD") : t("Undefined")
                                        },
                                        {
                                            label: t("Priority"),
                                            value: task.priority ? (task.priority === "0" ? t("Low") : task.priority === "1" ? t("Medium") : t("High")) : t("NotAvailable")
                                        }
                                   
                                    ]?.map((item) => item.label === t("Customer") && !task.customerObject?.name ? null : (
                                        <div className={`col-12 mb-4 mb-lg-3 ${item.label === t("Description") ? '' : 'col-md-6 col-lg-4'}`} key={item?.label}>
                                            <strong>{item?.label}</strong>
                                            <span className="d-block mt-2 mb-3" style={{ whiteSpace: 'pre-line' }}>
                                                {item.label === t("Customer") ?
                                                    task?.customerObject?.name !== '' ? (<Stack direction="row" gap={2}>
                                                        <span onClick={() => setOpenCustomerDialog(true)} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', cursor: 'pointer' }}>{task?.customerObject?.name}</span>
                                                        <a target={isLargeScreen ? '_blank' : "_self"} rel="noreferrer" href={`tel:${trimPhone(task?.customerObject?.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(task?.customerObject?.phone)}</a>
                                                    </Stack>) : t("NotAvailable")
                                                    : item?.value}</span>
                                        </div>
                                    ))}
                                </div>
                                <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                    {t("Attachments")}
                                </Divider>
                                <div className="col-12 mt-2">
                                    {task?.attachments ? (<>
                                        <ImageList cols={matchDownMd ? 2 : matchDownLg ? (task?.propertyObject ? 3 : 4) : (task?.propertyObject ? 5 : 6)} gap={15}>
                                            {task?.attachments?.split(',').map((item, i) => (
                                                <ImageListItem
                                                    key={i}
                                                    style={{
                                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                                        cursor: "pointer",
                                                        borderRadius: 8,
                                                        height: "160px",
                                                        width: '100%'
                                                    }}
                                                    onClick={() => {
                                                        if (item?.includes(".pdf")) {
                                                            if (isLargeScreen)
                                                            window.open(item, "_blank");
                                                          else
                                                          createDownloadLink("file.pdf", item);
                                                        } else {
                                                            setSelectedAttachment(item);
                                                            setImageModalOpened(true);
                                                        }
                                                    }}>
                                                    {item?.includes(".pdf") ? (
                                                        <img
                                                            src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                                                            alt={`attachment-${i}`}
                                                            loading='lazy'
                                                            style={{ objectFit: "contain", height: "100%", padding: 10 }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${item}`}
                                                            srcSet={`${item}`}
                                                            alt={`attachment-${i}`}
                                                            loading='lazy'
                                                            style={{ objectFit: "cover", borderRadius: 8, height: "100%" }}
                                                        />
                                                    )}
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                        <ImageModal
                                            src={selectedAttachment}
                                            open={imageModalOpened}
                                            handleClose={() => setImageModalOpened(false)}
                                        />
                                    </>) : (
                                        <p>{t("NoAttachmentsHaveBeenAddedToThisTask")}</p>
                                    )}
                           
                                </div>

                                <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                    <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                        {t("Comments")}
                                    </div>
                                </Divider>
                           
                                <div className='row mx-0 mb-2 flex-column'>
                                    {task?.comments?.length > 0 && <ul className="p-0">
                                        {task.comments?.map(item => (
                                            <li key={item.id} style={{ marginBottom: '15px', listStyle: 'none', display: 'flex', gap: '10px' }}>
                                                <Avatar
                                                    alt={item.avatar}
                                                    sx={{ bgcolor: '#fff', width: '50px', height: '50px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                                                    src={item.avatar || publicUrl + "assets/img/defimgs/1_account.svg"}
                                                />
                                                <div className="comment-wrapper">
                                                    <div className="d-flex justify-content-between w-100" style={{ gap: '7px' }}>
                                                        <h6 style={{ marginBottom: '2px' }}>{item?.createdBy === companyName ? t("MainAccount") : item?.createdBy}</h6>
                                                        <p style={{ fontSize: '12px', marginTop: 0 }}> {moment(new Date(item.createdAt)).add(3, 'hours').fromNow()}</p>
                                                    </div>
                                                    <p style={{ fontSize: '12px', margin: 0}}> {item?.content}</p>
                                                </div>
                                            </li>
                                        ))}</ul>}
                                    <FormControl className="my-2 comment-box">
                                        <TextField
                                            placeholder={t("WriteYourComment")}
                                            sx={{ width: "100%" }}
                                            InputLabelProps={{ shrink: true }}
                                            value={content}
                                            size='small'
                                            multiline
                                            rows={2}
                                            InputProps={{
                                                endAdornment: (
                                                    <LoadingButton
                                                        style={{ justifyContent: "center", alignItems: 'center', borderRadius: '100% !important', width: '40px', height: '40px' }}
                                                        OKdisabled={content?.length === 0}
                                                        loading={isSubmittingData}
                                                        classes='primary-btn p-2'
                                                        handleClick={() => {
                                                            setIsSubmittingData(true);
                                                            saveTaskComment({
                                                                userId: token,
                                                                taskId: task.id,
                                                                content: content,
                                                                language: i18n.language === 'ar' ? '0' : '1'
                                                            }).then(res => {
                                                                if (res.resCode === 0) {
                                                                    setContent("")
                                                                    toast.success(t("CommentAddedSuccessfully"));
                                                                    getTask({
                                                                        userId: token,
                                                                        language: i18n.language === 'ar' ? '0' : '1',
                                                                        taskId: task?.id
                                                                    }).then((res) => {
                                                                        if (res.resCode === 0) {
                                                                            setTask(res.response);
                                                                        }
                                                                    });
                                                                    getData();
                                                                } else {
                                                                    toast.error(res.resStr);
                                                                }
                                                            }).finally(() => setIsSubmittingData(false));
                                                        }}
                                                        label={<Send sx={i18n.language === "ar" ? { transform: 'rotate(-180deg)', fontSize: '22px' } : { fontSize: '22px' }} />}
                                                    />
                                                ),
                                            }}
                                            onChange={(e) => setContent(e.currentTarget.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        
                            {task?.propertyObject && (<div className="col-12 col-lg-4">
                                <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                    {t("TaskRelatedRealEstateAD")}
                                </Divider>
                                <div
                                    className='col-12 col-md-6 col-lg-8 m-auto m-lg-0'
                                    style={{ cursor: "pointer" }}>
                                    <PropertyCardForAdmin item={task?.propertyObject} hideActions />
                                </div>
                            </div>)}

                    
                        </div>
                    </div>
                  
                </DialogContent>
                <DialogActions sx={{ paddingInline: '25px', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px' }}>
                    <span style={{ fontSize: '13px' }}><b>{t("AddedBy")}:</b> {task.addedBy === companyName ? t("MainAccount") : task.addedBy}</span>
                    <span style={{ fontSize: '13px' }}><b>{t("UpdatedAt")}:</b> {task.updatedAt ? moment(new Date(task.updatedAt)).add(3, 'hours').fromNow() : t("NotAvailable")}</span>
                </DialogActions>
            </Dialog>
            {task?.customerObject && (
                <CustomerDetails
                    openCustomerDialog={openCustomerDialog}
                    setOpenCustomerDialog={setOpenCustomerDialog}
                    companyName={companyName}
                    setClient={() => { }}
                    client={task.customerObject} />
            )}
        </>
    );
}

export default TaskDetails;
