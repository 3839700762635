import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AppLayout from "../../components/layouts/app-layout";
import { useAuth } from "../../helpers/context";
import { getBrokerRegaInfo, getDomainInfo } from "../../api/userApi";
import LoadingData from "../../components/global-components/loading-data";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CORPORATE_LICENSE_URI, INDIVIDUAL_LICENSE_URI } from "../../constants";
import AccessDenied from "../shared/access-denied";
import { useTranslation } from "react-i18next";

const AllowedUsers = [
  "155668",
  "34882",
  "32955",
  "34296",
  "32387",
  "21532",
  "12818",
  "161438",
  "165199",
  "1"
];

const WLUserDetails = (props) => {
  const { token, UserId } = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const { domain } = useParams();
  const [License, setLicense] = useState();
  const { t } = useTranslation();
  
  useEffect(() => {
    if (token && domain && AllowedUsers.includes(UserId)) {
      setLoadingData(true);
      getDomainInfo({ domain })
        .then((result) => {
          if (result.resCode === 0) {
            setData(result.response[0]);
            if (result.response[0]?.advertiserNumber)
              getBrokerRegaInfo({
                licenseNumber: result.response[0]?.advertiserNumber,
                // licenseType: "Bml",
              })
                .then((res) => {
                  if (res.resCode === 0) {
                    setLicense(res.response);
                  }
                })
                .catch((err) => { });
          } else {
            toast.error(result.resStr);
          }
        })
        .finally(() => setLoadingData(false));
    }
  }, [token, domain, UserId]);

  return (
    <AppLayout pageTitle={t("SubscriberDetails")} needAuth regaNavMode>
      {token && AllowedUsers.includes(UserId) ? (
        <div className='page-width favorite-area pd-top-90 mg-bottom-100 text-center'>
          {!loadingData ? (
            <>
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "center !important",
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#000",
                }}>
                {t("SubscriberDetails")}
                <Box
                  sx={{
                    textAlign: "center !important",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#000",
                    flexDirection: "column",
                    display: "flex",
                  }}>
                  <span style={{ fontSize: 17, fontWeight: 500 }}>
                    {data?.name}
                  </span>
                  <div className="mt-2">
                    <span
                      style={{
                        color: "var(--main-color-one)",
                        fontWeight: 500,
                      }}>
                      {data?.accountType ? (
                        +data?.accountType === 1 ? (
                          `${t("IDNumber")}: `
                        ) : (
                          `${t("CommercialRegistrationNo")}: `
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                    <span>{data?.advertiserId || t("NotAvailable")}</span>
                  </div>

                  <div className="mt-2">
                    <span
                      style={{
                        color: "var(--main-color-one)",
                        fontWeight: 500,
                      }}>
                      {t("Domain")}:{" "}
                    </span>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        textDecoration: "underline",
                        color: "var(--main-color-one)",
                      }}
                      href={`https://${domain}`}>
                      {domain}
                    </a>
                  </div>
                  <div className="mt-2">
                    <span
                      style={{
                        color: "var(--main-color-one)",
                        fontWeight: 500,
                      }}>
                      {t("FalLicense")}:{" "}
                    </span>
                    {(License && License?.licenseNumber) ||
                      data?.advertiserNumber ? (
                      <>
                        {License && License?.licenseNumber ? (
                          <a
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              textDecoration: "underline",
                              color: "var(--main-color-one)",
                            }}
                            href={
                              +data?.accountType === 1
                                ? INDIVIDUAL_LICENSE_URI + License?.id
                                : CORPORATE_LICENSE_URI + License?.id
                            }>
                            {License?.licenseNumber}
                          </a>
                        ) : (
                          <span>{data?.advertiserNumber}</span>
                        )}
                      </>
                    ) : (
                      <span>{t("NotAvailable")}</span>
                    )}
                  </div>
                  <div className="mt-2">
                    <span
                      style={{
                        color: "var(--main-color-one)",
                        fontWeight: 500,
                      }}>
                      {t("NumberExpiryDateSaudiBusinessCenterCertificate")}:{" "}
                    </span>
                 
                    <span>{data?.crNumber ? data?.crNumber + ' / ' + data?.expriyDate : t("NotAvailable")}</span>
                  
                  </div>
                </Box>
              </Box>
              <div className='row align-items-center justify-content-center pd-top-30'>
                <div className='custom-card'>
                  <div className='card-header'>
                    <h4>{t("SubscriberDetails")}</h4>
                    <h6>{t("SubscriberDetailsDesc")}</h6>
                  </div>
                  <div className='card-body text-left' dir='ltr'>
                    <div className='custom-card'>
                      <div className='card-header'>
                        <h4>{t("DomainInformation")}</h4>
                      </div>
                      <div className='card-body' dir='ltr'>
                        <div className='row m-0'>
                          <div
                            className='subscriber-card col-12 text-center text-sm-left col-lg-8 col-xl-6 row justify-content-around mx-auto mb-4 pt-5'
                            style={{ gap: 50 }}>
                            <div>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Domain
                              </p>
                              <p className="text-left">{data?.info?.DomainName}</p>

                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Registration date
                              </p>
                              <p className="text-left">{data?.info?.CreationDate}</p>

                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Expiration date
                              </p>
                              <p className="text-left">{data?.info?.ExpirationDate}</p>
                            </div>
                            <div>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                }}>
                                Name servers
                              </p>
                              {data?.info &&
                                data?.info?.Nameservers?.length > 0 &&
                                data?.info?.Nameservers?.map((item) => (
                                  <p className="text-left">{item.Name}</p>
                                ))}
                            </div>

                            <span className='circle circle-1'></span>
                            <span className='circle circle-2'></span>
                          </div>
                          <div
                            className='px-0 col-12 row align-items-center justify-content-center mx-auto'
                            style={{ gap: 30 }}>
                            <div className='subscriber-card'>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                }}>
                                Registrant Contact
                              </p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.FirstName}{" "}
                                {data?.info?.RegistrantContact?.LastName}
                              </p>
                              <p className="text-left">{data?.info?.RegistrantContact?.Email}</p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.PhoneNumber}
                              </p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.AddressLine1}
                              </p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.AddressLine2}
                              </p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.City}{" "}
                                {data?.info?.RegistrantContact?.ZipCode}
                              </p>
                              <p className="text-left">
                                {data?.info?.RegistrantContact?.CountryCode}
                              </p>
                              <p className="text-left">
                                Privacy protection (
                                <span
                                  style={{
                                    color: data?.info?.RegistrantPrivacy
                                      ? "#4dc247"
                                      : "#d00404",
                                    fontWeight: 600,
                                  }}>
                                  {data?.info?.RegistrantPrivacy ? "On" : "Off"}
                                </span>
                                )
                              </p>
                              <span className='circle circle-1'></span>
                              <span className='circle circle-2'></span>
                            </div>

                            <div className='subscriber-card'>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                }}>
                                Tech Contact
                              </p>
                              <p className="text-left">
                                {data?.info?.TechContact?.FirstName}{" "}
                                {data?.info?.TechContact?.LastName}
                              </p>
                              <p className="text-left">{data?.info?.TechContact?.Email}</p>
                              <p className="text-left">{data?.info?.TechContact?.PhoneNumber}</p>
                              <p className="text-left">{data?.info?.TechContact?.AddressLine1}</p>
                              <p className="text-left">{data?.info?.TechContact?.AddressLine2}</p>
                              <p className="text-left">
                                {data?.info?.TechContact?.City}{" "}
                                {data?.info?.TechContact?.ZipCode}
                              </p>
                              <p className="text-left">{data?.info?.TechContact?.CountryCode}</p>
                              <p className="text-left">
                                Privacy protection (
                                <span className="text-left"
                                  style={{
                                    color: data?.info?.TechPrivacy
                                      ? "#4dc247"
                                      : "#d00404",
                                    fontWeight: 600,
                                  }}>
                                  {data?.info?.TechPrivacy ? "On" : "Off"}
                                </span>
                                )
                              </p>
                              <span className='circle circle-1'></span>
                              <span className='circle circle-2'></span>
                            </div>

                            <div className='subscriber-card'>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                }}>
                                Admin Contact
                              </p>
                              <p className="text-left">
                                {data?.info?.AdminContact?.FirstName}{" "}
                                {data?.info?.AdminContact?.LastName}
                              </p>
                              <p className="text-left">{data?.info?.AdminContact?.Email}</p>
                              <p className="text-left">{data?.info?.AdminContact?.PhoneNumber}</p>
                              <p className="text-left">{data?.info?.AdminContact?.AddressLine1}</p>
                              <p className="text-left">{data?.info?.AdminContact?.AddressLine2}</p>
                              <p className="text-left">
                                {data?.info?.AdminContact?.City}{" "}
                                {data?.info?.AdminContact?.ZipCode}
                              </p>
                              <p className="text-left">{data?.info?.AdminContact?.CountryCode}</p>
                              <p className="text-left">
                                Privacy protection (
                                <span className="text-left"
                                  style={{
                                    color: data?.info?.AdminPrivacy
                                      ? "#4dc247"
                                      : "#d00404",
                                    fontWeight: 600,
                                  }}>
                                  {data?.info?.AdminPrivacy ? "On" : "Off"}
                                </span>
                                )
                              </p>
                              <span className='circle circle-1'></span>
                              <span className='circle circle-2'></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='custom-card'>
                      <div className='card-header'>
                        <h4>{t("HostingCompanyInformation")}</h4>
                      </div>
                      <div className='card-body text-left' dir='ltr'>
                        <div className='row m-0'>
                          <div
                            className='subscriber-card text-center text-sm-left col-12 col-lg-8 col-xl-6 row justify-content-around mx-auto mb-4 pt-5'
                            style={{ gap: 50 }}>
                            <div>
                              <p
                                className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Domain
                              </p>
                              <p className="text-left">{data?.info?.DomainName}</p>

                              <p
                                className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Registration date
                              </p>
                              <p className="text-left">{data?.info?.CreationDate}</p>

                              <p
                                className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}>
                                Expiration date
                              </p>
                              <p className="text-left">{data?.info?.ExpirationDate}</p>
                            </div>
                            <div>
                              <p className="text-left"
                                style={{
                                  color: "var(--main-color-one)",
                                  fontWeight: 600,
                                }}>
                                Name servers
                              </p>
                              {data?.info &&
                                data?.info?.Nameservers?.length > 0 &&
                                data?.info?.Nameservers?.map((item) => (
                                  <p className="text-left">{item.Name}</p>
                                ))}
                            </div>

                            <span className='circle circle-1'></span>
                            <span className='circle circle-2'></span>
                          </div>

                          <div
                            className='col-12 mx-auto row align-items-center justify-content-center'
                            style={{ gap: 30 }}>
                            <div className='subscriber-card col-12 mx-auto'>
                              {data?.records && data?.records.length > 0 && (
                                <div style={{ overflowX: "auto" }}>
                                  <table
                                    border={1}
                                    style={{
                                      width: "100%",
                                      margin: "20px auto 0",
                                      borderColor: "#999",
                                      fontSize: 14,
                                      borderRadius: 10,
                                    }}>
                                    <thead>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "4px 10px",
                                            background: "var(--main-color-one)",
                                            color: "#fff",
                                            fontSize: 14,
                                          }}>
                                          Record name
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "4px 10px",
                                            background: "var(--main-color-one)",
                                            color: "#fff",
                                            fontSize: 14,
                                          }}>
                                          Type
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "4px 10px",
                                            background: "var(--main-color-one)",
                                            color: "#fff",
                                            fontSize: 14,
                                          }}>
                                          DNS name / Resource records
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "4px 10px",
                                            background: "var(--main-color-one)",
                                            color: "#fff",
                                            fontSize: 14,
                                          }}>
                                          TTL
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.records?.map(
                                        (item) =>
                                          item.Type !== "CNAME" && (
                                            <tr>
                                              <td
                                                className="text-left"
                                                style={{ padding: "4px 10px" }}>
                                                {item.Name}{" "}
                                              </td>
                                              <td
                                                className="text-left"
                                                style={{ padding: "4px 10px" }}>
                                                {item.Type}{" "}
                                              </td>
                                              <td
                                                className="text-left"
                                                style={{ padding: "4px 10px" }}>
                                                {item.Type === "A"
                                                  ? item.AliasTarget?.DNSName
                                                  : item.ResourceRecords?.map(
                                                    (i, index) =>
                                                      i.Value +
                                                      (index ===
                                                        item.ResourceRecords
                                                          ?.length -
                                                        1
                                                        ? ""
                                                        : ", ")
                                                  )}{" "}
                                              </td>
                                              <td
                                                className="text-left"
                                                style={{ padding: "4px 10px" }}>
                                                {item.TTL}{" "}
                                              </td>
                                            </tr>
                                          )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              <span className='circle circle-1'></span>
                              <span className='circle circle-2'></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='custom-card'>
                      <div className='card-header'>
                        <h4>{t("SSLCertificateInformation")}</h4>
                      </div>
                      <div className='card-body text-left' dir='ltr'>
                        <div
                          className='m-0 row align-items-center justify-content-center'
                          style={{ gap: 30 }}>
                          <div className='subscriber-card col-12 mx-auto '>
                            {data?.records && data?.records.length > 0 && (
                              <div style={{ overflowX: "auto" }}>
                                <table
                                  border={1}
                                  style={{
                                    width: "100%",
                                    margin: "20px auto 0",
                                    borderColor: "#999",
                                    fontSize: 14,
                                  }}>
                                  <thead>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "4px 10px",
                                          background: "var(--main-color-one)",
                                          color: "#fff",
                                          fontSize: 14,
                                        }}>
                                        Record name
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "4px 10px",
                                          background: "var(--main-color-one)",
                                          color: "#fff",
                                          fontSize: 14,
                                        }}>
                                        Type
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "4px 10px",
                                          background: "var(--main-color-one)",
                                          color: "#fff",
                                          fontSize: 14,
                                        }}>
                                        Resource records
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "4px 10px",
                                          background: "var(--main-color-one)",
                                          color: "#fff",
                                          fontSize: 14,
                                        }}>
                                        TTL
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.records?.map(
                                      (item) =>
                                        item.Type === "CNAME" && (
                                          <tr>
                                            <td className="text-left" style={{ padding: "4px 10px" }}>
                                              {item.Name}{" "}
                                            </td>
                                            <td className="text-left" style={{ padding: "4px 10px" }}>
                                              {item.Type}{" "}
                                            </td>
                                            <td className="text-left" style={{ padding: "4px 10px" }}>
                                              <span>
                                                {item.ResourceRecords?.map(
                                                  (i, index) =>
                                                    i.Value +
                                                    (index ===
                                                      item.ResourceRecords
                                                        ?.length -
                                                      1
                                                      ? ""
                                                      : ", ")
                                                )}
                                              </span>{" "}
                                            </td>
                                            <td className="text-left" style={{ padding: "4px 10px" }}>
                                              {item.TTL}{" "}
                                            </td>
                                          </tr>
                                        )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}

                            <span className='circle circle-1'></span>
                            <span className='circle circle-2'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <LoadingData />
          )}
        </div>
      ) : (
        <AccessDenied
          btnLink='/'
          btnText={t("BackToHomePage")}
          desc={t("YouCannotAccessThisPage")}
        />
      )}
    </AppLayout>
  );
}

export default WLUserDetails;
