import React, { useEffect, useState } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import LoadingButton from "../../../components/global-components/loading-btn";
import { Alert, Box, Dialog, DialogContent, DialogTitle, FormHelperText, TextField } from "@mui/material";
import { getWLProfile, saveWLProfile } from "../../../api/userApi";
import { toast } from "react-toastify";
import { getAnalyticsId, getGTMId, validateAnalyticsID, validateGTMID } from "../../../helpers";
import Close from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CustomerSupportIcon } from "../../../constants/icons";
import ConnectWithWhatsApp from "./components/connect-with-whatsapp";
// import io from 'socket.io-client';
// import QRCode from "react-qr-code";
// import { WHATSAPP_API } from "../../../constants";

// const socket = io.connect(WHATSAPP_API, {});


export default function Addons() {
  const { token, vendor, UserType } = useAuth();
  const [WlUser, setWlUser] = useState();
  const { t, i18n } = useTranslation();
  const [GTMId, setGTMId] = useState('');
  const [isSubmittingGTM, setIsSubmittingGTM] = useState(false);
  const [GTMError, setGTMError] = useState('');
  const [openGTMStepsDialog, setOpenGTMStepsDialog] = useState(false);
  const [extractGTMID, setExtractGTMID] = useState(false);
  const [GTMCode, setGTMCode] = useState('');
  const [newGTMId, setNewGTMId] = useState('');
  const [AnalyticsId, setAnalyticsId] = useState('');
  const [isSubmittingAnalytics, setIsSubmittingAnalytics] = useState(false);
  const [AnalyticsError, setAnalyticsError] = useState('');
  const [openAnalyticsStepsDialog, setOpenAnalyticsStepsDialog] = useState(false);
  const [extractAnalyticsID, setExtractAnalyticsID] = useState(false);
  const [AnalyticsCode, setAnalyticsCode] = useState('');
  const [newAnalyticsId, setNewAnalyticsId] = useState('');
  
  // useEffect(() => {
  //   if (number) {
  //     socket.emit("connected", "Hello from client");
    
  //     socket.on("qr", (data) => {
  //       console.log(data)
  //       console.log({ number })
  //       if (number === data.phone)
  //         setQR(data.qr);
  //     });

  //     socket.on("loggedIn", (data) => {
  //       if (number === data.phone)
  //         setIsLoggedIn(true);
  //     });

  //     socket.on("loggedOut", (data) => {
  //       if (number === data.phone)
  //         setIsLoggedIn(false);
  //     });
      
  //   }
    
  // }, [number]);
  
  const getData = () => {
    if (UserType !== '5') {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setWlUser(_userInfo.response.wlUser);
          setGTMId(_userInfo.response.wlUser.gtm_container || '');
          setAnalyticsId(_userInfo.response.wlUser.gtm_measurement_id || '');
        }
      });
    }
  }

  useEffect(() => {
    if (token) {
      if (UserType !== '5') {
        getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setWlUser(_userInfo.response.wlUser);
            setGTMId(_userInfo.response.wlUser.gtm_container || '');
            setAnalyticsId(_userInfo.response.wlUser.gtm_measurement_id || '');
            // socket.emit("createSession", {
            //   phone: trimPhone(_userInfo.response.wlUser.whatsapp)
            // });
          }
        });
      } else {
        // getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
        //   if (_userInfo.resCode === 0) {
        //     // socket.emit("createSession", {
        //     //   phone: trimPhone(_userInfo.response.userProfile.phone)
        //     // });
        //   }
        // });
      }

    }
  },  // eslint-disable-next-line
    [token, vendor, UserType]);
  
  const saveGTMID = (event, code) => {
    event.preventDefault();
    setExtractGTMID(false);
    if (GTMId && !validateGTMID(GTMId)) {
      setGTMError(t("PleaseEnterAValidID"));
      return;
    }
    setIsSubmittingGTM(true);
    saveWLProfile({
      ...WlUser,
      userId: token,
      // whatsapp: resolvePhonePrefix(number),
      language: i18n.language === 'ar' ? '0' : '1',
      gtm_container: code || GTMId
    }).then(result => {
      if (result.resCode === 0) {
        toast.success(t("LinkedSuccessfully"));
        setNewGTMId('');
        getData();
      } else {
        toast.error(result.resStr);
      }
    }).finally(() => setIsSubmittingGTM(false));
  }

  const saveAnalyticsID = (event, code) => {
    event.preventDefault();
    setExtractAnalyticsID(false);
    if (AnalyticsId && !validateAnalyticsID(AnalyticsId)) {
      setAnalyticsError(t("PleaseEnterAValidID"));
      return;
    }
    setIsSubmittingAnalytics(true);
    saveWLProfile({
      ...WlUser,
      userId: token,
      // whatsapp: resolvePhonePrefix(number),
      language: i18n.language === 'ar' ? '0' : '1',
      gtm_measurement_id: code || AnalyticsId
    }).then(result => {
      if (result.resCode === 0) {
        toast.success(t("LinkedSuccessfully"));
        setNewAnalyticsId('');
        getData();
      } else {
        toast.error(result.resStr);
      }
    }).finally(() => setIsSubmittingAnalytics(false));
  }
  
  const unLinkGTM = (event) => {
    event.preventDefault();
    setIsSubmittingGTM(true);
    saveWLProfile({
      ...WlUser,
      userId: token,
      // whatsapp: resolvePhonePrefix(number),
      language: i18n.language === 'ar' ? '0' : '1',
      gtm_container: ''
    }).then(result => {
      if (result.resCode === 0) {
        toast.success(t("UnLinkedSuccessfully"));
        getData();
        setGTMId("");
        setNewGTMId("");
      } else {
        toast.error(result.resStr);
      }
    }).finally(() => setIsSubmittingGTM(false));
  }

  const unLinkAnalytics = (event) => {
    event.preventDefault();
    setIsSubmittingAnalytics(true);
    saveWLProfile({
      ...WlUser,
      userId: token,
      // whatsapp: resolvePhonePrefix(number),
      language: i18n.language === 'ar' ? '0' : '1',
      gtm_measurement_id: ''
    }).then(result => {
      if (result.resCode === 0) {
        toast.success(t("UnLinkedSuccessfully"));
        getData();
        setAnalyticsId("");
        setNewAnalyticsId("");
      } else {
        toast.error(result.resStr);
      }
    }).finally(() => setIsSubmittingAnalytics(false));
  }


  return (
    <>
      <SubHeader OKElement={<></>} />
      <ConnectWithWhatsApp />

      {UserType !== '5' && (
        <>
          <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
            <div className="card-header">
              <div className="d-flex flex-row justify-content-start align-items-center" style={{ gap: '10px' }}>
                <img alt="gtm logo" src="/assets/img/logo/gtm-logo.png" width={50} height={50} />
                <div>
                  <h4>{t("LinkWithGTM")}</h4>
                  <h6>{t("LinkWithGTMDesc")}</h6>
                </div>
              </div>
              {newGTMId && (
                <LoadingButton
                  classes="mt-3 mt-md-0 primary-btn py-2 px-3"
                  handleClick={saveGTMID}
                  OKdisabled={!GTMId}
                  label={t("ActivateLinking")}
                  loading={isSubmittingGTM}
                />
              )}
            
            </div>
            <div className="card-body">
              <div className="row mx-0">
                {GTMId && !newGTMId ? (
                  <Alert
                    sx={{ "& .MuiAlert-message": { width: "100%" } }}
                    severity='success'
                    className='custom-alert2 custom-alert w-100 align-items-center'
                  >
                    <div className='w-100 d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                      <span>
                        {t("LinkedWithGTMDesc", { id: GTMId })}
                      </span>
                      <LoadingButton
                        style={{
                          width: "fit-content",
                          borderColor: "#222",
                          color: "#222",
                        }}
                        classes='btn primary-btn mt-2 mt-md-0 delete-btn'
                        variant='outlined'
                        handleClick={(e) => unLinkGTM(e)}
                        loading={isSubmittingGTM}
                        label={t("Unlink")}
                      />
                    </div>
                  </Alert>
                ) : (
                  <>
                    <div className="col-12 p-0">
                      <p>
                        {t("YouCanViewTheLinkingSteps")}
                        <span
                          style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer', fontWeight: 600 }}
                          onClick={() => setOpenGTMStepsDialog(true)}>
                          {t("FromHere")}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 p-0 mt-2">
                      <TextField
                        label={t("GTMID")}
                        sx={{ direction: 'ltr', textAlign: 'left' }}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        placeholder="GTM-XXXXXXXX"
                        fullWidth
                        className='mt-4'
                        value={GTMId}
                        onChange={(v) => {
                          v.preventDefault();
                          let code = getGTMId(v.currentTarget.value);
                          setGTMId(code);
                          // if (!validateGTMID(code)) {
                          setNewGTMId(code);
                          // } else {
                          //   saveGTMID(v, code);
                          // }
                          setGTMError('');
                        }}
                      />
                      <p style={{ color: 'var(--main-color-one)', cursor: 'pointer', fontWeight: 500 }} className="my-2" onClick={() => setExtractGTMID(true)}>{t("ExtractIDByCode")}</p>
                      {GTMError &&
                        <FormHelperText component={Box}>
                          <Alert severity="error" className="custom-alert">
                            {GTMError}
                          </Alert>
                        </FormHelperText>
                      }
                      {extractGTMID && (
                        <>
                          <button className="outlined-btn btn py-1 mt-3" style={{ height: '35px' }} onClick={() => setExtractGTMID(false)}>{t("Hide")}</button>
                          <TextField
                            sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& textarea': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={7}
                            value={GTMCode}
                            className='my-2'
                            onChange={(v) => {
                              v.preventDefault();
                              setGTMCode(v.currentTarget.value);
                            }}
                          />
                          <button className="primary-btn btn py-1" style={{ height: '35px' }} onClick={(e) => {
                            if (validateGTMID(getGTMId(GTMCode))) {
                              setGTMId(getGTMId(GTMCode));
                              // setNewGTMId(getGTMId(GTMCode));
                              setGTMError('');
                              setExtractGTMID(false);
                              saveGTMID(e, getGTMId(GTMCode));
                            } else {
                              setGTMError(t("PleaseEnterAValidID"));
                            }
                          }}>{t("Extract")}</button>
                        </>
                      )}
                
                    </div>
                  </>
                )}
            
              </div>
            </div>
          </div>

          
          <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
            <div className="card-header">
              <div className="d-flex flex-row justify-content-start align-items-center" style={{ gap: '10px' }}>
                <img alt="Google Analytics logo" src="/assets/img/logo/analytics-logo.svg" width={50} height={50} />
                <div>
                  <h4>{t("LinkWithAnalytics")}</h4>
                  <h6>{t("LinkWithAnalyticsDesc")}</h6>
                </div>
              </div>
              {newAnalyticsId && (
                <LoadingButton
                  classes="mt-3 mt-md-0 primary-btn py-2 px-3"
                  handleClick={saveAnalyticsID}
                  OKdisabled={!AnalyticsId}
                  label={t("ActivateLinking")}
                  loading={isSubmittingAnalytics}
                />
              )}
            
            </div>
            <div className="card-body">
              <div className="row mx-0">
                {AnalyticsId && !newAnalyticsId ? (
                  <Alert
                    sx={{ "& .MuiAlert-message": { width: "100%" } }}
                    severity='success'
                    className='custom-alert2 custom-alert w-100 align-items-center'
                  >
                    <div className='w-100 d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                      <span>
                        {t("LinkedWithAnalyticsDesc", { id: AnalyticsId })}
                      </span>
                      <LoadingButton
                        style={{
                          width: "fit-content",
                          borderColor: "#222",
                          color: "#222",
                        }}
                        classes='btn primary-btn mt-2 mt-md-0 delete-btn'
                        variant='outlined'
                        handleClick={(e) => unLinkAnalytics(e)}
                        loading={isSubmittingAnalytics}
                        label={t("Unlink")}
                      />
                    </div>
                  </Alert>
                ) : (
                  <>
                    <div className="col-12 p-0">
                      <p>
                        {t("YouCanViewTheLinkingSteps")}
                        <span
                          style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer', fontWeight: 600 }}
                          onClick={() => setOpenAnalyticsStepsDialog(true)}>
                          {t("FromHere")}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 p-0 mt-2">
                      <TextField
                        label={t("AnalyticsID")}
                        sx={{ direction: 'ltr', textAlign: 'left' }}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        placeholder="G-XXXXXXXXXX"
                        fullWidth
                        className='mt-4'
                        value={AnalyticsId}
                        onChange={(v) => {
                          v.preventDefault();
                          let code = getAnalyticsId(v.currentTarget.value);
                          setAnalyticsId(code);
                          setNewAnalyticsId(code);
                          setAnalyticsError('');
                        }}
                      />
                      <p style={{ color: 'var(--main-color-one)', cursor: 'pointer', fontWeight: 500 }} className="my-2" onClick={() => setExtractAnalyticsID(true)}>{t("ExtractIDByCode")}</p>
                      {AnalyticsError &&
                        <FormHelperText component={Box}>
                          <Alert severity="error" className="custom-alert">
                            {AnalyticsError}
                          </Alert>
                        </FormHelperText>
                      }
                      {extractAnalyticsID && (
                        <>
                          <button className="outlined-btn btn py-1 mt-3" style={{ height: '35px' }} onClick={() => setExtractAnalyticsID(false)}>{t("Hide")}</button>
                          <TextField
                            sx={{ width: "100%", '& .MuiInputBase-root': { direction: 'ltr' }, '& textarea': { direction: 'ltr', textAlign: 'left', textAlignLast: 'left' } }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={7}
                            value={AnalyticsCode}
                            className='my-2'
                            onChange={(v) => {
                              v.preventDefault();
                              setAnalyticsCode(v.currentTarget.value);
                            }}
                          />
                          <button className="primary-btn btn py-1" style={{ height: '35px' }} onClick={(e) => {
                            if (validateAnalyticsID(getAnalyticsId(AnalyticsCode))) {
                              setAnalyticsId(getAnalyticsId(AnalyticsCode));
                              setAnalyticsError('');
                              setExtractAnalyticsID(false);
                              saveAnalyticsID(e, getAnalyticsId(AnalyticsCode));
                            } else {
                              setAnalyticsError(t("PleaseEnterAValidID"));
                            }
                          }}>{t("Extract")}</button>
                        </>
                      )}
                
                    </div>
                  </>
                )}
            
              </div>
            </div>
          </div>
        </>
      )}

      <div className='custom-card without-header call-us'>
        <div className="card-body">
          <div className="flex-wrapper">
            <div>
              <h5>{t("FacingProblemLinkingWithWhatsApp")}</h5>
              <p>{t("DoNotHesitateToContactUs")}</p>
            </div>
            <div>
              <Link to="/admin/ticket" className="primary-btn justify-content-between" >
                <CustomerSupportIcon />
                <span>
                  {t("CustomerSupport")}
                </span>
              </Link>
            </div>
            
          </div>
        </div>
      </div>
      {UserType !== '5' && (
        <>
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openGTMStepsDialog}
            className="custom-dialog model"
            onClose={() => setOpenGTMStepsDialog(false)}>
            <DialogTitle>
              <div className="dialog-head">
                <div>
                  <h2>{t("GTMLinkingSteps")}</h2>
                </div>
                <div className="dialog-actions">
                  <span onClick={() => setOpenGTMStepsDialog(false)} style={{ cursor: 'pointer' }}>
                    <Close />
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginTop: '15px', fontSize: '14px', maxWidth: '100%' }}>
                <h5 style={{ color: 'var(--main-color-one)' }}>{t("GTMStep01")}</h5>
                <p>1. {t("GTMStep03-1")}<a href="https://tagmanager.google.com/#/home" style={{ textDecoration: 'underline', color: 'var(--main-color-one)' }}>Google Tag Manager</a> {t("GTMStep03-2")}</p>
                <img src="/assets/img/others/gtm/step-01.png" alt="step-01" className="mb-5 d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p>2. {t("GTMStep04")}</p>
                <img src="/assets/img/others/gtm/step-02.png" alt="step-02" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p>1- {t("GTMStep04-1")}</p>
                <p>2- {t("GTMStep04-2")}</p>
                <p className="mb-5">3- {t("GTMStep04-3")}</p>

                <p>3. {t("GTMStep05")}</p>
                <img src="/assets/img/others/gtm/step-03.png" alt="step-03" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p>1- {t("GTMStep05-1")}</p>
                <p>2- {t("GTMStep05-2")} ({t("GTMStep05-3")})</p>
                
                <p className="mt-5">4. {t("GTMStep06")}</p>
                <img src="/assets/img/others/gtm/step-04.png" alt="step-04" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">5. {t("GTMStep07")}</p>
                <img src="/assets/img/others/gtm/step-05.png" alt="step-05" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />

                <h5 style={{ color: 'var(--main-color-one)' }} className="my-5">{t("GTMStep08")}</h5>
                <p>1. {t("GTMStep09")}</p>
                <p>2. {t("GTMStep10")}</p>
                <img src="/assets/img/others/gtm/step-06.png" alt="step-06" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">3. {t("GTMStep11")}</p>
                <img src="/assets/img/others/gtm/step-07.png" alt="step-07" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">{t("GTMStep12")}</p>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openAnalyticsStepsDialog}
            className="custom-dialog model"
            onClose={() => setOpenAnalyticsStepsDialog(false)}>
            <DialogTitle>
              <div className="dialog-head">
                <div>
                  <h2>{t("AnalyticsLinkingSteps")}</h2>
                </div>
                <div className="dialog-actions">
                  <span onClick={() => setOpenAnalyticsStepsDialog(false)} style={{ cursor: 'pointer' }}>
                    <Close />
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginTop: '15px', fontSize: '14px', maxWidth: '100%' }}>
                
                {/* <h5 style={{ color: 'var(--main-color-one)' }}>ما هو Google Analytics؟</h5>
                <p>تحليلات قوقل  Google Analytics: هي خدمة مجانية بشكل جزئي متوفرة من قبل شركة Google، وتقوم هذه الخدمة بتعقب زوار  موقعك الإلكتروني وتوليد تقارير حولها.</p>
              
                <h5 style={{ color: 'var(--main-color-one)' }}>ما هي أهم البيانات التي يمكنني التعريف عليها من خلال Google Analytics ؟</h5>
                <p>أهم ما يمكنك التعرف إليه من خلال هذه الأداة :</p>
                <ul>
                <li> عدد الأشخاص الذين زاروا موقعك الإلكتروني.</li>
                <li>عدد الأشخاص القادمين إلى موقعك الإلكتروني للمرة الأولى.</li>
                <li>عدد الأشخاص الذين يُعتبرون زوار دوريّين.</li>
                <li>الصفحات التي تتمتع بأعلى نسب دخول.</li>
                <li>الزمن الوسطي لبقاء الزائر.</li>
                <li>الكلمات الرئيسية التي تجتذب الزوار أكثر.</li>
                <li>عدد المستخدمين القادمين من صفحات البحث.</li>
                <li>عدد المستخدمين القادمين من الإعلانات.</li>
                </ul> */}

                <h5 style={{ color: 'var(--main-color-one)' }}>{t("AnalyticsStep01")}</h5>
                <p>{t("AnalyticsStep02-1")} <a href="https://analytics.google.com/analytics/web/provision/?authuser=0#/provision" style={{ textDecoration: 'underline', color: 'var(--main-color-one)' }}>Google Analytics</a> {t("AnalyticsStep02-2")}</p>
                <img src="/assets/img/others/analytics/step-01.png" alt="step-01" className="mb-5 d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">1. {t("AnalyticsStep03")}</p>
                <img src="/assets/img/others/analytics/step-02.png" alt="step-02" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">{t("AnalyticsStep04")}</p>
                <img src="/assets/img/others/analytics/step-03.png" alt="step-03" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">2. {t("AnalyticsStep05")}</p>
                <img src="/assets/img/others/analytics/step-04.png" alt="step-04" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">3. {t("AnalyticsStep06")}</p>
                <img src="/assets/img/others/analytics/step-05.png" alt="step-05" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">4. {t("AnalyticsStep07")}</p>
                <img src="/assets/img/others/analytics/step-06.png" alt="step-06" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">5. {t("AnalyticsStep08")}</p>
                <img src="/assets/img/others/analytics/step-07.png" alt="step-07" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">6. {t("AnalyticsStep09")}</p>
                <img src="/assets/img/others/analytics/step-08.png" alt="step-08" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">7. {t("AnalyticsStep10")}</p>
                <img src="/assets/img/others/analytics/step-09.png" alt="step-09" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">{t("AnalyticsStep11")}</p>
                <img src="/assets/img/others/analytics/step-10.png" alt="step-10" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p style={{ fontWeight: 600 }} className="my-3">{t("AnalyticsStep12")}</p>
                
                <h5 style={{ color: 'var(--main-color-one)' }} className="my-5">{t("AnalyticsStep13")}</h5>
                <p>1. {t("GTMStep09")}</p>
                <p>2. {t("AnalyticsStep14")}</p>
                <img src="/assets/img/others/analytics/step-11.png" alt="step-11" className="d-block" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">3. {t("AnalyticsStep15")}</p>
                <img src="/assets/img/others/analytics/step-12.png" alt="step-12" className="d-block mb-3" style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} />
                <p className="mt-5">{t("AnalyticsStep16")}</p>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
