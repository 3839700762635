import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppLayout from "../../../components/layouts/app-layout";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../helpers/context";
import {  DARK_TEMPLATES } from "../../../constants";
import PropertyLoader from "../../shared/properties/components/property-loader";
import Forbidden from "../../shared/403";
import RelatedProject from "../../shared/properties/components/related-project";
import { Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, useMediaQuery } from "@mui/material";
import { CircularCheck, GreenCheck, ListArrow, Marker, RedClose } from "../../../constants/icons";
import { Approval, BubbleChart, Close, EmojiTransportation, Filter3, LocalConvenienceStore, LocationCity, Map, Navigation, Signpost } from "@mui/icons-material";
import { createDownloadLink, getFontspace, getLocalizedText, renderFeatureIcon, renderLocationTitle, renderMeterIfNotExist, renderRoomIcon, resolveRerBorders } from "../../../helpers";
import GoogleMapsContainer from "../../../components/googleMapComponent.js";
import ImageModal from "../../../components/global-components/image-modal.js";
import { getPropertyDescription } from "../../../api/propertyApi.js";
import QRCode from "react-qr-code";
import VideoSnapshot from "video-snapshot";
import ReactImageGallery from "react-image-gallery";

const publicUrl = process.env.REACT_APP_URL + "/";

const VIPADDetails = (props) => {
  let { id } = useParams();

  const { UserId, templateId } = useAuth();
  const images = [];
  const isAdmin = window.location.href.includes("admin");
  let propertyId = id;
  const [_property, set_property] = useState(false);
  const [_msg, set_msg] = useState(false);
  const [_code, set_code] = useState(1);
  const { t, i18n } = useTranslation();
  const [expandedTheDesc, setExpandedTheDesc] = React.useState(false);
  const [ExploreNeighbourhood, setExploreNeighbourhood] = useState({
    hospital: false,
    mosque: false,
    school: false,
    shopping_mall: false,
    // bus_station: false,
    // train_station: false,
  });
  const [openVideoDialog, setOpenVideoDialog] = useState({
    visible: false,
    url: "",
  });
  const [selectedAttachment, setSelectedAttachment] = React.useState(undefined);
  const [imageModalOpened, setImageModalOpened] = React.useState(false);
  const [videosToShow, setVideosToShow] = React.useState([]);
  let _photos = [],
    FloorPlans = [],
    videos = [];
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const getData = () => {
    getPropertyDescription(getProps(propertyId, i18n.language === 'ar' ? '0' : '1')).then((res) => {
      set_property(res.response);
      // setPropertyLicenseNumber(
      //   res?.response?.additionalInfo?.filter((i) => i.key === "10")?.[0]
      //     ?.valueText
      // );
      set_msg(res.resStr);
      set_code(res.resCode);
    });
  };

  useEffect(() => {
    // let isnum = /^\d+$/.test(propertyId);
    // if (!isnum)
    getData();

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, i18n.language]);





  useEffect(() => {
    setExploreNeighbourhood({
      hospital: false,
      mosque: false,
      school: false,
      shopping_mall: false,
      // bus_station: false,
      // train_station: false,
    });
  }, [_property]);

  function getProps(propertyId, language) {
    if (UserId) {
      let userId = UserId;
      return {
        propertyId,
        language,
        userId,
      };
    } else
      return {
        propertyId,
        language,
      };
  }



  const renderVideo = (item) => {
    return (
      <video style={{ height: "93%", width: "100%", border: 0 }} controls>
        <source src={item.embedUrl} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    );
  };


  const renderVideos = async () => {
    let temp = [];
    for (let item of videos) {
      let response = await fetch(item.video_url);
      let data = await response.blob();
      let file = new File([data], "test.mp4", { type: "video/mp4" });
      const snapshoter = new VideoSnapshot(file);
      const previewSrc = await snapshoter.takeSnapshot(10);

      temp.push({
        embedUrl: item.video_url,
        original: previewSrc || `${publicUrl}assets/img/icons/mp4-icon.png`,
        thumbnail: previewSrc || `${publicUrl}assets/img/icons/mp4-icon.png`,
        renderItem: renderVideo.bind(this),
        thumbnailClass: "video",
      });
    }
    setVideosToShow(temp);
  };
  useEffect(
    () => {
      if (
        videos &&
        videos?.length > 0 &&
        images.length > 0 &&
        videosToShow.length === 0
      ) {
        renderVideos();
      }
    }, // eslint-disable-next-line
    [videos, images]
  );


  if (_property.videoUrl && _property.videoUrl?.length > 0)
    videos = _property.videoUrl;

  if (_property.photos) {
    _photos = _property.photosNew.split(",");
  } else {
    _photos.push(
      publicUrl + "assets/img/defimgs/" + _property.propertyType + (DARK_TEMPLATES.includes(+templateId) && !isAdmin ? '-dark' : '') + ".svg"
    );
  }
  if (_property.floorPlans) FloorPlans = _property.floorPlans.split(",");
  _photos.forEach((element) => {
    images.push({
      original:
        element.indexOf("#") > -1
          ? element.substring(0, element.indexOf("#"))
          : element,
      thumbnail:
        element.indexOf("#") > -1
          ? element.substring(0, element.indexOf("#"))
          : element,
      description:
        element.indexOf("#") > -1
          ? element.substring(element.indexOf("#") + 1)
          : "",
    });
  });

  let data = "";
  if (
    _property?.additionalInfo?.filter((i) => i.key === "10")?.[0]?.valueText
  ) {
    data += `${t("RealEstateADLicenseNumber")}: ${_property.additionalInfo.filter((i) => i.key === "10")?.[0]?.valueText
      }\n\n`;
  }
  if (_property.rooms?.length > 0) {
    _property.rooms?.map((item) => (data += `${item.name}: ${item.value}\n`));
  }
  if (_property.features?.length > 0) {
    _property.features?.map(
      (item) =>
      (data += `${item.name}: ${item.key === "7"
        ? getFontspace(item.value)
        : Intl.NumberFormat("en").format(item.value) + " " + item.unit
        }\n`)
    );
  }
  if (_property.amenities?.length > 0) {
    data += `\n\n${t("Features")}:\n`;
    _property.amenities?.map((item) => (data += `${item.name}\n`));
  }

  const [openMapModal, setOpenMapModal] = useState(false);
 


  return (
    <AppLayout pageTitle={t("ADDetails")} withoutNav={isAdmin} unsetOverflow>
      <div className="padding-bottom">
        {
          _code !== 1 ? (
            _code === 0 ? (
              <>
                <div className={`property-details-area no-padding admin vip`} key={_property.propertyId}>
                  <div className='w-100 property-details-slider-info position-relative'>
                    <div className="stacked-card">
                      <div
                        dir='ltr'
                        className='col-12 property-details-slider p-0'>
                        <ReactImageGallery
                          items={[...images]}
                          showPlayButton={false}
                          autoPlay
                          lazyLoad
                          showThumbnails={
                            [...images].length > 1
                          }
                        />
                      </div>
                                  
                      <div className="qr">
                        {_property.additionalInfo.filter(
                          (i) => i.key === "18"
                        )?.[0]?.valueText !== "لا" && (
                            <>
                              <div
                                style={{
                                  height: "auto",
                                  maxWidth: 75,
                                  width: "100%",
                                  border: "3px solid #e9e8ea",
                                  borderRadius: "12px",
                                  padding: "8px 5px 0",
                                }}>
                                {!_property.additionalInfo
                                  .filter((i) => i.key === "18")?.[0]
                                  ?.valueText?.includes("amakkn") ? (
                                  <a
                                    href={
                                      _property.additionalInfo.filter(
                                        (i) => i.key === "18"
                                      )?.[0]?.valueText
                                    }
                                    target='_blank'
                                    rel='noreferrer'>
                                    <QRCode
                                      size={256}
                                      style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                      value={
                                        _property.additionalInfo.filter(
                                          (i) => i.key === "18"
                                        )?.[0]?.valueText
                                      }
                                      viewBox={`0 0 256 256`}
                                    />
                                  </a>
                                ) : (
                                  <img
                                    src={
                                      _property.additionalInfo.filter(
                                        (i) => i.key === "18"
                                      )?.[0]?.valueText
                                    }
                                    style={{
                                      height: "auto",
                                      maxWidth: "100%",
                                      width: "100%",
                                    }}
                                    alt={t("QRCode")}
                                  />
                                )}
                              </div>
                                
                            </>
                          )}
                      </div>
                                  
                    </div>

                    <div className="stacked-card">
                      <div className="content property-inner-details row mx-0 justify-content-between">
                        <div className='col-12 col-lg-5'>
                          <h4 className="borderd-label">{_property.propertyTypeName} {_property.listedFor === "2" ? t("ForSale") : t("ForRent")}</h4>
                          <p>{_property.address}</p>
                          <p style={{ color: "var(--main-color-one)" }}> <span>
                            {Intl.NumberFormat("en").format(
                              _property.defaultPrice
                            )}{" "}
                            {t("SAR")}
                          </span>
                            {_property.listedFor === "1" && (
                              <span>
                                &nbsp;({_property.defaultPriceType.name})
                              </span>)}
                          </p>
                          {_property.description && (
                       
                            <p
                              className='text-more mt-5'
                              style={{ whiteSpace: "pre-line" }}>
                              {expandedTheDesc
                                ? getLocalizedText(_property.description, i18n.language, false)
                                : getLocalizedText(_property.description, i18n.language, false).length > 120
                                  ? getLocalizedText(_property.description, i18n.language, false).substr(0, 120) +
                                  "... "
                                  : getLocalizedText(_property.description, i18n.language, false)}
                              {getLocalizedText(_property.description, i18n.language, false).length > 120 && (
                                <button
                                  className="d-block text-center mt-2"
                                  onClick={() =>
                                    setExpandedTheDesc(!expandedTheDesc)
                                  }>
                                  {expandedTheDesc ? t("ShowLess") : t("ShowMore")}
                                </button>
                              )}
                            </p>
                     
                          )}
                        </div>
                        <div className={`col-12 ${_property.description ? 'col-lg-6' : ''}`}>
                          <h4 className="borderd-label">{t("PropertyDetails")}</h4>
                          <div className='property-info'>
                            <div className='row p-0 m-0'>
                              {_property.rooms.map((item) => (
                                <div
                                  className='col-sm-6 col-lg-4 box d-flex justify-content-center'
                                  key={item.key}>
                                  <div className='icon'>
                                    {renderRoomIcon(item.key)}
                                  </div>
                                  <div className='info'>
                                    <h5>{item.name}</h5>
                                    <span>
                                      {item.key === "11" || item.key === "26"
                                        ? item.value === "0"
                                          ? t("GroundFloor")
                                          : item.value
                                        : item.value}
                                    </span>
                                  </div>
                                </div>
                              ))}
                              {_property.features.map((item) => item.key !== '6' && (
                                <div
                                  className='col-sm-6 col-lg-4 box'
                                  key={item.key}>
                                  <div className='icon'>
                                    {renderFeatureIcon(item.key)}
                                  </div>
                                  <div className='info'>
                                    <h5>{item.name}</h5>
                                    <span>
                                      <i className='fa' />
                                      {item.key === "7"
                                        ? getFontspace(item.value)
                                        : Intl.NumberFormat("en").format(item.value) + " " + item.unit}
                                    </span>
                                  </div>
                                </div>
                              ))}
 
                              {_property?.propertyType !== '1' && _property?.additionalInfo?.filter(
                                (i) => i.key === "60"
                              )?.[0] && <div
                                className='col-sm-6 col-lg-4 box'
                                key={60}>
                                  <div className='icon'>
                                    {renderFeatureIcon('6')}
                                  </div>
                                  <div className='info'>
                                    <h5>{_property?.additionalInfo?.filter(
                                      (i) => i.key === "60"
                                    )?.[0]?.name}</h5>
                                    <span>
                                      {_property?.additionalInfo?.filter(
                                        (i) => i.key === "60"
                                      )?.[0]?.valueText}
                                    </span>
                                  </div>
                                </div>}
                                
                              <div className='col-sm-6 col-lg-4 box'>
                                <div className='icon'>
                                  <BubbleChart />
                                </div>
                                <div className='info'>
                                  <h5>
                                    {
                                      _property?.additionalInfo?.filter(
                                        (i) => i.key === "22"
                                      )?.[0]?.name
                                    }
                                  </h5>
                                  <span>
                                    {
                                      _property?.additionalInfo?.filter(
                                        (i) => i.key === "22"
                                      )?.[0]?.valueText
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    {_property.additionalInfo.filter((i) =>
                      ["38"].includes(i.key)
                    )?.length > 0 &&
                      _property.additionalInfo.filter((i) =>
                        ["38"].includes(i.key)
                      )?.[0]?.valueText !== "0#0#0#0#0#0#0#0#0#0#0#0" && (
                        <div className="stacked-card">
                          <div className="content property-inner-details">
                            <div className='col-12 additional-info '>
                              <h4 className="borderd-label">
                                {
                                  _property.additionalInfo.filter((i) =>
                                    ["38"].includes(i.key)
                                  )?.[0]?.name
                                }
                              </h4>
                              <div className='property-info'>
                                <div className='row p-0 m-0'>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <Navigation />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("NorthernBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[0] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[0] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[1] ===
                                          "North"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[1] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[2]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <Navigation
                                        sx={{ transform: "rotate(90deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("EasternBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[3] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[3] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[4] ===
                                          "East"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[4] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[5]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <Navigation
                                        sx={{ transform: "rotate(180deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("SouthernBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[9] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[9] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[10] ===
                                          "South"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[10] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[11]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='col-12 box'>
                                    <div className='icon blue'>
                                      <Navigation
                                        sx={{ transform: "rotate(-90deg)" }}
                                      />
                                    </div>
                                    <div className='info'>
                                      <span>
                                        <b>{t("WesternBorder")}: </b>
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[6] === "0"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[6] +
                                          " "}
                                        {_property.additionalInfo
                                          .filter((i) =>
                                            ["38"].includes(i.key)
                                          )?.[0]
                                          ?.valueText?.split("#")?.[7] ===
                                          "West"
                                          ? ""
                                          : _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[7] +
                                          " "}
                                        <b>{t("WithLength")} </b>
                                        {renderMeterIfNotExist(
                                          _property.additionalInfo
                                            .filter((i) =>
                                              ["38"].includes(i.key)
                                            )?.[0]
                                            ?.valueText?.split("#")?.[8]
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    <div className="stacked-card">
                      <div className="content property-inner-details row mx-0 justify-content-between">
                        {_property.amenities.length > 0 && (
                          <div className='col-12 col-lg-6 property-amenities '>
                            <h4 className="borderd-label">{t("Features")}</h4>
                            <div className='row p-0 mx-0'>
                              {_property.amenities.map((amItem) => (
                                <div
                                  className='col-sm-6 col-lg-4 property-amenity'
                                  key={amItem.key}>
                                  <span className='icon'>
                                    <CircularCheck />
                                  </span>{" "}
                                  {amItem.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {_property.additionalInfo.filter(
                          (i) =>
                            ["5", "6", "7", "50", "51", "52", '15'].includes(i.key) &&
                            i.valueBoolean === "1"
                        )?.length > 0 && (
                            <div className='col-12 col-lg-5 additional-info-v2 '>
                              <h4 className="borderd-label">{t("RealEstateServices")}</h4>
                              <div className='row p-0 m-0'>
                                {_property.additionalInfo
                                  .filter(
                                    (i) =>
                                      [
                                        "5",
                                        "6",
                                        "7",
                                        "50",
                                        "51",
                                        "52",
                                        "15",
                                      ].includes(i.key) && i.valueBoolean === "1"
                                  )
                                  .map((AIItem) => (
                                    <div
                                      className={`col-12 col-lg-6 mb-4 info`}
                                      key={AIItem.key}>
                                      <span>
                                        {AIItem.valueBoolean === "1" ? (
                                          <GreenCheck gold />
                                        ) : (
                                          <RedClose />
                                        )}
                                      </span>
                                      <span>{AIItem.name}</span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                   
                    {(FloorPlans.length > 0 || videosToShow?.length > 0) && (
                      <div className="stacked-card">
                        <div className="content property-inner-details row mx-0 justify-content-between">
                          {FloorPlans.length > 0 && (
                            <div className='col-12 col-lg-6'>
                              <h4 className="borderd-label">{t("Plans")}</h4>
                              <div className='attachments-wrapper'></div>
                              <ImageList cols={3} gap={15}>
                                {FloorPlans.map((item, i) => (
                                  <ImageListItem
                                    key={i}
                                    style={{
                                      border: "1px solid rgba(0, 0, 0, 0.23)",
                                      cursor: "pointer",
                                      borderRadius: 8,
                                      height: "160px",
                                    }}
                                    onClick={() => {
                                      if (item?.includes(".pdf")) {
                                        if (isLargeScreen)
                                          window.open(item, "_blank");
                                        else
                                        createDownloadLink("plan.pdf", item);
                                      } else {
                                        setSelectedAttachment(item);
                                        setImageModalOpened(true);
                                      }
                                    }}>
                                    {item?.includes(".pdf") ? (
                                      <img
                                        src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                                        alt={`attachment-${i}`}
                                        loading='lazy'
                                        style={{
                                          objectFit: "contain",
                                          height: "100%",
                                          padding: 10,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={`${item}`}
                                        srcSet={`${item}`}
                                        alt={`attachment-${i}`}
                                        loading='lazy'
                                        style={{
                                          objectFit: "cover",
                                          borderRadius: 8,
                                          height: "100%",
                                        }}
                                      />
                                    )}
                                  </ImageListItem>
                                ))}
                              </ImageList>
                              <ImageModal
                                src={selectedAttachment}
                                open={imageModalOpened}
                                handleClose={() => setImageModalOpened(false)}
                              />
                            </div>
                          )}

                          {videosToShow?.length > 0 && (
                            <>
                              <div className='col-12 col-lg-6'>
                                <h4 className="borderd-label">{t("Videos1")}</h4>
                                <div className='attachments-wrapper'></div>
                                <ImageList cols={3} gap={15}>
                                  {videosToShow.map((item, i) => (
                                    <ImageListItem
                                      key={i}
                                      style={{
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: 8,
                                        height: "160px",
                                      }}
                                    >
                                      <div
                                        className='youtube-icon'
                                        style={{
                                          width: '45px',
                                          height: '45px',
                                          position: 'absolute',
                                          top: '37%',
                                          left: '50%',
                                          transform: 'translate(-50%)'
                                        }}
                                        onClick={() =>
                                          setOpenVideoDialog({
                                            visible: true,
                                            url: item.embedUrl,
                                          })
                                        }>
                                        <svg
                                          width='31'
                                          height='32'
                                          viewBox='0 0 21 22'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'>
                                          <path
                                            d='M1.63861 10.764V6.70324C1.63861 1.66145 5.20893 -0.403178 9.57772 2.11772L13.1024 4.14812L16.6271 6.17853C20.9959 8.69942 20.9959 12.8287 16.6271 15.3496L13.1024 17.38L9.57772 19.4104C5.20893 21.9313 1.63861 19.8666 1.63861 14.8249V10.764Z'
                                            strokeWidth='1.5'
                                            strokeMiterlimit='10'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'></path>
                                        </svg>
                                  
                                      </div>
                                      <img
                                        src={item.thumbnail}
                                        alt={`attachment-${i}`}
                                        loading='lazy'
                                        style={{
                                          objectFit: "contain",
                                          height: "100%",
                                          padding: 10,
                                        }}
                                      />
                                    </ImageListItem>
                                  ))}
                                </ImageList>
                              </div>
                              <Dialog
                                onClose={() =>
                                  setOpenVideoDialog({ visible: false, url: "" })
                                }
                                maxWidth={"lg"}
                                style={{ zIndex: 9999999 }}
                                sx={{
                                  "& .MuiPaper-root": {
                                    margin: 0,
                                    padding: 0,
                                    zIndex: 9999999,
                                    width: "calc(100vw - 10%)",
                                    borderRadius: "12px",
                                    border: 0,
                                  },
                                }}
                                open={openVideoDialog.visible}>
                                <DialogContent
                                  sx={{
                                    padding: 0,
                                    background: "var(--main-color-one)",
                                    borderRadius: "12px",
                                    border: 0,
                                  }}>
                                  <video
                                    style={{ height: "500px", width: "100%", border: 0 }}
                                    controls>
                                    <source src={openVideoDialog.url} type='video/mp4' />
                                    Your browser does not support the video tag.
                                  </video>
                                </DialogContent>
                              </Dialog>
                            </>
                          )}
                          
                        </div>
                      </div>
                    )}
                     

                    <div className="stacked-card">
                      <div className="content property-inner-details">
                        <div className='col-12 p-0 mt-0 property-location '>
                          <h4 className="borderd-label">{t("PropertyAddress")}</h4>
                        
                          <Dialog
                            fullWidth={true}
                            maxWidth={"xl"}
                            open={openMapModal}
                            className='custom-dialog model full-screen'
                            onClose={() => {
                              setOpenMapModal(false);
                              setExploreNeighbourhood({
                                hospital: false,
                                mosque: false,
                                school: false,
                                shopping_mall: false,
                                // bus_station: false,
                                // train_station: false
                              });
                            }}>
                            <DialogTitle>
                              <div className='dialog-head contact-info p-0'>
                                <h4 className='mb-0'>{t("PlacesSurroundingTheProperty")}</h4>
                                <div className='dialog-actions'>
                                  <IconButton
                                    aria-label='close'
                                    onClick={() => {
                                      setOpenMapModal(false);
                                      setExploreNeighbourhood({
                                        hospital: false,
                                        mosque: false,
                                        school: false,
                                        shopping_mall: false,
                                        // bus_station: false,
                                        // train_station: false
                                      });
                                    }}
                                    sx={{
                                      color: (theme) => theme.palette.grey[500],
                                    }}>
                                    <Close />
                                  </IconButton>
                                </div>
                              </div>
                            </DialogTitle>
                            <DialogContent>
                              <div className='btns-wrapper map-btns mx-0'>
                                <button
                                  className={`${ExploreNeighbourhood.hospital
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      mosque: false,
                                      school: false,
                                      shopping_mall: false,
                                      hospital: !ExploreNeighbourhood.hospital,
                                    });
                                  }}>
                                  {t("Hospitals")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.mosque
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      hospital: false,
                                      school: false,
                                      shopping_mall: false,
                                      mosque: !ExploreNeighbourhood.mosque,
                                    });
                                  }}>
                                  {t("Mosques")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.school
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      hospital: false,
                                      mosque: false,
                                      shopping_mall: false,
                                      school: !ExploreNeighbourhood.school,
                                    });
                                  }}>
                                  {t("Schools")}
                                </button>
                                <button
                                  className={`${ExploreNeighbourhood.shopping_mall
                                    ? "primary-btn"
                                    : "outlined-btn"
                                    } px-3 py-2`}
                                  onClick={(e) => {
                                    setExploreNeighbourhood({
                                      hospital: false,
                                      mosque: false,
                                      school: false,
                                      shopping_mall:
                                        !ExploreNeighbourhood.shopping_mall,
                                    });
                                  }}>
                                  {t("Markets")}
                                </button>
                          
                              </div>
                              <div
                                className='row custom-map'
                                style={{
                                  height: "calc(100% - 90px)",
                                  margin: "0px",
                                  position: "relative",
                                }}>
                                {_property.latitude && (
                                  <GoogleMapsContainer
                                    lat={_property.latitude}
                                    lng={_property.longitude}
                                    ExploreNeighbourhood={ExploreNeighbourhood}
                                    dialog
                                  />
                                  //  <MapForView
                                  //   lat={_property.latitude}
                                  //   dialog
                                  //   lng={_property.longitude}
                                  //   ExploreNeighbourhood={ExploreNeighbourhood}
                                  //   zoom={15}
                                  // />
                                )}
                              </div>
                            </DialogContent>
                          </Dialog>
                          <div
                            className='btns-wrapper map-btns d-md-flex d-none'
                            style={{ padding: "0 0 20px 0" }}>
                            <button
                              className={`${ExploreNeighbourhood.hospital
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  mosque: false,
                                  school: false,
                                  shopping_mall: false,
                                  hospital: !ExploreNeighbourhood.hospital,
                                });
                              }}>
                              {t("Hospitals")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.mosque
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  school: false,
                                  shopping_mall: false,
                                  mosque: !ExploreNeighbourhood.mosque,
                                });
                              }}>
                              {t("Mosques")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.school
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  mosque: false,
                                  shopping_mall: false,
                                  school: !ExploreNeighbourhood.school,
                                });
                              }}>
                              {t("Schools")}
                            </button>
                            <button
                              className={`${ExploreNeighbourhood.shopping_mall
                                ? "primary-btn"
                                : "outlined-btn"
                                } px-3 py-2`}
                              onClick={(e) => {
                                setExploreNeighbourhood({
                                  // ...ExploreNeighbourhood,
                                  hospital: false,
                                  mosque: false,
                                  school: false,
                                  shopping_mall:
                                    !ExploreNeighbourhood.shopping_mall,
                                });
                              }}>
                              {t("Markets")}
                            </button>
                                  
                                 
                          </div>
                          <div className='row'>
                            <div
                              className='col-12 col-lg-6'
                              style={{
                                height: "400px",
                                margin: "0px",
                                position: "relative",
                              }}>
                              <div className='map-container with-radius'>
                                {_property.latitude && (
                                  <GoogleMapsContainer
                                    lat={_property.latitude}
                                    lng={_property.longitude}
                                    ExploreNeighbourhood={ExploreNeighbourhood}
                                  />
                                  //   <MapForView
                                  //   lat={_property.latitude}
                                  //   lng={_property.longitude}
                                  //   ExploreNeighbourhood={ExploreNeighbourhood}
                                  //   zoom={15}
                                  // />
                                )}
                                <button
                                  className='primary-btn px-3 py-2 d-flex d-md-none'
                                  onClick={() => setOpenMapModal(true)}>
                                  {t("ExploreTheNeighborhood")}
                                </button>
                              </div>
                            </div>
                            <div className='col-12 col-lg-6 row mx-0  align-items-center property-info'>
                              {_property.fullAddress
                                .split(",")
                                .map((item, index) => (
                                  <div
                                    className='col-6 col-lg-4 box px-lg-2'
                                    key={index}>
                                    <div className='icon'>
                                      {index === 0 ? (
                                        <Map />
                                      ) : index === 1 ? (
                                        <LocationCity />
                                      ) : index === 2 ? (
                                        <EmojiTransportation />
                                      ) : index === 3 ? (
                                        <Marker sx={{ color: "#6c6544" }} />
                                      ) : index === 4 ? (
                                        <Approval />
                                      ) : index === 6 ? (
                                        <Filter3 />
                                      ) : (
                                        <LocalConvenienceStore />
                                      )}
                                    </div>
                                    <div className='info'>
                                      <h5>{renderLocationTitle(index)}</h5>
                                      <span style={{ fontSize: '13px' }}>
                                        {item?.trim() || t("NotAvailable")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              {_property.additionalInfo
                                .filter((i) => ["12", "33"].includes(i.key))
                                .map((AIItem) => (
                                  <div
                                    className='col-6 col-lg-4 box px-lg-2'
                                    key={AIItem.key}>
                                    <div className='icon'>
                                      <Signpost />
                                    </div>
                                    <div className='info'>
                                      <h5>{AIItem.name}</h5>
                                      <span style={{ fontSize: '13px' }}>
                                        {AIItem.valueText === "0"
                                          ? t("No")
                                          : AIItem.valueText === "1"
                                            ? t("Yes")
                                            : AIItem.valueText}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                  
                      </div>
                    </div>

                    <div className="stacked-card">
                      <div className="content property-inner-details row mx-0 justify-content-between">
                        <div className={`col-12 ${_property.priceRent.length > 0 || (_property.additionalInfo.filter((i) => ["56"].includes(i.key))?.length > 0 &&
                          _property.additionalInfo.filter((i) => ["56"].includes(i.key))?.[0]?.valueText !== "لا") ? 'col-lg-4' : ''} additional-info`}>
                          <h4 className="borderd-label">{t("ObligationsOnTheProperty")}</h4>
                          <div className='row m-0'>
                            {_property.additionalInfo
                              .filter((i) =>
                                [
                                  "1",
                                  "11",
                                  "13",
                                  "14",
                                  "19",
                                  "2",
                                  "20",
                                  "3",
                                  "30",
                                  "31",
                                  "32",
                                  "35",
                                  "37",
                                  "54",
                                  "55",
                                  "16",
                                  "17",
                                  "53",
                                ].includes(i.key)
                              )
                              .map((AIItem) => (
                                <div
                                  className='col-12 mb-4 info p-0'
                                  key={AIItem.key}>
                                  <div>
                                    <ListArrow
                                      fill={
                                        DARK_TEMPLATES.includes(+templateId)
                                          ? "var(--heading-color)"
                                          : "black"
                                      }
                                      style={i18n.language === "en" ? { transform: 'rotate(180deg)' } : {}}
                                    />
                                  </div>
                                  <div
                                    className='question'
                                    style={{ maxWidth: "75%" }}>
                                    <h5>{AIItem.name}</h5>
                                    <p style={{ whiteSpace: "pre-line" }}>
                                      {AIItem.valueText === "0"
                                        ? t("No")
                                        : AIItem.valueText === "1"
                                          ? t("Yes")
                                          : AIItem.valueText}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="row mx-0 col-lg-7">
                          {_property.additionalInfo.filter((i) =>
                            ["56"].includes(i.key)
                          )?.length > 0 &&
                            _property.additionalInfo.filter((i) =>
                              ["56"].includes(i.key)
                            )?.[0]?.valueText !== "لا" && (
                    
                              <div className='col-12'>
                                <h4 className="borderd-label">
                                  {
                                    _property.additionalInfo.filter((i) =>
                                      ["56"].includes(i.key)
                                    )?.[0]?.name
                                  }
                                </h4>
                                <div className='property-info'>
                                  <div className='row p-0 m-0'>
                                    {_property.additionalInfo
                                      .filter((i) => ["56"].includes(i.key))?.[0]
                                      ?.valueText?.split("-")
                                      ?.map((i, index) =>
                                        resolveRerBorders(i, index, true)
                                      )}
                                  </div>
                                </div>
                              </div>
                         
                            )}
                          
                          {_property.priceRent.length > 0 && (
                            <div className='col-12'>
                              <h4 className="borderd-label">{t("RentalOptions")}</h4>
                              <div className='row property-rent-options p-0 m-0'>
                                {_property.priceRent.map((item, i) => (
                                  <div key={i} className='col-12'>
                                    <p>
                                      {" "}
                                      <span>
                                        {Intl.NumberFormat("en").format(
                                          item.value
                                        )}{" "}
                                        {t("SAR")}
                                      </span>
                                      &nbsp;<span>{item.name}</span>{" "}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                     
                        
                      </div>
                    </div>
                  
                    {_property?.relatedProjectId && _property?.relatedProjectId !== "None" && (

                      <div className="stacked-card">
                        <div className="content">
                          <div className='row second-row mx-0 property-inner-details'>
                            <div className='col-12 p-0'>
                              <RelatedProject
                                vip
                                id={_property?.propertyId}
                                projectId={_property?.relatedProjectId}
                                isMap={false}
                                onItemSelected={props.onItemSelected}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {isAdmin ? (
                  <div className='col-12 p-0 m-0'>
                    <Link
                      className='mb-4 primary-btn py-2 px-3 '
                      style={{ width: "fit-content", marginRight: "-10px" }}
                      to={isUnlicensedAD ? '/admin/unlicensed-ads' : '/admin/property/my-listings'}>
                      {t("BackToRealEstateADsPage")}
                    </Link>
                  </div>
                ) : (
                  <></>
                )} */}
                          
                
                  </div>
                </div>
              </>
            ) : (
              _msg && (
                <Forbidden btnLink={"/"} btnText={t("BackToHomePage")} desc={_msg} />
              )
            )
          ) : <PropertyLoader isMap={false} />}
      </div>
    </AppLayout>
  );
}

export default VIPADDetails;
