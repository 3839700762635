import React, { useEffect, useState } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";
import { Save } from "@mui/icons-material";
import { getWLProfile, saveWLProfile } from "../../../api/userApi";
import { toast } from "react-toastify";

const SettingsPage = () => {
    const { setAutoTranslate, vendor, token } = useAuth();
    const { t, i18n } = useTranslation();
    const [autoTranslateSetting, setAutoTranslateSetting] = useState();
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const [WlUser, setWlUser] = useState(undefined);

    const getData = () => {
        getWLProfile({
            userName: vendor,
            language: i18n.language === 'ar' ? '0' : '1',
        }).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
                setWlUser(_userInfo.response.wlUser);
                setAutoTranslateSetting(_userInfo.response.wlUser?.autoTranslate)
            }
        });
    }

    useEffect(() => {
        getData();
    }, // eslint-disable-next-line
        []);

    const handleSave = () => {
        setIsSubmittingData(true);
        saveWLProfile({
            ...WlUser,
            userId: token,
            autoTranslate: autoTranslateSetting,
            language: i18n.language === 'ar' ? '0' : '1',
        }).then((resp) => {
            if (resp.resCode === 0) {
                toast.success(t("DoneSuccessfully"));
                setAutoTranslate()
            } else {
                toast.error(resp.resStr);
            }
        }).finally(() => setIsSubmittingData(false));

    }
    
    return (
        <>
            <SubHeader OKElement={<></>} />
            <div className='custom-card with-switcher'>
                <div className="card-header flex-column flex-sm-row">
                    <div>
                        <h4>{t("Settings")}</h4>
                        <h6>{t("SettingsDesc")}</h6>
                    </div>
                    <div className={""}>
                        <LoadingButton
                            classes={`primary-btn px-3`}
                            OKdisabled={WlUser?.autoTranslate === autoTranslateSetting}
                            handleClick={handleSave}
                            loading={isSubmittingData}
                            icon={<Save />}
                            label={t("Save")}
                        />
                            
                    </div>
                </div>
               
                <div className="card-body">
                    <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                        <div className='col'>
                            <Switch
                                checked={autoTranslateSetting === "1"}
                                onChange={(event) => {
                                    let value = event.target.checked;
                                    setAutoTranslateSetting(value ? "1" : "0")
                                }}
                            />
                            <span style={{ textAlign: "center", textAlignLast: "center" }}>
                                {t("AutoTranslate")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingsPage;
