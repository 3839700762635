import React, { useState } from "react";
import OTPForm from "./OTPForm";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";

// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../../Config/Firebase";

import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../helpers/context";
import AppLayout from "../../layouts/app-layout";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const OTP = () => {
  const [errors, setErrors] = useState({});
  const { token } = useAuth();
  const history = useNavigate();
  let { nationalId } = useParams();
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState({
    id: null,
    userIdHashed: token,
    nationalId: nationalId,
    otp: "",
    language: i18n.language === "ar" ? "0" : "1",
  });
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(
    () => {
      if (user.code.length === 6) handleSubmit();
    },
    // eslint-disable-next-line
    [user]
  );

  function handleChange(value) {
    setUser({
      ...user,
      code: value,
    });
  }

  useEffect(
    () => {
      if (Object.keys(errors).length > 0) {
        formIsValid();
      }
    }, // eslint-disable-next-line
    [i18n.language]
  );

  function formIsValid() {
    const _errors = {};

    if (!user.otp)
      _errors.otp = t("EnterTheCodeSentToYourPhoneRegisteredInAbsher");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event?.preventDefault();

    if (!formIsValid()) return;
    setLoadingButton(true);

    userApi
      .verifyBrokerOTP(user)
      .then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          // logEvent(analytics, "broker_verification", { platform: "website" });
          history("/successverfication");
          if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
        } else {
          const _errors = {};
          _errors.otp = _userInfo.resStr;
          setErrors(_errors);
          setLoadingButton(false);
        }
      })
      .catch((e) => setLoadingButton(false));
  }

  return (
    <AppLayout pageTitle={t("ConfirmActivationCode")}>
      <div className='page-wrapper page-width'>
        <div className='row page-wrapper-without-top'>
          <div className='col-12 col-lg-6 details-wrapper scrolled'>
            <OTPForm
              errors={errors}
              user={user}
              name='otp'
              label={t("ActivationCode")}
              value={user.otp}
              errorName={errors.otp}
              onChange={handleChange}
              onSubmit={handleSubmit}
              loading={loadingButton}
            />
          </div>
          <div
            className='col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0'
            style={{
              background: `url(${publicUrl}assets/img/pattern.png) repeat`,
            }}>
            <div className='img-container'>
              <img
                src={publicUrl + "assets/img/others/others/2.png"}
                alt='whitelabel'
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default OTP;
