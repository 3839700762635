import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination } from "swiper";
import { useAuth } from '../../../../helpers/context';
import { getFontspace, getLocalizedText, renderFeatureIcon, renderFeatureIconCondition, renderRoomIcon, renderRoomIconCondition } from '../../../../helpers';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router-dom';
import VerifyPopup from '../../../../components/user-components/verify-popup';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import { useTranslation } from 'react-i18next';
import { Check } from '../../../../constants/icons';
import { SettingsBackupRestore } from '@mui/icons-material';

const publicUrl = process.env.REACT_APP_URL + "/";

export default function PropertyCardForAdmin({vip, unlicensed, residentialUnit, multipleSelect, selectedProperties, setSelectedProperties, onOK, customSelect, selectedItem, setSelectedItem, disableLink, hideActions, onSelect, onUnSelect, isSelected, loadingPublishButton, isUserVerified, item, handlePublishAndUnPublish, setOpenPopper, setAnchorEl, openPopper, anchorEl, setSelectedProperty }) {
    const { UserType, UserId } = useAuth();
    const propertyLicenseNumber = item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText;
    const history = useNavigate();
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const isAdmin = window.location.href.includes('admin');
    const [selected, setSelected] = useState(isSelected ? isSelected : false);
    const { t, i18n } = useTranslation();
    const [timeStamp, setTimeStamp] = useState();

    function handleSelectedChange() {
        if (selected) onUnSelect(item.propertyId);
        else onSelect(item.propertyId);
        setSelected(!selected);
    }
    
    const renderInfoList = (item) => {
        return (
            <ul className='info-list'>
                {item.features.map((feature, i) => renderFeatureIconCondition(item.propertyType, feature.key)
                    && (
                        <li key={"li" + i} title={feature.name}>

                            <span className="feature-value">
                                {feature.key !== '7'
                                    ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                    : getFontspace(feature.value)}
                            </span>
                            {renderFeatureIcon(feature.key)}
                        </li>
                    )
                )}
                {item.rooms.map((room, i) => renderRoomIconCondition(item.propertyType, room.key)
                    && (
                        <li key={"li-" + i} title={room.name}>
                            <span className="feature-value">
                                {room.value}
                            </span>
                            {renderRoomIcon(room.key)}
                        </li>
                    )
                )}
            </ul>
        );
    }
    
    const renderPrice = (item) => {
        return (
            propertyLicenseNumber && propertyLicenseNumber !== 'لا' ? (<h5 className='price' style={{ paddingTop: '10px', borderTop: '1px solid #eee' }}>
                {item.listedFor === "2" ? (
                    <span>
                        {Intl.NumberFormat("en").format(item.defaultPrice)} {t('SAR')}
                    </span>
                ) : (
                    <span>
                        {Intl.NumberFormat("en").format(item.defaultPrice) +
                            ` ${t("SAR")} / ` +
                            item.defaultPriceType.name}
                    </span>
                )}
            </h5>) :
                (<h4 className='price'>
                    <span style={{ fontSize: '13px', color: '#d00404' }}>{t("WaitingForTheADLicenseNumber")}</span>
                </h4>)
        );
    }

    const image = (
        <div
            className='thumb position-relative'
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
                if (!disableLink && e.target.tagName !== 'A' && e.target.tagName !== 'svg' && e.target.tagName !== 'path' && e.target.tagName !== 'SPAN')
                    history(`${vip ? "/admin/vip-ads" : isAdmin ? unlicensed || propertyLicenseNumber === "1" ? "/admin/unlicensed-ads/details" : "/admin/property-details" : "/property-details"}/${unlicensed ? item.propertyId : item.idToShare}`)
            }}>
            {item?.photos?.split(",")?.[0] ?
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    key={i18n.language + 'ii'}
                    modules={[Keyboard, Pagination]}
                    keyboard={{ enabled: true }}
                    pagination={{
                        clickable: true, el: `.swiper-pagination.pagination-${item.propertyId}`,
                        dynamicBullets: true,
                        dynamicMainBullets: 4
                    }}
                    speed={2600}>
                    {item.photos.split(",").map((image, index) => (
                        <SwiperSlide key={index}>
                            <img style={{ objectFit: 'cover' }} src={image} alt={`${item.propertyTypeName} ${item.listedFor === "1" ? t("ForRent") : t("ForSale")} ${index}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <LazyLoadImage
                    src={`${publicUrl}assets/img/defimgs/${item.propertyType}.svg`}
                    className={item.photos.split(",")[0] ? '' : 'default-img'}
                    alt=''
                />}
            {item?.photos?.split(",")?.[0] ? <div className={`swiper-pagination pagination-${item.propertyId}`}></div> : null}
    
        </div>
    );
    
    function isValid() {
        let k = false;
        // if (item.tracker.isDescriptionComplete === "1")
        // if (item.tracker.isRoomsComplete === "1")
        // if (item.tracker.isFeaturesComplete === "1")
        // if (item.tracker.isContactComplete === "1")
        if (item.tracker.isPriceComplete === "1") k = true;
        return k;
    }
    
    const content = (
        <div className="position-relative details" style={{ cursor: 'pointer' }} onClick={(e) => {
            if (!disableLink && e.target.tagName !== 'A' && e.target.tagName !== 'svg' && e.target.tagName !== 'path' && e.target.tagName !== 'BUTTON')
                history(`${vip ? "/admin/vip-ads" :isAdmin ? unlicensed || propertyLicenseNumber === "1" ? "/admin/unlicensed-ads/details":"/admin/property-details" : "/property-details"}/${unlicensed ? item.propertyId :item.idToShare}`)
        }}>
            {item.isFeatured === "1" && <div className={`featured-ribbon edit`}><span>{t("FeaturedAD")}</span></div>}
            
            <div className='d-flex align-items-center justify-content-between'>
                <Typography variant="h6" color="text.primary" className='admin-card-title' style={{ fontSize: '18px' }}>
                    {item.propertyTypeName}{" "}
                    {item.listedFor === "1" ? t("ForRent") : t("ForSale")}
                </Typography>
                {!hideActions && !residentialUnit && (
                    <div className='d-flex' style={{ gap: '5px' }}>
                        {item.status !== '2' && (UserType !== "5" || (UserType === "5" && item.userId === UserId)) &&
                            <Tooltip title={t("Edit")}>
                                <IconButton onClick={() => history(`${isAdmin ? unlicensed ? '/admin/unlicensed-ads/edit/':'/admin/property/edit-property/' : '/edit-property/'}${item.propertyId}`)}>
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        }

                        {item.status !== '2' && (UserType !== "5" || (UserType === "5" && item.userId === UserId)) && (
                            isValid() && (item.status === '0' ? (
                                <VerifyPopup
                                    openVerifyModal={openVerifyModal}
                                    setOpenVerifyModal={setOpenVerifyModal}
                                    desc={t("YouMustVerifyYourAccountInOrderToContinue")}
                                    trigger={
                                        <Tooltip title={t("Publish")}>
                                            <IconButton
                                                disabled={loadingPublishButton}
                                                onClick={isUserVerified !== '3' ? () => setOpenVerifyModal(true) : () => handlePublishAndUnPublish(item)}>
                                                <PublicIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            ) : (
                                <Tooltip title={t("Unpublish")}>
                                    <IconButton
                                        disabled={loadingPublishButton}
                                        onClick={() =>
                                            handlePublishAndUnPublish(item)
                                        }>
                                        <PublicOffIcon />
                                    </IconButton>
                                </Tooltip>
                            ))
                        )}
                    </div>
                )}
                {vip && (
                    <div className='d-flex' style={{ gap: '5px' }}>
                        <Tooltip title={t("Dediscriminate")}>
                            <IconButton
                                disabled={loadingPublishButton}
                                onClick={() =>
                                    handlePublishAndUnPublish(item)
                                }>
                                <SettingsBackupRestore />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
               
            </div>
                
            <Typography variant="caption" color="text.secondary" className='mb-3 admin-card-desc one-line'>{item.address}</Typography>
            {renderInfoList(item)}
            {!unlicensed && propertyLicenseNumber !== "1" &&
                <Typography variant="caption" color="text.secondary">
                    {t("RealEstateADLicenseNumber")}: &nbsp;
                    {propertyLicenseNumber}
                </Typography>
            }
           
            {item.relatedProjectName !== "None" && item.relatedProjectName !== "" && !hideActions && !unlicensed ?
                <Typography variant="caption" sx={{ display: 'block' }} color="text.secondary">
                    {t("LinkedWith")}: &nbsp;
                    {getLocalizedText(item.relatedProjectName, i18n.language, false)}
                </Typography> : <Typography variant="caption" sx={{ display: 'block' }} color="text.secondary">&nbsp;</Typography>
                }

            {!unlicensed &&
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', marginBottom: '5px' }}>
                    {item.hostInfo.userType === "5" ? <>{t("Agent")}:&nbsp;{item.hostInfo.name}</> : <>&nbsp;</>}
                </Typography>}
            {renderPrice(item)}
        </div>
    );
       
    return (
        <Card className='p-0 position-relative radius-16 property-card' onClick={() => {
            if (customSelect) {
                setSelectedItem?.(+item.propertyId);
                onOK?.();
            }
            if (multipleSelect) {
                let temp = selectedProperties;
                if (temp?.includes(+item.propertyId)) {
                    temp = temp.filter(i => i !== +item.propertyId);
                } else {
                    temp = [...temp, +item.propertyId];
                }
                setSelectedProperties?.(temp);
                setTimeStamp(timeStamp + new Date().getTime());
            }
        }}>
            <CardHeader
                action={!hideActions ?
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <IconButton
                            onClick={(e) => {
                                setOpenPopper(!openPopper);
                                setAnchorEl(anchorEl ? null : e.target);
                                setSelectedProperty(item);
                            }}
                            sx={{
                                background: '#fff',
                                zIndex: 99,
                                color: 'var(--main-color-one)',
                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                '&:hover,&:focus': {
                                    color: '#fff',
                                    background: 'var(--main-color-one)'
                                }
                            }}>
                            <MoreVertIcon />
                        </IconButton>
                        {UserType !== "5" && !residentialUnit && (
                            <div className='property-type left'>
                                <FormControlLabel
                                    control={<Checkbox checked={selected} onChange={handleSelectedChange} />}
                                    label={t("Check")}
                                />
                        
                            </div>
                        )}
                    </div> : null
                }
                sx={{ position: 'absolute', top: 0 }}
            />
            {image}
        
            <CardContent sx={{ minHeight: unlicensed?"180px":'230px', borderTop: '1px solid #ddd', paddingBottom: '1px', '&:last-child': { paddingBottom: '1px' } }}>
                {content}
            </CardContent>
            {customSelect && +item.propertyId === selectedItem && (
                <div className="selected-property-card">
                    <Check />
                </div>
            )}
            {multipleSelect && selectedProperties?.includes(+item.propertyId) && (
                <div className="selected-property-card">
                    <Check />
                </div>
            )}
        </Card>
    );
}
