import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import UploadImage from "../global-components/upload-image";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import YoutubeDialog from "../youtube-dialog";
import { useTranslation } from "react-i18next";
import T04Banner from "../pro/template-04/banner";
import T05Banner from "../pro/template-05/banner";
import T06Banner from "../pro/template-06/banner";
import { translateText } from "../../api/userApi";

const BannerV2 = (props) => {
  const [Data, setData] = useState({
    ...props.data,
    oldTitle1: props.data.title1,
    oldTitle2: props.data.title2
  });
  const { token, vendor, logo, templateId, autoTranslate } = useAuth();
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
  const [ImageTOView, setImageTOView] = useState(publicUrl + "assets/img/banner/1.jpg?" + Math.random());
  const youtubeLink = props.youtubeLink;
  const { t, i18n } = useTranslation();
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  useEffect(() => {
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }

    const homeStorage = localStorage.getItem(`home-${vendor}`);
    if (homeStorage) {
      setImageTOView(homeStorage || publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    }

  },// eslint-disable-next-line
    [vendor]);



  function changeImage(img) {
    setImageTOView(img ? img : publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    handleSave();
  }

  async function handleSave() {
    setIsSubmittingData(true);
    let oldArabicTitle1 = getLocalizedText(Data.oldTitle1, "ar"),
      newArabicTitle1 = getLocalizedText(Data.title1, "ar"),
      newTitle1 = Data.title1,
      oldArabicTitle2 = getLocalizedText(Data.oldTitle2, "ar"),
      newArabicTitle2 = getLocalizedText(Data.title2, "ar"),
      newTitle2 = Data.title2;

    if (i18n.language === "ar" && autoTranslate === "1") {
      if (oldArabicTitle1 !== newArabicTitle1 && newArabicTitle1?.trim() !== "") {
        let newEnglishTitle1 = await translateText(newArabicTitle1);
        newTitle1 = newArabicTitle1 + '~@~' + newEnglishTitle1;
      }
      if (oldArabicTitle2 !== newArabicTitle2 && newArabicTitle2?.trim() !== "") {
        let newEnglishTitle2 = await translateText(newArabicTitle2);
        newTitle2 = newArabicTitle2 + '~@~' + newEnglishTitle2;
      }
      setData({ ...Data, title1: newTitle1, title2: newTitle2 });
      props.saveDetails({ data: { ...Data, title1: newTitle1, title2: newTitle2 } });
    } else {
      props.saveDetails({ data: Data });
    }
    setIsSubmittingData(false);
  }

  const inlineStyle = {
    background: "url(" + ImageTOView + ") no-repeat center",
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  };
  return (
    <>
      {CLASSIC_TEMPLATES.includes(+templateId) && !props.isEdit ? (
        <T04Banner data={Data} ImageTOView={ImageTOView} youtubeLink={youtubeLink} />
      ) : CLASSIC2_TEMPLATES.includes(+templateId) && !props.isEdit ? (
        <T05Banner data={Data} ImageTOView={ImageTOView} youtubeLink={youtubeLink} />
      ) : MODERN2_TEMPLATES.includes(+templateId) && !props.isEdit ? (
        <T06Banner data={Data} ImageTOView={ImageTOView} youtubeLink={youtubeLink} />
      ) : (
        <div className={`banner-area2 banner-area ${MODERN_TEMPLATES.includes(+templateId) ? 'template-3__banner' : ''} ${props.isEdit ? 'admin' : ''}`} style={inlineStyle}>
          {props.isEdit && (
            <UploadImage
              className="upload-button"
              token={token}
              imgUrl='/assets/img/banner/1.jpg'
              imgSource='assets/img/banner/'
              section="home"
              goNext={(e) => changeImage(e.base64Data)}
            />
          )}
          <div className='container'>
            <div
              className='banner-inner-wrap'
              style={{
                placeContent: { 0: "start", 1: "center", 2: "end" }[Data.postion],
              }}>
              <div className='row'>
                <div className='col-12'>
                  <div className='banner-inner'>
                    {props.isEdit ? (
                      <div className='editSection'>
                        {![...CLASSIC_TEMPLATES, CLASSIC2_TEMPLATES, MODERN2_TEMPLATES].includes(+templateId) && (
                          <div className='title mb-3 text-align-last-center text-center'>
                            <Button onClick={(e) => setData({ ...Data, postion: "0" })}>{t("Left")}</Button>
                            <Button onClick={(e) => setData({ ...Data, postion: "1" })}>{t("Center")}</Button>
                            <Button onClick={(e) => setData({ ...Data, postion: "2" })}>{t("Right")}</Button>
                          </div>
                        )}
                        <div
                          className={
                            {
                              0: "sub-title text-align-last-left",
                              1: "sub-title text-align-last-center",
                              2: "sub-title text-align-last-right",
                            }[Data.postion]
                          }>
                          <TextField
                            label={t("Headline")}
                            fullWidth={true}
                            variant='outlined'
                            value={getLocalizedText(Data.title2, i18n.language)}
                            InputLabelProps={{ shrink: true }}
                            onChange={(v) => {
                              let arabicPart = getLocalizedText(Data.title2, "ar");
                              let englishPart = getLocalizedText(Data.title2, "en");
                              if (i18n.language === "ar")
                                arabicPart = v.currentTarget.value;
                              else
                                englishPart = v.currentTarget.value;
                              let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                              setData({ ...Data, title2: resolvePersianAndArabicNumbers(text) });
                            }}
                          />
                        </div>
                        <div
                          className={
                            {
                              0: " text-align-last-left",
                              1: " text-align-last-center",
                              2: " text-align-last-right",
                            }[Data.postion]
                          }>
                          <TextField
                            label={t("Subtitle")}
                            variant='outlined'
                            fullWidth={true}
                            multiline
                            InputLabelProps={{ shrink: true }}
                            rows={3}
                            value={getLocalizedText(Data.title1, i18n.language)}
                            onChange={(v) => {
                              let arabicPart = getLocalizedText(Data.title1, "ar");
                              let englishPart = getLocalizedText(Data.title1, "en");
                              if (i18n.language === "ar")
                                arabicPart = v.currentTarget.value;
                              else
                                englishPart = v.currentTarget.value;
                              let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                              setData({ ...Data, title1: resolvePersianAndArabicNumbers(text) });
                            }}
                          />
                        </div>
                        {/* <div className='mt-3 '>
                         <TextField
                           id='outlined-basic'
                           label='رابط يوتيوب'
                           variant='outlined'
                           value={Data.yuotube}
                           onChange={(v) => {
                             setData({
                               ...Data,
                               yuotube: v.currentTarget.value,
                             });
                           }}
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position='start'>
                                 <YouTube />
                               </InputAdornment>
                             ),
                           }}
                         />
                       </div> */}
                        <div className='mt-4 w-100'>
                          <LoadingButton
                            classes="primary-btn px-3 m-auto"
                            handleClick={handleSave}
                            label={t("SaveChanges")}
                            loading={props.loading || isSubmittingData}
                          />
                        </div>
                      </div>
                    ) : (
                      <div data-aos='zoom-in' className="content" style={{
                        alignItems: MODERN_TEMPLATES.includes(+templateId) ? (+Data.postion === 0 ? 'flex-start' : +Data.postion === 1 ? 'center' : 'flex-end') : 'inherit'
                      }}>
                        {DARK_TEMPLATES.includes(+templateId) && youtubeLink && (
                          <YoutubeDialog
                            position={Data.postion}
                            youtubeLink={youtubeLink}
                          />
                        )}
                        <img
                          src={logo} alt="logo"
                          className={
                            {
                              0: "mobile-logo text-align-last-left",
                              1: "mobile-logo text-align-last-center",
                              2: "mobile-logo text-align-last-right",
                            }[Data.postion]
                          } />
                        <h3
                          className={
                            {
                              0: "sub-title text-align-last-left",
                              1: "sub-title text-align-last-center",
                              2: "sub-title text-align-last-right",
                            }[Data.postion]
                          }>
                          <img src={logo} alt="logo" />
                          {getLocalizedText(Data.title2, i18n.language, false)}
                        </h3>
                        <h1
                          className={
                            {
                              0: "title text-align-last-left text-left",
                              1: "title text-align-last-center text-center",
                              2: "title text-align-last-right text-right",
                            }[Data.postion]
                          }>
                          {getLocalizedText(Data.title1, i18n.language, false)}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BannerV2;
