import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppLayout from "../../components/layouts/app-layout";
import PageHeader from "../../components/global-components/page-header";
import { useAuth } from "../../helpers/context";
import { getNewsDetail } from "../../api/blogApi";
import purify from "dompurify";
import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Twitter } from "../../constants/icons";
import moment from "moment";
import { Helmet } from "react-helmet";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../helpers";

export default function News() {
  const [data, setData] = useState();
  const { vendor, token, templateId } = useAuth();
  const { id } = useParams();
  const isAdmin = window.location.href.includes("admin");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const { t, i18n } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(
    () => {
      if (vendor) {
        getNewsDetail({
          userName: vendor,
          language: i18n.language === 'ar' ? '0' : '1',
          id: id,
          userId: token || undefined,
        }).then((_response) => {
          if (_response.resCode === 0) {
            setData(_response.response);
          }
        });
      }
    },
    // eslint-disable-next-line
    [vendor, token]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t("Amakkn")} | ${data?.subject}`}</title>
        <meta
          property='og:title'
          content={`${t("Amakkn")} | ${data?.subject}`}
          data-react-helmet='true'
        />
        <meta
          name='description'
          content={data?.shortDescription?.trim()}
          data-react-helmet='true'
        />
        <meta
          property='og:description'
          content={data?.shortDescription?.trim()}
          data-react-helmet='true'
        />
        <meta
          property='og:url'
          content={`${window.location.origin}/news/${id}`}
          data-react-helmet='true'
        />
        <meta property='og:type' content='article' data-react-helmet='true' />
        <meta
          property='og:image'
          content={data?.image}
          data-react-helmet='true'
        />
        <meta
          name='twitter:card'
          content='summary_large_image'
          data-react-helmet='true'
        />
        <meta
          name='twitter:title'
          content={`${t("Amakkn")} | ${data?.subject}`}
          data-react-helmet='true'
        />
        <meta
          name='twitter:description'
          content={data?.shortDescription?.trim()}
          data-react-helmet='true'
        />
        <meta
          name='twitter:image'
          content={data?.image}
          data-react-helmet='true'
        />
      </Helmet>
      <AppLayout
        pageTitle={t("NewsDetails")}
        withoutNav={isAdmin}
        withFooter={!isAdmin}
        unsetOverflow>
        {data && (
          <div style={{ width: "100%", maxWidth: "135rem", margin: "auto" }}>
            {isAdmin && (
              <Link className='primary-btn py-2 px-3 back-btn' to='/admin/blog'>{t("BackToBlogPage")}</Link>
            )}
            <PageHeader
              moreHeight
              isAdmin={isAdmin}
              name={getLocalizedText(data?.subject, i18n.language, false)}
              imgCover
              imageUrl={data?.image}
            // shareButton={
            //   <button className='btn fav-btn share-news-btn' onClick={() => setOpenShareModal(true)}>
            //     <span>مشاركة</span>
            //     <ShareIcon />
            //   </button>
            // }
            />
            <div
              className={`projects-area news-details ${isAdmin ? "admin" : ""}`}
              style={{
                padding: "30px calc(var(--px-meduim) / 2) 40px ",
                background: DARK_TEMPLATES.includes(+templateId) ? "var(--main-color-two)" : "#fff"
              }}
             >
              <div className='row flex-column justify-content-center align-items-center'>
                <div className='col-12 col-lg-10 col-xl-8 mb-4'>
                  <span style={{ color: "var(--heading-color)" }}>
                    <span style={{ fontWeight: 600 }}>{t("PublicationDate")}: </span>
                    {moment(data?.createdAt).locale("en").format("D/MM/YYYY")}
                  </span>
                </div>
                {data?.sections &&
                  data?.sections?.map((item, index) => (
                    <div key={index} className='col-12 col-lg-10 col-xl-8 '>
                      {item.type === "text" ? (
                        <div
                          className='text-wrapper'
                          style={{ wordWrap: "break-word" }}
                          dangerouslySetInnerHTML={{ __html: purify.sanitize(item.value) }}
                        />
                      ) : item.type === "image" ? (
                        <div className='news-image-wrapper'>
                          <img
                            src={item.value}
                            alt={data?.subject + index}
                            className='news-image'
                          />
                          {item.value?.indexOf("#") > -1 && (
                            <span className='news-image-desc'>
                              {item.value.substring(item.value?.indexOf("#") + 1).replace(/#/g, "")}
                            </span>
                          )}
                        </div>
                      ) : item.type === "gallery" ? (
                        item.value
                          ?.substring(0, item.value.length - 1)
                          .split(",")
                          ?.map((i, num) => (
                            <div className='news-image-wrapper'>
                              <img
                                src={i}
                                alt={data?.subject + num}
                                className='news-image'
                              />
                              {i?.indexOf("#") > -1 && (
                                <span className='news-image-desc'>
                                  {i.substring(i?.indexOf("#") + 1).replace(/#/g, "")}
                                </span>
                              )}
                            </div>
                          ))
                      ) : item.type === "line" ? (
                        <hr style={{ borderBottom: "1px solid var(--main-color-one)" }} />
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>
            <Dialog
              onClose={() => setOpenShareModal(false)}
              className='custom-dialog no-padding full-screen2'
              open={openShareModal}>
              <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
                <div className='modal2 '>
                  <IconButton
                    onClick={() => setOpenShareModal(false)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                  </IconButton>

                  <div className='social-modal'>
                    <div className='social-items-wrapper'>
                      <ClickAwayListener
                        onClickAway={() => setOpenTooltip(false)}>
                        <Tooltip
                          PopperProps={{ disablePortal: true }}
                          sx={{ zIndex: "99999999999999999" }}
                          onClose={() => setOpenTooltip(false)}
                          open={openTooltip}
                          disableHoverListener
                          placement='left'
                          title={t("CopiedSuccessfully")}>
                          <div
                            className='social-item'
                            onMouseLeave={() => setOpenTooltip(false)}
                            onClick={() => {
                              navigator?.clipboard?.writeText(
                                `${window.location.origin}/news/${id}`
                              );
                              setOpenTooltip(true);
                            }}>
                            <ContentCopyIcon />
                            <span>{t("CopyLink")}</span>
                          </div>
                        </Tooltip>
                      </ClickAwayListener>

                      <div
                        className='social-item'
                        onClick={() => {
                          window.open(
                            encodeURI(
                              `${isLargeScreen ? `https://api.whatsapp.com/` : `whatsapp://`}send?text=${getLocalizedText(data?.subject, i18n.language, false)
                              }\n${getLocalizedText(data?.shortDescription, i18n.language, false)?.trim()}\n\n${window.location.origin
                              }/news/${id}`
                            )
                          );
                        }}>
                        <WhatsAppIcon />
                        <span>{t("WhatsApp")}</span>
                      </div>

                      <div
                        className='social-item'
                        onClick={() => {
                          window.open(
                            "http://facebook.com/sharer/sharer.php?u=" +
                            encodeURIComponent(
                              `${window.location.origin}/news/${id}`
                            ),
                            "",
                            "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
                          );
                        }}>
                        <FacebookIcon />
                        <span>{t("Facebook")}</span>
                      </div>

                      <div
                        className='social-item'
                        onClick={() => {
                          window.open(
                            "https://twitter.com/intent/tweet?text=" +
                            encodeURIComponent(
                              `${window.location.origin}/news/${id}`
                            )
                          );
                        }}>
                        <Twitter medium />
                        <span>X</span>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <Fab
              size='large'
              color='primary'
              onClick={() => setOpenShareModal(true)}
              style={{
                position: "sticky",
                bottom: "30px",
                left: i18n.language === "ar"? 'unset': '40px',
                right: i18n.language === "ar" ? '40px' : 'unset',
                borderRadius: MODERN_TEMPLATES.includes(+templateId) ? "12px" : "100%",
                marginBottom: "50px",
              }}>
              <ShareIcon />
            </Fab>
          </div>
        )}
      </AppLayout>
    </>
  );
}
