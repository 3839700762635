import React, { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  cancelUserPlan,
  cancelWLPlan,
  getNormalPlans,
  getUserPlan,
  getUserProile,
  getWLPlans,
  renew,
  setUserPlan,
} from "../../../api/userApi";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import moment from "moment/moment";
import { toast } from "react-toastify";
import {
  DEMO_VENDORS,
  plansFeatures,
  wlPlansAvailabilty,
} from "../../../constants";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Hidden,
  IconButton,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";
import { checkPromo } from "../../../api/propertyApi";
import { Close } from "@mui/icons-material";
import { getDemoSnakbar, openDeleteModal } from "../../../helpers";
import { ThemeProvider } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  changeOrderStatus,
  checkPayment,
  getUserPayments,
  saveOrder,
} from "../../../api/generalAPI";
import LoadingData from "../../../components/global-components/loading-data";
import NoData from "../../../components/global-components/no-data";
import InfoIcon from "@mui/icons-material/Info";
import PlansTable from "../../../components/plans-table";
import AmakknPlansCards from "../../amakkn/plan-registration/components/amakkn-plans-cards";
import { useTranslation } from "react-i18next";
import { CustomerSupportIcon } from "../../../constants/icons";

const pageSize = 10;

export default function Subscriptions() {
  // const [data, setData] = useState([]);
  // const [loadingData, setLoadingData] = useState(true);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const {
    currentPlan,
    token,
    UserId,
    currentDomain,
    setCurrentPlan,
    AccountType,
    setAccountType,
    vendor,
    setPlanEndDate,
  } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loadingCouponButton, setLoadingCouponButton] = useState(false);
  const [openMoyasarForm, setOpenMoyasarForm] = useState(false);
  const [openMoyasarForm2, setOpenMoyasarForm2] = useState(false);
  const [WLPlanCanceled, setWLPlanCanceled] = useState(false);
  const [page, setPage] = useState(1);
  const [openPaymentsDetailsModal, setOpenPaymentsDetailsModal] = useState({
    visible: false,
    content: "",
  });
  const [plans, setPlans] = useState([]);
  // const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
  const [coupon, setCoupon] = useState({
    value: "",
    isValid: "",
    discount: "",
  });
  const [selectedplan, setSelectedplan] = useState({
    name: "",
    id: "",
    price: "",
  });
  const { planId, planPrice, planName, planDuration } = useParams();
  const [mode, setMode] = useState("");
  const [selectedPlanDuration, setSelectedPlanDuration] = useState("");

  const [openPaymentsHistoryModal, setOpenPaymentsHistoryModal] =
    useState(false);
  const [loadingPaymentsHistory, setLoadingPaymentsHistory] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsTotalCount, setPaymentsTotalCount] = useState(0);
  const [searchParams] = useSearchParams();
  const [AmakknUserPlan, setAmakknUserPlan] = useState(undefined);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [user, setUser] = useState();
  const [WLPlans, setWLPlans] = useState([]);
  const { t, i18n } = useTranslation();

  // const [advertiserNumber, setAdvertiserNumber] = useState();
  // const [isSubmittingButton, setIsSubmittingButton] = useState(false);

  const [isUserVerified, setIsUserVerified] = useState("");

  useEffect(
    () => {
      // getWLPlans({ language: "0" }).then((res) => {
      //   const data = res.response.plans;
      //   let plans = [];
      //   data.map((plan) =>
      //     plans.push({ ...plan, features: plansFeatures[plan.id] })
      //   );
      //   setData(plans);
      //   setLoadingData(false);
      // });

      getUserProile({
        userId: token,
        language: i18n.language === "ar" ? "0" : "1",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setIsUserVerified(_userInfo.response.userProfile.isUserVerified);
          setAccountType(_userInfo.response.userProfile.accountType);
          setUser(_userInfo.response.userProfile);
          setWLPlanCanceled(
            _userInfo.response.userProfile.wlPlanCanceled || false
          );
          // setAdvertiserNumber(_userInfo.response.userProfile.advertiserNumber);
        }
      });
      getUserPlan({
        userId: token,
        language: i18n.language === "ar" ? "0" : "1",
      }).then((result) => {
        if (result.resCode === 0) {
          setAmakknUserPlan(result.response);
        } else {
          toast.error(result.resStr);
        }
      });

      getNormalPlans({
        language: i18n.language === "ar" ? "0" : "1",
        userId: token,
      }).then((res) => {
        setPlans(res.response);
      });
      getWLPlans({ language: i18n.language === "ar" ? "0" : "1" }).then(
        (res) => {
          const data = res.response.plans;
          let plansTemp = [];
          data.map((plan) =>
            plansTemp.push({ ...plan, features: plansFeatures[plan.id] })
          );
          setWLPlans(plansTemp);
        }
      );
      // setLoadingData(false);
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (
        searchParams.get("id") &&
        !searchParams.get("renew") &&
        planId &&
        planName &&
        planPrice &&
        planDuration
      ) {
        let paymentId = searchParams.get("id");
        checkPayment({ paymentId }).then((res) => {
          let data = res.response;
          if (res.resCode === 0) {
            changeOrderStatus({
              language: i18n.language === "ar" ? "0" : "1",
              paymentId: paymentId,
              paymentStatus: data.status,
              userId: token,
              status: data.status === "paid" ? "done" : "failed",
            });
            if (data.status === "paid") {
              setUserPlan({
                userId: token,
                planId: planId,
                isTrialVersion: "0",
              }).then((res) => {
                if (res.resCode === 0) {
                  Swal.fire({
                    title: t("SubscriptionCompletedSuccessfully"),
                    text: t("YouHaveSuccessfullySubscribedToPlan", {
                      name: planName,
                    }),
                    icon: "success",
                    confirmButtonText: t("Close"),
                  });
                  setPlanEndDate(
                    moment()
                      .add(planDuration, "days")
                      .locale("en")
                      .format("YYYY-MM-DD")
                  );
                  setCurrentPlan({
                    isTrialVersion: "0",
                    plan: {
                      name: planName,
                      cost: planPrice,
                      id:
                        +planId === 9
                          ? "7"
                          : +planId === 10
                          ? "6"
                          : +planId === 11
                          ? "9"
                          : "8",
                      duration: planDuration,
                    },
                    endDate: moment()
                      .locale("en")
                      .add(planDuration, "day")
                      .format("YYYY-MM-D"),
                    startDate: moment().locale("en").format("YYYY-MM-D"),
                  });
                }
              });
            } else {
              Swal.fire({
                title: t("NotSubscribed"),
                text: data.source.message,
                icon: "error",
                confirmButtonText: t("Close"),
              });
            }
          } else {
            toast.error(res.resStr);
          }
        });
      }
    }, // eslint-disable-next-line
    [planId, planName, planPrice, planDuration]
  );

  useEffect(
    () => {
      if (
        searchParams.get("id") &&
        searchParams.get("planId") &&
        searchParams.get("renew")
      ) {
        setWaitingResponse(true);
        let planId = searchParams.get("planId");
        let paymentId = searchParams.get("id");

        checkPayment({ paymentId }).then((res) => {
          let data = res.response;
          if (res.resCode === 0) {
            changeOrderStatus({
              language: i18n.language === "ar" ? "0" : "1",
              paymentId: paymentId,
              paymentStatus: data.status,
              userId: token,
              status: data.status === "paid" ? "done" : "failed",
            });
            if (data.status === "paid") {
              setUserPlan({
                userId: token,
                planId: planId,
                isTrialVersion: "0",
              }).then((res) => {
                if (res.resCode === 0) {
                  setWaitingResponse(false);
                  Swal.fire({
                    title: t("RenewedSuccessfully"),
                    text: t("SubscriptionRenewedSuccessfully"),
                    icon: "success",
                    confirmButtonText: t("Close"),
                  });
                  getUserPlan({
                    userId: token,
                    language: i18n.language === "ar" ? "0" : "1",
                  }).then((result) => {
                    if (result.resCode === 0) {
                      setAmakknUserPlan(result.response);
                    }
                  });
                  // }
                } else {
                  toast.error(res.resStr);
                  setWaitingResponse(false);
                }
              });
            } else {
              setWaitingResponse(false);
              Swal.fire({
                title: t("NotSubscribed"),
                text: data.source.message,
                icon: "error",
                confirmButtonText: t("Close"),
              });
            }
          } else {
            setWaitingResponse(false);
            toast.error(res.resStr);
          }
        });
      }
    },
    // eslint-disable-next-line
    [searchParams]
  );

  const upgradePlan = (item) => {
    // if (+item.id > +(currentPlan?.plan?.id || '1')) {
    setSelectedplan({ ...item, price: item.cost || item.price });
    setOpenDrawer(true);
    // } else {
    //   toast.error('عذراً, لا يمكنك الاشتراك بخطّة ادنى من خطتّك الحاليّة');
    // }
  };

  const applyCouponCode = () => {
    if (coupon && coupon.value) {
      setLoadingCouponButton(true);
      checkPromo({
        userId: UserId,
        code: coupon.value,
      })
        .then((res) => {
          if (res.resCode === 0) {
            setCoupon({
              ...coupon,
              discount: res.response.discount,
              isValid: res.response.isValid,
            });
            if (res.response.isValid === "0")
              toast.error(t("TheEnteredDiscountCodeHasExpired"));
          } else {
            toast.error(res.resStr);
          }
          setLoadingCouponButton(false);
        })
        .catch((e) => setLoadingCouponButton(false));
    } else {
      toast.error(t("EnterTheDiscountCode"));
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setCoupon({
      value: "",
      isValid: "",
      discount: "",
    });
  };

  const getUserPaymentsData = (page) => {
    setLoadingPaymentsHistory(true);
    getUserPayments({ userId: token, page: page, pageSize: pageSize })
      .then((res) => {
        if (res.resCode === 0) {
          setPayments(res.response.array);
          setPaymentsTotalCount(res.response.totalCount);
        }
        setLoadingPaymentsHistory(false);
      })
      .catch(() => {
        setLoadingPaymentsHistory(false);
      });
  };
  useEffect(
    () => {
      if (openMoyasarForm)
        setTimeout(() => {
          window?.Moyasar?.init({
            element: ".mysr-form",
            language: "ar",
            amount: coupon.isValid
              ? (
                  (parseFloat(selectedplan.price) -
                    (parseFloat(selectedplan.price) *
                      parseFloat(coupon.discount)) /
                      100) *
                  100
                ).toFixed(2)
              : parseFloat(selectedplan.price * 100).toFixed(2),
            currency: "SAR",
            description: `${t("SubscribeToPlan")} ${selectedplan.name} | ${
              user?.name
            } | ${user?.phone} | ${user?.userId} | ${coupon?.value}`,
            publishable_api_key: process.env.REACT_APP_MOYASAR_KEY,
            // "pk_live_YyU1CG3AbLfLgAeMb4NetZzyFhYCFFPqVVBVe2wJ", //'pk_test_JiU5dzWs6Y2J1dz2BbLZdeEDVjDTws7tdh9vjxVZ',
            callback_url: `https://${currentDomain}/admin/plans/${selectedplan.id}/${selectedplan.name}/${selectedplan.price}/${selectedplan.duration}`,
            methods: ["creditcard"],
            // apple_pay: {
            //   country: "SA",
            //   // label: `${t("SubscribeToPlan")} ${selectedplan.name}`,
            //   label: "Amakkn",
            //   display_name: "amakkn.com",
            //   domain_name: "amakkn.com",
            //   validate_merchant_url:
            //     "https://api.moyasar.com/v1/applepay/initiate",
            // },
            credit_card: {
              save_card: true,
            },
            on_completed: function (payment) {
        
              return new Promise(function (resolve, reject) {
                saveOrder({
                  // paymentAmoutWl: '',
                  // paymentAmountBeforeDiscountWl: '',
                  // discountAmountWl: '',
                  // paymentAmoutNormal: selectedplan ? (coupon.isValid
                  //   ? ((+selectedplan?.price - ((+selectedplan?.price) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2)
                  //   : parseFloat(+selectedplan?.price * 100).toFixed(2)) : '',
                  // paymentAmountBeforeDiscountNormal: +selectedplan?.price ? +selectedplan?.price * 100 : '',
                  // discountAmountNormal: coupon.isValid && selectedplan ? (((+selectedplan?.price * parseFloat(coupon.discount)) / 100)*100).toFixed(2) : ''
                  paymentId: payment?.id,
                  paymentToken: payment?.source?.token || "-1",
                  paymentStatus: payment?.status,
                  paymentAmount: payment?.amount,
                  paymentMessage: " ",
                  userId: token,
                  // "wlPlan": +selectedplan.id === 9 ? '7' : +selectedplan.id === 10 ? '6' : +selectedplan.id === 11 ? '9' : '8',
                  domain: "-1",
                  status: "InProgress",
                  language: i18n.language === "ar" ? "0" : "1",
                  paymentAmountBeforeDiscount: +selectedplan.price * 100,
                  discountAmount: coupon.isValid
                    ? (
                        ((parseFloat(selectedplan.price) *
                          parseFloat(coupon.discount)) /
                          100) *
                        100
                      ).toFixed(2)
                    : "-1",
                  promoCodeId: coupon.isValid ? coupon.value : "-1",
                  isTrialVersion: "0",
                  userPlanId: selectedplan.id,
                })
                  .then((res) => {
                    resolve({ payment });
                  })
                  .catch((e) => {
                    toast.error(t("PaymentNotCompleted"));
                    reject();
                  });
              });
            },
          });
        }, 200);
    }, // eslint-disable-next-line
    [openMoyasarForm]
  );

  useEffect(
    () => {
      if (openMoyasarForm2)
        setTimeout(() => {
          window?.Moyasar?.init({
            element: ".mysr-form",
            language: "ar",
            amount: AmakknUserPlan?.nextPayment,
            currency: "SAR",
            description: `${t("ManuallyRenewYourPlanSubscription")} ${
              AmakknUserPlan?.planId
            } | ${user?.name} | ${user?.phone} | ${user?.userId} | ${
              coupon?.value
            }`,
            publishable_api_key: process.env.REACT_APP_MOYASAR_KEY,
            // "pk_live_YyU1CG3AbLfLgAeMb4NetZzyFhYCFFPqVVBVe2wJ", //'pk_test_JiU5dzWs6Y2J1dz2BbLZdeEDVjDTws7tdh9vjxVZ',
            callback_url: `https://${currentDomain}/admin/plans?planId=${
              AmakknUserPlan?.planId || 0
            }&wlPlanId=${AmakknUserPlan?.wlPlanId || 0}&renew=true`,
            methods: ["creditcard"],//"applepay",
            // apple_pay: {
            //   country: "SA",
            //   // label: `${t("ManuallyRenewYourPlanSubscription")}`,
            //   label: "Amakkn",
            //   display_name: "amakkn.com",
            //   domain_name: "amakkn.com",
            //   validate_merchant_url:
            //     "https://api.moyasar.com/v1/applepay/initiate",
            // },
            credit_card: {
              save_card: true,
            },
            on_completed: function (payment) {
            
              return new Promise(function (resolve, reject) {
                saveOrder({
                  paymentId: payment?.id,
                  paymentStatus: payment?.status,
                  paymentAmount: payment?.amount,
                  paymentAmountBeforeDiscount: +AmakknUserPlan?.nextPayment
                    ? +AmakknUserPlan?.nextPayment
                    : 0,
                  discountAmount: "-1",
                  promoCodeId: "-1",
                  paymentMessage: " ",
                  paymentToken: payment?.source?.token || "-1",
                  userId: token,
                  domain: "-1",
                  status: "InProgress",
                  language: i18n.language === "ar" ? "0" : "1",
                  isTrialVersion: "0",
                  userPlanId: AmakknUserPlan?.planId || undefined,
                })
                  .then((res) => {
                    resolve({ payment });
                  })
                  .catch((e) => {
                    toast.error(t("PaymentNotCompleted"));
                    reject();
                  });
              });
            },
          });
        }, 200);
    }, // eslint-disable-next-line
    [openMoyasarForm2]
  );

  const openConfirmPopup = () => {
    openDeleteModal(
      t("ConfirmSubscription"),
      t("AreYouSureYouWantToSubscribeForThisPlan"),
      () => {
        if (
          parseFloat(selectedplan.price) === 0 ||
          (coupon.isValid &&
            (
              parseFloat(selectedplan.price) -
              (parseFloat(selectedplan.price) * parseFloat(coupon.discount)) /
                100
            ).toFixed(1) === "0.0")
        ) {
          setUserPlan({
            userId: token,
            planId: selectedplan.id,
            isTrialVersion: "0",
          }).then((res) => {
            if (res.resCode === 0) {
              Swal.fire({
                title: t("SubscriptionCompletedSuccessfully"),
                text: t("YouHaveSuccessfullySubscribedToPlan", {
                  name: selectedplan.name,
                }),
                icon: "success",
                confirmButtonText: t("Close"),
              });
              handleCloseDrawer();
              setCurrentPlan({
                isTrialVersion: "0",
                plan: {
                  name: selectedplan.name,
                  cost: selectedplan.price,
                  id:
                    +selectedplan.id === 9
                      ? "7"
                      : +selectedplan.id === 10
                      ? "6"
                      : +selectedplan.id === 11
                      ? "9"
                      : "8",
                  duration: selectedplan.duration,
                },
                endDate: moment()
                  .locale("en")
                  .add(selectedplan.duration, "day")
                  .format("YYYY-MM-D"),
                startDate: moment().locale("en").format("YYYY-MM-D"),
              });
            }
          });
        } else {
          setOpenMoyasarForm(true);
        }
      },
      t("Subscribe")
    );
  };

  return (
    <>
      <SubHeader OKElement={<></>} />
      {AccountType && currentPlan && (
        <>
          {currentPlan?.plan?.id !== "" && (
            <div className='custom-card without-header'>
              <div className='card-body'>
                <div
                  className='d-flex align-items-center justify-content-between mb-2'
                  style={{ flexWrap: "wrap" }}>
                  <h4>{t("CurrentPlan")}</h4>
                  <button
                    className='primary-btn'
                    onClick={() => {
                      setOpenPaymentsHistoryModal(true);
                      getUserPaymentsData("1");
                    }}
                    style={{ fontSize: "13px" }}>
                    {t("ViewPaymentHistory")}
                  </button>
                </div>
                <p style={{ fontSize: 18 }}>
                  {currentPlan?.plan?.name +
                    ` ${
                      currentPlan?.isTrialVersion === "1" ? t("TrialMode") : ""
                    }`}
                </p>
                <Alert
                  severity={
                    wlPlansAvailabilty[AccountType].includes(
                      currentPlan?.plan?.id
                    )
                      ? moment(currentPlan?.endDate).diff(moment(), "days") > 7
                        ? "info"
                        : moment(currentPlan?.endDate).diff(moment(), "days") <=
                          0
                        ? "error"
                        : "warning"
                      : "error"
                  }
                  className='info-alert mb-3'
                  sx={{ "& .MuiAlert-message": { width: "100%" } }}>
                  <AlertTitle
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}>
                    {t("NextPayment")}
                    <div className='d-flex my-2 my-md-0' style={{ gap: 10 }}>
                      {moment(currentPlan?.endDate).diff(moment(), "days") <
                        0 &&
                        AmakknUserPlan?.lastPaymentFailed === "1" && (
                          <button
                            className='primary-btn btn-blue'
                            onClick={() => {
                              // setMode('1');
                              // upgradePlan(plans.filter(item => (+currentPlan?.plan?.id === 6 && +item.id === 10) ||
                              //   (+currentPlan?.plan?.id === 7 && +item.id === 9) ||
                              //   (+currentPlan?.plan?.id === 8 && +item.id === 12) ||
                              //   (+currentPlan?.plan?.id === 9 && +item.id === 11))[0]);
                              // setSelectedPlanDuration([6, 8].includes(+currentPlan?.plan?.id) ? 'monthly' : 'yearly');
                              openDeleteModal(
                                t("ConfirmRenewal"),
                                t("AreYouSureYouWantToRenewYourSubscription"),
                                async () => {
                                  await renew({
                                    language:
                                      i18n.language === "ar" ? "0" : "1",
                                    userId: token,
                                    orderId: AmakknUserPlan?.orderId,
                                  }).then((res) => {
                                    if (res.resCode === 0) {
                                      toast.success(
                                        t(
                                          "YourSubscriptionHasBbeenSuccessfullyRenewed"
                                        )
                                      );
                                    } else {
                                      toast.error(res.resStr);
                                      setOpenMoyasarForm2(true);
                                    }
                                  });
                                },
                                t("Confirm")
                              );
                            }}
                            style={{ fontSize: "13px" }}>
                            {t("RenewSubscription")}
                          </button>
                        )}
                      {WLPlanCanceled && (
                        <Tooltip
                          title={
                            WLPlanCanceled === "1"
                              ? t("PlanAutoRenewalHasBeenCancelled")
                              : t("CancelAutoRenewalOfThePlan")
                          }
                          placement='top'>
                          <button
                            className='primary-btn delete-btn'
                            disabled={WLPlanCanceled === "1"}
                            onClick={() => {
                              if (WLPlanCanceled === "0") {
                                if (DEMO_VENDORS.includes(vendor)) {
                                  setOpenDemoSnackBar(true);
                                } else {
                                  openDeleteModal(
                                    t("ConfirmCancellation"),
                                    t(
                                      "AreYouSureYouWantToCancelYourAutoRenewalSubscription"
                                    ),
                                    () => {
                                      cancelUserPlan({
                                        language:
                                          i18n.language === "ar" ? "0" : "1",
                                        userId: token,
                                      }).then((res) => {
                                        if (res.resCode === 0) {
                                          cancelWLPlan({
                                            language:
                                              i18n.language === "ar"
                                                ? "0"
                                                : "1",
                                            userId: token,
                                          }).then((res) => {
                                            if (res.resCode === 0) {
                                              toast.success(
                                                t(
                                                  "PlanAutoRenewalHasBeenCancelled"
                                                )
                                              );
                                              setWLPlanCanceled("1");
                                            } else toast.error(res.resStr);
                                          });
                                        } else toast.error(res.resStr);
                                      });
                                    },
                                    t("Confirm")
                                  );
                                }
                              }
                            }}
                            style={{ fontSize: "13px" }}>
                            {t("CancelAutoRenewal")}
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </AlertTitle>
                  {wlPlansAvailabilty[AccountType].includes(
                    currentPlan?.plan?.id
                  ) ? (
                    moment(currentPlan?.endDate).diff(moment()) <= 0 ? (
                      <>
                        {currentPlan?.isTrialVersion === "1"
                          ? t(
                              "PleaseNoteThatYourSubscriptionHasExpiredOnTheDate"
                            )
                          : t(
                              "PleaseNoteThatYourSubscriptionHasExpiredOnTheDate"
                            )}{" "}
                        <strong>
                          {moment(currentPlan?.endDate)
                            .locale("en")
                            .format("YYYY-MM-DD HH:mm")}
                          .
                        </strong>{" "}
                        {/* &nbsp; {t("PleaseRenewYourSubscription")}
                        {["4", "8"].includes(currentPlan?.plan?.id)
                          ? t("OrUpgradeThePlan")
                          : " "} */}
                      </>
                    ) : (
                      <>
                        {currentPlan?.isTrialVersion === "1"
                          ? t("PleaseNoteThatTheTrialPeriodWillExpireOnTheDate")
                          : t(
                              "PleaseNoteThatYourSubscriptionWillExpireOnTheDate"
                            )}{" "}
                        <strong>
                          {moment(currentPlan?.endDate)
                            .locale("en")
                            .format("YYYY-MM-DD HH:mm")}
                          .
                        </strong>{" "}
                        {/* &nbsp; {t("PleaseRenewYourSubscription")}
                        {["4", "8"].includes(currentPlan?.plan?.id)
                          ? t("OrUpgradeThePlan")
                          : " "}
                        {t("BeforeThisDate")} */}
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </Alert>
              </div>
            </div>
          )}

          {currentPlan?.plan?.id === "" && (
            <div className='custom-card'>
              <div className='card-header'>
                <h4>{t("UpgradeCurrentPlan")}</h4>
                <h6>{t("YouCanUpgradeYourCurrentPlanToEnjoyMoreFeatures")}</h6>
              </div>
              <div className='card-body'>
                <AmakknPlansCards
                  data={plans}
                  fromWL
                  WLPlans={WLPlans}
                  currentPlan={AmakknUserPlan}
                  AccountType={AccountType}
                  upgradePlanFunc={upgradePlan}
                  onOK={() => {
                    getUserPlan({
                      userId: token,
                      language: i18n.language === "ar" ? "0" : "1",
                    }).then((result) => {
                      if (result.resCode === 0) {
                        setAmakknUserPlan(result.response);
                      } else {
                        toast.error(result.resStr);
                      }
                    });
                  }}
                  currentPlanID={+AmakknUserPlan?.planId || 0}
                  isUserVerified={isUserVerified}
                />
              </div>
            </div>
          )}

          {["6", "7"].includes(currentPlan?.plan?.id) &&
            wlPlansAvailabilty[AccountType].includes(currentPlan?.plan?.id) &&
            currentPlan?.isTrialVersion !== "1" && (
              <div className='custom-card without-header'>
                <div className='card-body'>
                  <div
                    className='d-flex align-items-center justify-content-between mb-2'
                    style={{ flexWrap: "wrap" }}>
                    <div>
                      <h4>{t("UpgradeCurrentPlan")}</h4>
                      <h6>
                        {t("YouCanUpgradeYourCurrentPlanToEnjoyMoreFeatures")}
                      </h6>
                    </div>
                    <button
                      className='primary-btn btn-blue'
                      onClick={() => {
                        setMode("2");
                        if (["6"].includes(currentPlan?.plan?.id)) {
                          upgradePlan(
                            plans.filter((item) => item.id === "12")[0]
                          );
                          setSelectedPlanDuration("monthly");
                        } else if (["7"].includes(currentPlan?.plan?.id)) {
                          upgradePlan(
                            plans.filter((item) => item.id === "11")[0]
                          );
                          setSelectedPlanDuration("yearly");
                        }
                      }}
                      style={{ fontSize: "13px", backgroundColor: "#243ead" }}>
                      {t("UpgradeThePlan")}
                    </button>
                  </div>
                </div>
                {/* <div className='card-body'>
                {loadingData ? (
                  <LoadingData />
                ) : (
                  data.length > 0 && (
                    <PlansCards
                      upgradePlan={upgradePlan}
                      currentPlan={currentPlan}
                      data={data}
                      upgradeOnly
                    />
                  )
                )}
              </div> */}
              </div>
            )}

          {!["8", "9"].includes(currentPlan?.plan?.id) && plans && (
            <>
              <div className='custom-card'>
                <div className='card-header'>
                  <h4>{t("ComparePlans")}</h4>
                  <h6>{t("YouCanCompareTheAvailablePlansViaTheListBelow")}</h6>
                </div>
                <div className='card-body'>
                  <PlansTable
                    currentPlan={currentPlan}
                    upgradePlan={upgradePlan}
                    data={plans}
                  />
                </div>
              </div>
            </>
          )}

          {/* {['2', '3', '4', '5'].includes(currentPlan.plan.id) && (
            <div className='custom-card with-switcher'>
              <div className='card-header flex-column flex-sm-row'>
                <div>
                  <h4>الترقية لإحدى الخطط الخاصّة بالشركات</h4>
                  <h6>
                    يمكنك ترقية خطتّك الحالية إلى إحدى الخطط الخاصّة بالشركات والتمتّع بميزات أكثر
                  </h6>
                </div>
                {/* {wlPlansAvailabilty[AccountType].includes(currentPlan.plan.id) && <ChangeTypePopup
                  openChangeTypeModal={openChangeTypeModal}
                  setOpenChangeTypeModal={setOpenChangeTypeModal}
                  desc={'يجب عليك تغيير نوع حسابك إلى حساب أعمال لكي تتمكن من المتابعة '}
                  trigger={
                    <button
                      className='primary-btn btn-blue'
                      onClick={() => {
                        setMode('2');
                        setSelectedPlanDuration('');
                        setOpenChangeTypeModal(true);
                      }}
                      style={{ fontSize: "13px", backgroundColor: '#243ead' }}>
                      {t("UpgradeThePlan")}
                    </button>
                  }
                />} 

          
              </div>
              <div className='card-body'>
                {loadingData ? (
                  <LoadingData />
                ) : (
                  data.length > 0 && (
                    <PlansCards
                      upgradePlan={(item) => {
                        setSelectedPlanDuration('monthly');
                        upgradePlan(item);
                      }}
                      currentPlan={currentPlan}
                      data={data}
                      isUserVerified={isUserVerified}
                      corporate
                    />
                  )
                )}
              </div>
            </div>
          )} */}

          <div className='custom-card without-header call-us'>
            <div className='card-body'>
              <div className='flex-wrapper'>
                <div>
                  <h5>{t("HelpCenter")}</h5>
                  <p>{t("DoNotHesitateToContactUs")}</p>
                </div>
                <div>
                  <Link
                    to='/admin/ticket'
                    className='primary-btn justify-content-between'>
                    <CustomerSupportIcon />
                    <span>{t("HelpCenter")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Hidden mdUp>
              <ThemeProvider
                theme={(outerTheme) => ({
                  ...outerTheme,
                  direction: "ltr",
                })}>
                <Drawer
                  anchor={"left"}
                  sx={{ zIndex: 999999 }}
                  open={openDrawer}
                  onClose={handleCloseDrawer}>
                  <Close
                    onClick={() => handleCloseDrawer()}
                    sx={{
                      zIndex: 99,
                      top: "26px",
                      color: "#222",
                      position: "absolute",
                      left: "5px",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    className='custom-drawer'
                    style={{
                      padding: "14px",
                      height: "auto",
                    }}>
                    <div className='coupon-box row px-2'>
                      <h4 className='col-12 mb-3'>
                        {mode
                          ? mode === "1"
                            ? t("RenewSubscription")
                            : t("UpgradeThePlan")
                          : t("OrderSummary")}
                      </h4>
                      <p className='col-12 my-0' style={{ fontWeight: "500" }}>
                        {mode
                          ? mode === "1"
                            ? `${t("RenewSubscriptionWithPlan")} ${
                                selectedplan?.name
                              }`
                            : `${t("SubscribeToPlan")} ${selectedplan?.name}`
                          : ""}
                      </p>

                      {mode && (
                        <RadioGroup
                          row
                          value={selectedPlanDuration}
                          onChange={(e, value) => {
                            if (AccountType === "2") {
                              //corporate re-new
                              if (mode === "1") {
                                // re-new
                                if (["6", "7"].includes(currentPlan.plan.id)) {
                                  if (value === "monthly") {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "10"
                                      )[0]
                                    );
                                  } else {
                                    setSelectedplan(
                                      plans.filter((item) => item.id === "9")[0]
                                    );
                                  }
                                } else {
                                  if (value === "monthly") {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "12"
                                      )[0]
                                    );
                                  } else {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "11"
                                      )[0]
                                    );
                                  }
                                }
                              } else {
                                if (value === "monthly") {
                                  setSelectedplan(
                                    plans.filter((item) => item.id === "12")[0]
                                  );
                                } else {
                                  setSelectedplan(
                                    plans.filter((item) => item.id === "11")[0]
                                  );
                                }
                              }
                            } else {
                              //individuals
                              // if (mode === '1') {
                              //   // re-new
                              //   if (['2', '4'].includes(currentPlan.plan.id)) {
                              //     if (value === 'monthly') {
                              //       setSelectedplan(data.filter(item => item.id === '2')[0]);
                              //     } else {
                              //       setSelectedplan(data.filter(item => item.id === '4')[0]);
                              //     }
                              //   } else {
                              //     if (value === 'monthly') {
                              //       setSelectedplan(data.filter(item => item.id === '3')[0]);
                              //     } else {
                              //       setSelectedplan(data.filter(item => item.id === '5')[0]);
                              //     }
                              //   }
                              // } else {
                              //   // upgrade
                              //   if (value === 'monthly') {
                              //     setSelectedplan(data.filter(item => item.id === '3')[0]);
                              //   } else {
                              //     setSelectedplan(data.filter(item => item.id === '5')[0]);
                              //   }
                              // }
                            }
                            setSelectedPlanDuration(value);
                          }}>
                          <FormControlLabel
                            sx={{ m: 0 }}
                            value='monthly'
                            control={<Radio />}
                            label={t("Monthly")}
                          />
                          <FormControlLabel
                            sx={{ m: 0 }}
                            value='yearly'
                            control={<Radio />}
                            label={t("Yearly")}
                          />
                        </RadioGroup>
                      )}
                      {
                        <>
                          {mode !== "" && (
                            <h5 className='col-12 mb-3'>{t("OrderSummary")}</h5>
                          )}
                          <p className='coupon-details-row'>
                            <span>
                              {t("SubscribeToPlan")} {selectedplan?.name} /{" "}
                              {+selectedplan?.duration === 365
                                ? t("year")
                                : t("month")}
                            </span>
                          </p>
                          <p className='coupon-details-row'>
                            <span>{t("SubscriptionPriceIncludingVAT")}</span>
                            <span
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                textDecoration: coupon?.isValid
                                  ? "line-through #d50000"
                                  : "none",
                              }}>
                              {`${(+selectedplan?.price || 0).toFixed(2)} ${t(
                                "SAR"
                              )}/${
                                +selectedplan?.duration === 365
                                  ? t("year")
                                  : t("month")
                              }`}
                            </span>
                          </p>
                          <p
                            className='my-0 px-2'
                            style={{
                              color: "var(--main-color-one)",
                              fontWeight: 600,
                              fontSize: 16,
                              userSelect: "none",
                            }}>
                            {t("EnterDiscountCode")}
                          </p>
                          <div className='col-12 coupon-form p-0'>
                            <TextField
                              type='search'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ flex: 1 }}
                              size='small'
                              label={t("DiscountCode")}
                              onChange={(e) =>
                                setCoupon({ value: e.currentTarget.value })
                              }
                              value={coupon.value}
                            />
                            <LoadingButton
                              classes='btn primary-btn'
                              style={{ height: "40px" }}
                              handleClick={applyCouponCode}
                              label={t("Apply")}
                              loading={loadingCouponButton}
                            />
                          </div>

                          {coupon?.isValid && (
                            <Alert
                              variant='standard'
                              icon={false}
                              sx={{
                                width: "100%",
                                border: "1px solid #21B000",
                                "& .MuiAlert-message": {
                                  width: "100%",
                                },
                              }}
                              color='success'>
                              <div
                                className='d-flex flex-column w-100'
                                style={{ gap: "10px" }}>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}>
                                  {t("Discount")}
                                </span>
                                <div className='d-flex justify-content-between w-100'>
                                  <span
                                    style={{
                                      fontSize: "13px",
                                    }}>{`%${(+coupon.discount).toFixed(0)} ${
                                    +selectedplan?.duration === 365
                                      ? t("ForFirstYear")
                                      : t("ForFirstMonth")
                                  }`}</span>
                                  <span style={{ fontSize: "13px" }}>{`- ${(
                                    ((+selectedplan?.price || 0) *
                                      parseFloat(coupon.discount)) /
                                    100
                                  ).toFixed(2)} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}</span>
                                </div>
                              </div>
                            </Alert>
                          )}

                          <div className='col-12 coupon-details mt-3'>
                            {selectedplan && (
                              <p className='coupon-details-row'>
                                <span>{t("Subtotal")}</span>
                                <span style={{ fontSize: 15, fontWeight: 700 }}>
                                  {`${(
                                    (((+selectedplan?.price || 0) *
                                      (1 -
                                        (coupon.isValid
                                          ? parseFloat(coupon.discount) / 100
                                          : 0))) /
                                      115) *
                                    100
                                  ).toFixed(2)} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}
                                </span>
                              </p>
                            )}

                            <p className='coupon-details-row'>
                              <span>{t("VAT")}</span>
                              <span style={{ fontSize: 15, fontWeight: 700 }}>
                                {`${(
                                  (((+selectedplan?.price || 0) *
                                    (1 -
                                      (coupon.isValid
                                        ? parseFloat(coupon.discount) / 100
                                        : 0))) /
                                    115) *
                                  15
                                ).toFixed(2)} ${t("SAR")}/${
                                  +selectedplan?.duration === 365
                                    ? t("year")
                                    : t("month")
                                }`}
                              </span>
                            </p>
                            <p className='coupon-details-row'>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {t("DueNow")}
                              </span>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {coupon.isValid
                                  ? `${(
                                      (+selectedplan?.price || 0) *
                                      (1 - parseFloat(coupon.discount) / 100)
                                    ).toFixed(2)} ${t("SAR")}`
                                  : `${(+selectedplan?.price || 0).toFixed(
                                      2
                                    )} ${t("SAR")}`}
                              </span>
                            </p>
                            {coupon?.isValid && (
                              <p className='coupon-details-row'>
                                <span>
                                  {t("DueAfterTheDiscountExpiresIn")}{" "}
                                  {moment()
                                    .add(
                                      +selectedplan?.duration === 365 ? 12 : 1,
                                      "months"
                                    )
                                    .format("Do MMMM YYYY")}
                                </span>
                                <span style={{ fontSize: 15, fontWeight: 700 }}>
                                  {`${(+selectedplan?.price || 0).toFixed(
                                    2
                                  )} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}
                                </span>
                              </p>
                            )}
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  <LoadingButton
                    classes='btn primary-btn py-2 text-center pay-button'
                    loading={false}
                    handleClick={() => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        setOpenDemoSnackBar(true);
                      } else {
                        openConfirmPopup();
                        // setIsSubmittingButton(true);
                        // getBrokerRegaInfo({ licenseNumber: advertiserNumber }).then((res) => {
                        //   if (res.resCode === 0) {
                        //     if (res.response.isActive === '1') {
                        //       openConfirmPopup();
                        //     } else {
                        //       Swal.fire({
                        //         title: "لا يمكن إتمام العمليّة",
                        //         text: 'رخصة فال خاصتّك منتهيّة الصلاحيّة',
                        //         icon: "error",
                        //         confirmButtonText: t("Close"),
                        //       });
                        //     }
                        //   }
                        // }).catch(() => openConfirmPopup())
                        //   .finally(() => setIsSubmittingButton(false));
                      }
                    }}
                    label={
                      parseFloat(selectedplan.price) === 0 ||
                      (coupon.isValid &&
                        (
                          parseFloat(selectedplan.price) -
                          (parseFloat(selectedplan.price) *
                            parseFloat(coupon.discount)) /
                            100
                        ).toFixed(1) === "0.0")
                        ? t("Next")
                        : t("Pay")
                    }
                  />

                  <p className='payment-hint'>
                    {t("ByClickingThePayButtonYouAgreeTo")}
                    <a
                      href='https://www.amakkn.com/Terms'
                      rel='noreferrer'
                      target='_blank'
                      style={{
                        textDecoration: "underline",
                        marginRight: "4px",
                      }}>
                      {" "}
                      {t("TermsOfUse")}
                    </a>
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        position: "relative",
                        top: "2px",
                      }}>
                      *
                    </span>
                  </p>
                </Drawer>
              </ThemeProvider>
            </Hidden>
            <Hidden mdDown>
              <Dialog
                onClose={handleCloseDrawer}
                maxWidth={"xs"}
                className='custom-dialog'
                style={{ zIndex: 9999999 }}
                sx={{
                  "& .MuiPaper-root": {
                    margin: 0,
                    width: "100%",
                    zIndex: 9999999,
                  },
                }}
                open={openDrawer}>
                <DialogTitle>
                  <div className='dialog-head'>
                    <div>
                      <h2 className='mb-0'>
                        {mode
                          ? mode === "1"
                            ? t("RenewSubscription")
                            : t("UpgradeThePlan")
                          : t("OrderSummary")}
                      </h2>
                    </div>
                    <div className='dialog-actions close-only'>
                      <IconButton
                        aria-label='close'
                        onClick={handleCloseDrawer}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div
                    className='custom-drawer mx-auto'
                    style={{
                      padding: "14px 0px",
                      height: "auto",
                    }}>
                    <div className='coupon-box row px-2'>
                      <p className='col-12 my-0' style={{ fontWeight: "500" }}>
                        {mode
                          ? mode === "1"
                            ? `${t("RenewSubscriptionWithPlan")} ${
                                selectedplan?.name
                              }`
                            : `${t("SubscribeToPlan")} ${selectedplan?.name}`
                          : ""}
                      </p>

                      {mode && (
                        <RadioGroup
                          row
                          value={selectedPlanDuration}
                          onChange={(e, value) => {
                            if (AccountType === "2") {
                              //corporate re-new
                              if (mode === "1") {
                                // re-new
                                if (["6", "7"].includes(currentPlan.plan.id)) {
                                  if (value === "monthly") {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "10"
                                      )[0]
                                    );
                                  } else {
                                    setSelectedplan(
                                      plans.filter((item) => item.id === "9")[0]
                                    );
                                  }
                                } else {
                                  if (value === "monthly") {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "12"
                                      )[0]
                                    );
                                  } else {
                                    setSelectedplan(
                                      plans.filter(
                                        (item) => item.id === "11"
                                      )[0]
                                    );
                                  }
                                }
                              } else {
                                if (value === "monthly") {
                                  setSelectedplan(
                                    plans.filter((item) => item.id === "12")[0]
                                  );
                                } else {
                                  setSelectedplan(
                                    plans.filter((item) => item.id === "11")[0]
                                  );
                                }
                              }
                            } else {
                              //individuals
                              // if (mode === '1') {
                              //   // re-new
                              //   if (['2', '4'].includes(currentPlan.plan.id)) {
                              //     if (value === 'monthly') {
                              //       setSelectedplan(data.filter(item => item.id === '2')[0]);
                              //     } else {
                              //       setSelectedplan(data.filter(item => item.id === '4')[0]);
                              //     }
                              //   } else {
                              //     if (value === 'monthly') {
                              //       setSelectedplan(data.filter(item => item.id === '3')[0]);
                              //     } else {
                              //       setSelectedplan(data.filter(item => item.id === '5')[0]);
                              //     }
                              //   }
                              // } else {
                              //   // upgrade
                              //   if (value === 'monthly') {
                              //     setSelectedplan(data.filter(item => item.id === '3')[0]);
                              //   } else {
                              //     setSelectedplan(data.filter(item => item.id === '5')[0]);
                              //   }
                              // }
                            }
                            setSelectedPlanDuration(value);
                          }}>
                          <FormControlLabel
                            sx={{ m: 0 }}
                            value='monthly'
                            control={<Radio />}
                            label={t("Monthly")}
                          />
                          <FormControlLabel
                            sx={{ m: 0 }}
                            value='yearly'
                            control={<Radio />}
                            label={t("Yearly")}
                          />
                        </RadioGroup>
                      )}
                      {
                        <>
                          {mode !== "" && (
                            <h5 className='col-12 mb-3'>ملخص الطلب</h5>
                          )}
                          <p className='coupon-details-row'>
                            <span>
                              {t("SubscribeToPlan")} {selectedplan?.name} /{" "}
                              {+selectedplan?.duration === 365
                                ? t("year")
                                : t("month")}
                            </span>
                          </p>
                          <p className='coupon-details-row'>
                            <span>{t("SubscriptionPriceIncludingVAT")}</span>
                            <span
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                textDecoration: coupon?.isValid
                                  ? "line-through #d50000"
                                  : "none",
                              }}>
                              {`${(+selectedplan?.price || 0).toFixed(2)} ${t(
                                "SAR"
                              )}/${
                                +selectedplan?.duration === 365
                                  ? t("year")
                                  : t("month")
                              }`}
                            </span>
                          </p>
                          <p
                            className='my-0 px-2'
                            style={{
                              color: "var(--main-color-one)",
                              fontWeight: 600,
                              fontSize: 16,
                              userSelect: "none",
                            }}>
                            {t("EnterDiscountCode")}
                          </p>
                          <div className='col-12 coupon-form p-0'>
                            <TextField
                              type='search'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ flex: 1 }}
                              size='small'
                              label={t("DiscountCode")}
                              onChange={(e) =>
                                setCoupon({ value: e.currentTarget.value })
                              }
                              value={coupon.value}
                            />
                            <LoadingButton
                              classes='btn primary-btn'
                              style={{ height: "40px" }}
                              handleClick={applyCouponCode}
                              label={t("Apply")}
                              loading={loadingCouponButton}
                            />
                          </div>

                          {coupon?.isValid && (
                            <Alert
                              variant='standard'
                              icon={false}
                              sx={{
                                width: "100%",
                                border: "1px solid #21B000",
                                "& .MuiAlert-message": {
                                  width: "100%",
                                },
                              }}
                              color='success'>
                              <div
                                className='d-flex flex-column w-100'
                                style={{ gap: "10px" }}>
                                <span
                                  style={{ fontSize: "16px", fontWeight: 600 }}>
                                  {t("Discount")}
                                </span>
                                <div className='d-flex justify-content-between w-100'>
                                  <span
                                    style={{
                                      fontSize: "13px",
                                    }}>{`%${(+coupon.discount).toFixed(0)} ${
                                    +selectedplan?.duration === 365
                                      ? t("ForFirstYear")
                                      : t("ForFirstMonth")
                                  }`}</span>
                                  <span style={{ fontSize: "13px" }}>{`- ${(
                                    ((+selectedplan?.price || 0) *
                                      parseFloat(coupon.discount)) /
                                    100
                                  ).toFixed(2)} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}</span>
                                </div>
                              </div>
                            </Alert>
                          )}

                          <div className='col-12 coupon-details mt-3'>
                            {selectedplan && (
                              <p className='coupon-details-row'>
                                <span>{t("Subtotal")}</span>
                                <span style={{ fontSize: 15, fontWeight: 700 }}>
                                  {`${(
                                    (((+selectedplan?.price || 0) *
                                      (1 -
                                        (coupon.isValid
                                          ? parseFloat(coupon.discount) / 100
                                          : 0))) /
                                      115) *
                                    100
                                  ).toFixed(2)} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}
                                </span>
                              </p>
                            )}

                            <p className='coupon-details-row'>
                              <span>{t("VAT")}</span>
                              <span style={{ fontSize: 15, fontWeight: 700 }}>
                                {`${(
                                  (((+selectedplan?.price || 0) *
                                    (1 -
                                      (coupon.isValid
                                        ? parseFloat(coupon.discount) / 100
                                        : 0))) /
                                    115) *
                                  15
                                ).toFixed(2)} ${t("SAR")}/${
                                  +selectedplan?.duration === 365
                                    ? t("year")
                                    : t("month")
                                }`}
                              </span>
                            </p>
                            <p className='coupon-details-row'>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {t("DueNow")}
                              </span>
                              <span style={{ fontSize: 14, fontWeight: 700 }}>
                                {coupon.isValid
                                  ? `${(
                                      (+selectedplan?.price || 0) *
                                      (1 - parseFloat(coupon.discount) / 100)
                                    ).toFixed(2)} ${t("SAR")}`
                                  : `${(+selectedplan?.price || 0).toFixed(
                                      2
                                    )} ${t("SAR")}`}
                              </span>
                            </p>
                            {coupon?.isValid && (
                              <p className='coupon-details-row'>
                                <span>
                                  {t("DueAfterTheDiscountExpiresIn")}{" "}
                                  {moment()
                                    .add(
                                      +selectedplan?.duration === 365 ? 12 : 1,
                                      "months"
                                    )
                                    .format("Do MMMM YYYY")}
                                </span>
                                <span style={{ fontSize: 15, fontWeight: 700 }}>
                                  {`${(+selectedplan?.price || 0).toFixed(
                                    2
                                  )} ${t("SAR")}/${
                                    +selectedplan?.duration === 365
                                      ? t("year")
                                      : t("month")
                                  }`}
                                </span>
                              </p>
                            )}
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  <LoadingButton
                    classes='btn primary-btn py-2 text-center d-flex justify-content-center w-100'
                    loading={false}
                    handleClick={() => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        setOpenDemoSnackBar(true);
                      } else {
                        openConfirmPopup();
                        // setIsSubmittingButton(true);
                        // getBrokerRegaInfo({ licenseNumber: advertiserNumber }).then((res) => {
                        //   if (res.resCode === 0) {
                        //     if (res.response.isActive === '1') {
                        //       openConfirmPopup();
                        //     } else {
                        //       Swal.fire({
                        //         title: "لا يمكن إتمام العمليّة",
                        //         text: 'رخصة فال خاصتّك منتهيّة الصلاحيّة',
                        //         icon: "error",
                        //         confirmButtonText: t("Close"),
                        //       });
                        //     }
                        //   }
                        // }).catch(() => openConfirmPopup())
                        //   .finally(() => setIsSubmittingButton(false));
                      }
                    }}
                    label={
                      parseFloat(selectedplan.price) === 0 ||
                      (coupon.isValid &&
                        (
                          parseFloat(selectedplan.price) -
                          (parseFloat(selectedplan.price) *
                            parseFloat(coupon.discount)) /
                            100
                        ).toFixed(1) === "0.0")
                        ? t("Next")
                        : t("Pay")
                    }
                  />

                  <p className='payment-hint'>
                    {t("ByClickingThePayButtonYouAgreeTo")}
                    <a
                      href='https://www.amakkn.com/Terms'
                      rel='noreferrer'
                      target='_blank'
                      style={{
                        textDecoration: "underline",
                        marginRight: "4px",
                      }}>
                      {" "}
                      {t("TermsOfUse")}
                    </a>
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        position: "relative",
                        top: "2px",
                      }}>
                      *
                    </span>
                  </p>
                </DialogContent>
              </Dialog>
            </Hidden>
          </div>
          <Dialog
            onClose={() => {
              setOpenMoyasarForm(false);
            }}
            maxWidth={"sm"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
              "& .MuiPaper-root": {
                margin: 0,
                width: "calc(100% - 20px)",
                zIndex: 9999999,
              },
            }}
            open={openMoyasarForm}>
            <DialogTitle>
              <div className='dialog-head mb-4'>
                <div className='dialog-actions close-only'>
                  <IconButton
                    aria-label='close'
                    onClick={() => {
                      setOpenMoyasarForm(false);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className='mysr-form'></div>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={() => {
              setOpenPaymentsHistoryModal(false);
            }}
            maxWidth={"lg"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
              "& .MuiPaper-root": {
                margin: 0,
                width: "calc(100% - 20px)",
                zIndex: 9999999,
              },
            }}
            open={openPaymentsHistoryModal}>
            <DialogTitle>
              <div className='dialog-head'>
                <div>
                  <h2>{t("PaymentHistory")}</h2>
                </div>
                <div className='dialog-actions'>
                  <span
                    onClick={() => setOpenPaymentsHistoryModal(false)}
                    style={{ cursor: "pointer" }}>
                    <Close />
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  height: "400px",
                  width: "100%",
                  flexDirection: "column",
                  marginTop: 10,
                }}>
                <TableContainer
                  className='table-container responsive-table'
                  component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align='right'>{t("PaymentID")}</TableCell>
                        <TableCell align='right'>
                          {t("PaymentStatus")}
                        </TableCell>
                        <TableCell align='right'>
                          {t("PaymentAmount")}
                        </TableCell>
                        <TableCell align='right'>{t("PaymentDate")}</TableCell>
                        <TableCell align='right'>
                          {t("AdditionalDetails")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loadingPaymentsHistory ? (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell colSpan={5}>
                            <LoadingData />
                          </TableCell>
                        </TableRow>
                      ) : payments?.length > 0 ? (
                        payments.map((row) => (
                          <TableRow
                            key={row.paymentId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              "& td, & th": { pt: 0.7, pb: 0.7 },
                            }}>
                            <TableCell align='right' component='th' scope='row'>
                              {row.paymentId?.slice(-10)}
                            </TableCell>
                            <TableCell
                              align='right'
                              style={{
                                color:
                                  row.paymentStatus === "paid"
                                    ? "green"
                                    : row.paymentStatus === "failed"
                                    ? "#d00404"
                                    : "orange",
                              }}>
                              {row.paymentStatus === "paid"
                                ? t("Paid")
                                : row.paymentStatus === "failed"
                                ? t("Failed")
                                : t("InProgress1")}
                            </TableCell>
                            <TableCell align='right'>
                              {(+row.paymentAmout / 100).toFixed(2) +
                                ` ${t("SAR")}`}
                            </TableCell>
                            <TableCell align='right'>{row.createdAt}</TableCell>
                            <TableCell align='right'>
                              <button
                                onClick={() =>
                                  setOpenPaymentsDetailsModal({
                                    visible: true,
                                    content: (
                                      <>
                                        {row.domain !== "-1" && (
                                          <p>
                                            <b>{t("ReserveTheDomain")}: </b>
                                            {row.domain}
                                          </p>
                                        )}
                                        {row.normal_plan !== "" && (
                                          <p>
                                            <b>{t("SubscribeToThePlan")}: </b>
                                            {
                                              plans.filter(
                                                (i) =>
                                                  +i.id === +row.normal_plan
                                              )?.[0]?.name
                                            }
                                          </p>
                                        )}
                                        {/* {row.plan?.plan?.name !== '' && <p><b>الاشتراك في خطة أماكن برو: </b>{row.plan?.plan?.name}</p>} */}
                                      </>
                                    ),
                                  })
                                }
                                className='custom-btn custom-btn2 btn-info-bg btn-white-bg text-info'>
                                <InfoIcon />
                              </button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell colSpan={5}>
                            <NoData
                              msg={t("ThereAreNoPreviousPaymentsForYou")}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {paymentsTotalCount > pageSize && (
                  <Pagination
                    sx={{
                      "& .MuiPagination-ul": {
                        flexDirection: "row",
                        marginTop: "25px",
                      },
                    }}
                    count={parseInt(Math.ceil(paymentsTotalCount / pageSize))}
                    page={page}
                    onChange={(event, value) => {
                      setPage(+value);
                      getUserPaymentsData(value);
                    }}
                    color='primary'
                  />
                )}
              </div>
            </DialogContent>
          </Dialog>
          {DEMO_VENDORS.includes(vendor) &&
            getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

          <Dialog
            onClose={() =>
              setOpenPaymentsDetailsModal({ visible: false, content: "" })
            }
            maxWidth={"md"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
              "& .MuiPaper-root": {
                margin: 0,
                width: "calc(100% - 20px)",
                zIndex: 9999999,
              },
            }}
            open={openPaymentsDetailsModal.visible}>
            <DialogTitle>
              <div className='dialog-head'>
                <div>
                  <h2>{t("AdditionalDetails")}</h2>
                </div>
                <div className='dialog-actions'>
                  <span
                    onClick={() =>
                      setOpenPaymentsDetailsModal({
                        visible: false,
                        content: "",
                      })
                    }
                    style={{ cursor: "pointer" }}>
                    <Close />
                  </span>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  height: "150px",
                  width: "100%",
                  flexDirection: "column",
                }}>
                {openPaymentsDetailsModal.content}
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={() => setOpenMoyasarForm2(false)}
            maxWidth={"sm"}
            className='custom-dialog'
            style={{ zIndex: 9999999 }}
            sx={{
              "& .MuiPaper-root": {
                margin: 0,
                width: "calc(100% - 20px)",
                zIndex: 9999999,
              },
            }}
            open={openMoyasarForm2}>
            <DialogTitle>
              <div className='dialog-head mb-4'>
                <div className='dialog-actions close-only'>
                  <IconButton
                    aria-label='close'
                    onClick={() => {
                      setOpenMoyasarForm2(false);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <Alert
                severity='info'
                style={{
                  maxWidth: "340px",
                  margin: "0 auto 20px",
                }}
                sx={{
                  "& .MuiAlert-icon": {
                    marginInlineStart: 0,
                    marginInlineEnd: "5px",
                  },
                  "& .MuiAlert-message": {
                    padding: "2px 0",
                  },
                }}>
                {t("YourSubscriptionRenewalProcessWasUnsuccessful")}
              </Alert>
              <div className='mysr-form'></div>
            </DialogContent>
          </Dialog>

          {waitingResponse && (
            <div className='progress-area'>
              <h3>{t("PleaseWait")}</h3>
              <CircularProgress size={100} sx={{ color: "#fff" }} />
            </div>
          )}
        </>
      )}
    </>
  );
}
