import React, { useEffect, useState } from "react";
import { getDashboardWL } from "../../../../api/userApi";
import { Dialog, DialogContent, DialogTitle, Skeleton, Tab, Tabs } from "@mui/material";
import { Close } from "@mui/icons-material";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useAuth } from "../../../../helpers/context";
import { useTranslation } from "react-i18next";

const TopStatistics = () => {
    const { token } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [selectedStatistic, setSelectedStatistic] = useState("");
    const [loadingStatistics, setLoadingStatistics] = useState(true);
    const [duration, setDuration] = useState('yearly');
    const [state, setState] = useState([]);
    const [data, setData] = useState();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (token) {
            setLoadingStatistics(true);
            getDashboardWL({ userId: token, language: i18n.language === "ar" ? '0' : '1' }).then((_userInfo) => {
                if (_userInfo.resCode === 0) {
                    setState(_userInfo.response);
                }
                setLoadingStatistics(false);
            });
        }
    }, [token, i18n.language]);

    const handleDurationClick = (event, value) => {
        setDuration(value);
        switch (value) {
            case 'yearly':
                setData(state.yearly?.[selectedStatistic.key === '1' ? 0 : selectedStatistic.key === '2' ? 1 : selectedStatistic.key === '3' ? 2 : 3]?.sort((a, b) => +a.key - +b.key).map(i => { return { ...i, name: i.key } }))
                break;
            case 'monthly':
                setData(state.monthly?.[selectedStatistic.key === '1' ? 0 : selectedStatistic.key === '2' ? 1 : selectedStatistic.key === '3' ? 2 : 3]?.map(i => { return { ...i, name: resolveMonthName(i.key) } }))
                break;
            default:
                break;
        }
    }

    const handleClose = () => {
        setOpenModal(false);
        setSelectedStatistic('');
        setDuration('yearly');
        setData([]);
    }

    const tabStyle = {
        fontSize: "16px",
        "&.Mui-selected": {
            background: "#fff",
            color: "var(--main-color-one)",
            borderRadius: "4px",
        },
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip d-flex align-items-center flex-column">
                    <p className="label">{`${selectedStatistic.name} ${t('During')} ${label}`}</p>
                    <p>{`${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    const resolveMonthName = (key) => {
        switch (key) {
            case '1': return t("January");
            case '2': return t("February");
            case '3': return t("March");
            case '4': return t("April");
            case '5': return t("May");
            case '6': return t("June");
            case '7': return t("July");
            case '8': return t("August");
            case '9': return t("September");
            case '10': return t("October");
            case '11': return t("November");
            case '12': return t("December");
            default: return '';
        }
    }
    
    return (
        <>
            <div className="dashboard-statictics-wrapper">
                {loadingStatistics ? (
                    [1, 2, 3, 4].map((item) =>
                        <Skeleton
                            className="dashboard-statictics-card loading"
                            variant='rectangular'
                            height={142}
                            key={item}
                        />
                    )
                ) : (
                    state?.general?.length > 0 &&
                    state?.general?.map((item, i) => (
                        <div onClick={() => {
                            setSelectedStatistic(item);
                            setOpenModal(true);
                            setData(state.yearly?.[item.key === '1' ? 0 : item.key === '2' ? 1 : item.key === '3' ? 2 : 3]?.sort((a, b) => +a.key - +b.key).map(i => { return { ...i, name: i.key } }))
                        }}
                            className="dashboard-statictics-card position-relative"
                            key={item.key}
                            // data-aos='flip-left'
                        >
                            <h6>{item.name}</h6>
                            <h5>{item.value}</h5>
                        </div>
                    ))
                )}
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={openModal}
                className="custom-dialog model"
                onClose={handleClose}>
                <DialogTitle>
                    <div className="dialog-head">
                        <div>
                            <h2>{selectedStatistic.name}</h2>
                        </div>
                        <div className="dialog-actions">
                            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                                <Close />
                            </span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='row justify-content-start p-0'>
                        <div style={{ display: 'flex', width: '100%', height: '500px' }}>
                            <div style={{ width: '100%', padding: '0 5px 15px', textAlign: 'center' }}>
                                <Tabs
                                    value={duration}
                                    onChange={handleDurationClick}
                                    centered
                                    sx={{
                                        background: "#EAEAEA",
                                        padding: "5px",
                                        borderRadius: "6px",
                                        width: 'fit-content',
                                        margin: '0 auto 15px'
                                    }}
                                    TabIndicatorProps={{ style: { display: "none" } }}>
                                    <Tab value={'yearly'} label={t("Yearly")} sx={tabStyle} />
                                    <Tab value={'monthly'} label={t("Monthly")} sx={tabStyle} />
                                </Tabs>
                                <ResponsiveContainer width="100%" height="100%" >
                                    <AreaChart
                                        width={'100%'}
                                        height={300}
                                        data={data}
                                        margin={{
                                            top: 5,
                                            bottom: 50,
                                            right: 0,
                                            left: 0
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis width={45} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="var(--main-color-one)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default TopStatistics;
