import React, { useState } from "react";
import CompanyManagerIDVerficationForm from "./CompanyManagerIDVerficationForm";
import AppLayout from "../../layouts/app-layout";
import { Box } from "@mui/material";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";
import CheckNafazOtp from '../../../scenes/amakkn/verify2/components/check-nafaz-otp';
import { requestNafath } from "../../../api/userApi";
import { toast } from "react-toastify";

const publicUrl = process.env.REACT_APP_URL + "/";
// const version2 = window.location.href.includes('version2');

const CompanyManagerIDValidation = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [disable, setDisable] = React.useState(false);
  const { token } = useAuth();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState({
    id: null,
    managerId: "",
    userIdHashed: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  const [isSubmittingVerification, setIsSubmittingVerification] = useState(false);
  const [random, setRandom] = useState('');
  const [transId, setTransId] = useState('');
  
  function handleChange({ target }) {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  }

  // function formIsValid() {
  //   const _errors = {};

  //   if (!user.managerId)
  //     _errors.managerId = "ادخل رقم هوية المالك / المدير للمنشأة";

  //   setErrors(_errors);
  //   // Form is valid if the errors object has no properties
  //   return Object.keys(_errors).length === 0;
  // }

  function handleSubmit(event) {
    event.preventDefault();
    //
    // if (!formIsValid()) return;
    // setDisable(true);

    // userApi.VerifyCompanyManagerID(user).then((_userInfo) => {
    //   if (_userInfo.resCode === 0) {
    //     history("/managerotp/" + user.managerId);
    //     if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
    //   } else {
    //     toast.error(_userInfo.resStr);
    //     setDisable(false);
    //   }
    // });
    // if (!version2) {
    //   window.location.replace("https://iam.amakkn.com");
    // } else {
    
      // setIsStartChecking(true);
      requestNafath({ userId: token, language: i18n.language === 'ar' ? '0' : '1', nationalId: user.managerId }).then(res => {
        if (res.resCode === 0) {
          setRandom(res.response.random);
          setTransId(res.response.transId);
          setIsSubmittingVerification(true);
        } else {
          toast.error(res.resStr);
        }
      }).finally(() => {
        // setIsStartChecking(false);
      });
      
    // }
  }

  return (
    <AppLayout pageTitle={t("AccountVerification")} needAuth>
      <div className="page-wrapper page-width">
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-6 details-wrapper scrolled">
            <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
              <Box
                sx={{
                  marginBottom: "40px",
                  fontSize: "28px",
                  fontWeight: 600,
                  padding: '10px 0',
                  color: "#000",
                }}>
                {t("AccountVerification")}
              </Box>
              {!isSubmittingVerification ? <CompanyManagerIDVerficationForm
                errors={errors}
                user={user}
                setUser={setUser}
                onChange={handleChange}
                onSubmit={handleSubmit}
                disable={disable}
              /> : <CheckNafazOtp random={random} transId={transId} id={user.managerId} setIsSubmittingVerification={setIsSubmittingVerification} />}
              
            </div>
          </div>
          <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="img-container">
              <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CompanyManagerIDValidation;
