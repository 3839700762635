import React from "react";
import SubHeader from "../../../components/global-components/sub-header";
import AgentsPage from "../../amakkn/agents/components/agents";

const ManageAgentsPermissions = () => {
  return (
    <>
      <SubHeader OKElement={<></>} />
      <AgentsPage managePermissions />
    </>
  );
}

export default ManageAgentsPermissions;
