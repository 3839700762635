import React, { useEffect, useState } from "react";
import PropertyBox from "./property-box";
import { Avatar, Box, Grid, useMediaQuery} from "@mui/material";
import NoData from "../global-components/no-data";
import { Mail, Marker, Tel, Twitter, Users } from "../../constants/icons";
import { Facebook, InfoOutlined, Instagram, Person, Summarize } from "@mui/icons-material";
import { getBrokerRegaInfo } from "../../api/userApi";
import { CORPORATE_LICENSE_URI, INDIVIDUAL_LICENSE_URI } from "../../constants";
import { useAuth } from "../../helpers/context.js";
import GoogleMapsContainer from "../../components/googleMapComponent";
import { resolveFacebookLink, resolveInstagramLink, resolveTwitterLink, resolveUserTypeName } from "../../helpers/index.js";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../scenes/shared/properties/components/property-card-for-admin.js";

const publicUrl = process.env.REACT_APP_URL + "/";
const isAdmin = window.location.href.includes("admin");

const CorpreateProfile = (props) => {
  // let pageSize = 6;

  let userType = resolveUserTypeName(props.user.userType);
  const [licenseId, setLicenseId] = useState();
  const [licenseNumber, setLicenseNumber] = useState();
  const { vendor } = useAuth();
  const { t } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    if (props.user && props.user.advertiserNumber)
      getBrokerRegaInfo({
        licenseNumber: props.user.advertiserNumber,
        // licenseType: "Bml"
      }).then(res => {
        if (res.resCode === 0) {
          setLicenseId(res.response.id);
          setLicenseNumber(res.response.licenseNumber);
        } else {
          setLicenseId(undefined);
          setLicenseNumber(undefined);
        }
      }).catch(err => {
        setLicenseId(undefined);
        setLicenseNumber(undefined);
      });

  }, [props.user]);

  return (
    <>
      <div className={`${isAdmin ? 'admin px-3' : ''} page-wrapper page-width company-profile`}>
        <div className="row page-wrapper-without-top" style={{ maxHeight: 'calc(100vh - 100px)' }}>
          <div className="col-12 col-lg-4 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="text-center w-100">
              <Avatar
                alt={props.user.name}
                sx={{ bgcolor: "#fff" }}
                className='company-img'
                src={
                  props.user.avatar
                    ? props.user.avatar
                    : publicUrl +
                    "assets/img/defimgs/" +
                    props.user.accountType +
                    "_account.svg"
                }
              />
                
              <h3 className='pxp-page-header mb-2'> {props.user.name} </h3>
              <div className='mb-2 text-center'>
                <span style={{ fontSize: "16px" }} className='designation'>
                  {userType}
                </span>
                {props.user.isUserVerified === "2" && (
                  <img
                    src={publicUrl + "assets/img/icons/verfiy.svg"}
                    alt='image2'
                  />
                )}
              </div>
              <div className='pt-4 widget-owner-info' style={{ borderTop: '1px solid #ccc' }}>
                  
                <div className='contact-info'>
                  {props.user.aboutCompany && (
                    <div className='media'>
                      <div className='media-left'>
                        <InfoOutlined />
                      </div>
                      <div className='media-body'>
                        <p>{t("AboutCompany")}</p>
                        <span>
                          {props.user.aboutCompany}
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.advertiserNumber && (
                    <div className='media'>
                      <div className='media-left'>
                        <Summarize />
                      </div>
                      <div className='media-body'>
                        <p>{t("FalLicense")}</p>
                        {licenseId ? (
                          <a target="_blank"
                            style={{ color: 'var(--main-color-one)' }}
                            rel="noreferrer"
                            href={props.user.accountType === "1" ? INDIVIDUAL_LICENSE_URI + licenseId : CORPORATE_LICENSE_URI + licenseId}>
                            {licenseNumber}
  
                          </a>
                        ) : <span>
                          {props.user.advertiserNumber}
                        </span>}
                          
                      </div>
                    </div>
                  )}
                  <div className='media'>
                    <div className='media-left'>
                      <Summarize />
                    </div>
                    <div className='media-body'>
                      <p>{props?.user?.commercialRecordNumber?.startsWith('7') ? t("CompanyUnifiedNationalNumber") : t("CommercialRegistrationNo")}</p>
                      <span>
                        {props.user.commercialRecordNumber}
                      </span>
                    </div>
                  </div>
                  {props.user.address && (
                    <div className='media'>
                      <div className='media-left'>
                        <Marker />
                      </div>
                      <div className='media-body'>
                        <p>{t("Address")}</p>
                        {props.user.companyLatitude ? (
                          <a
                            target="_blank"
                            style={{ color: 'var(--main-color-one)' }}
                            rel="noreferrer"
                            href={`https://maps.google.com/?q=${props.user.companyLatitude},${props.user.companyLongitude}`}>
                            {props.user.address}
                          </a>
                        ) : (<span>{props.user.address}</span>)}
                        {props?.plan?.canAddOnMap === "1" ?
                          <div
                            className='col-12 mx-0 mt-3 p-0'
                            style={{ height: "250px", position: "relative" }}
                          >
                            <div className="map-container with-radius">
                              {props.user.companyLatitude && (
                                <GoogleMapsContainer
                                  height="250px"
                                  lat={props.user.companyLatitude}
                                  lng={props.user.companyLongitude}
                                />
                              //   <MapForView
                              //   lat={props.user.companyLatitude}
                              //   lng={props.user.companyLongitude}
                              //   zoom={15}
                              //   height="250px"
                              // />
                              )}
                            </div>
                          </div>
                          : null}
                      </div>
                    </div>
                  )}
                  {props.user.email && (
                    <div className='media'>
                      <div className='media-left'>
                        <Mail />
                      </div>
                      <div className='media-body'>
                        <p>{t("Email")}</p>
                        <a
                          href={
                            "mailto:" + props.user.email
                          }>
                          {props.user.email}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className='media'>
                    <div className='media-left'>
                      <Tel />
                    </div>
                    <div className='media-body'>
                      <p>{t("PhoneNumber")}</p>
                      <a
                        target={isLargeScreen ? '_blank' : "_self"}
                        rel="noreferrer"
                        href={
                          "tel:" +
                          props.user.countryCode +
                          props.user.phone
                        }>
                        0{props.user.phone}
                      </a>
                    </div>
                  </div>
                  {props.user.unifiedNumber && (
                    <div className='media'>
                      <div className='media-left'>
                        <Tel />
                      </div>
                      <div className='media-body'>
                        <p>{t("UnifiedContactNumber")}</p>
                        <span>{props.user.unifiedNumber}</span>
                      </div>
                    </div>
                  )}
                
                  <div className='media'>
                    <div className='media-left'>
                      <Person />
                    </div>
                    <div className='media-body'>
                      <p>{t("ManagerName")}</p>
                      <span>
                        {props.user.managerName}
                      </span>
                    </div>
                  </div>
                 
                  {props.user.userType !== '5' && (
                    <div className='media'>
                      <div className='media-left'>
                        <Users />
                      </div>
                      <div className='media-body'>
                        <p>{t("Agents")}</p>
                        <span>
                          {props.user.agentCount}
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.fb && (
                    <div className='media'>
                      <div className='media-left'>
                        <Facebook />
                      </div>
                      <div className='media-body'>
                        <p>{t("FacebookAccount")}</p>
                        <span>
                          <a
                            target={isLargeScreen ? '_blank' : "_self"}
                            rel="noreferrer"
                            href={resolveFacebookLink(isLargeScreen, props.user.fb)}>
                            {props.user.fb}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.twitter && (
                    <div className='media'>
                      <div className='media-left'>
                        <Twitter small />
                      </div>
                      <div className='media-body'>
                        <p>{t("XAccount")}</p>
                        <span>
                          <a
                            target={isLargeScreen ? '_blank' : "_self"}
                            rel="noreferrer"
                            href={resolveTwitterLink(isLargeScreen, props.user.twitter)}>
                            {props.user.twitter}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.insta && (
                    <div className='media'>
                      <div className='media-left'>
                        <Instagram />
                      </div>
                      <div className='media-body'>
                        <p>{t("InstagramAccount")}</p>
                        <span>
                          <a
                            target={isLargeScreen ? '_blank' : "_self"}
                            rel="noreferrer"
                            href={resolveInstagramLink(isLargeScreen, props.user.insta)}>
                            {props.user.insta}
                          </a>
                        </span>
                      </div>
                    </div>
                      
                  )}
                </div>
              </div>
            </div>
          </div>
  
          <div className="col-12 col-lg-8 details-wrapper scrolled" style={{ overflowY: 'hidden' }}>
            <div className="px-5 pt-5 pt-lg-0">
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "center !important",
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#000",
                }}>
                {props.user.userType !== '5' ? t("CompanyOffers") : t("AdvertiserOffers")}
                  
                {props.totalCount > 0 && (
                  <Box
                    sx={{
                      textAlign: "center !important",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000",
                    }}>
                    {t("ThereAreOffers", { number: props.totalCount })}
                  </Box>
                )}
              </Box>
              <div className='pd-top-30' style={{ overflowY: 'auto', maxHeight: '80vh', paddingBottom: '100px' }} >
                {props.properties.length > 0 ?
                  <Grid container spacing={2}>
                    {props.properties.map((item, i) => (
                      <Grid item xs={12} md={6} lg={4} xl={vendor === 'amakkn' ? 3 : 4}
                        key={i}
                        // className="custom-grid-item"
                        style={{ cursor: "pointer" }}>
                        {vendor === 'amakkn' ? (
                          <PropertyBox
                            propertey={item}
                            key={i}
                            isEdit={false}
                            isFav={true}
                            userId={props.user.id}
                          />
                        ) : (
                          <PropertyCardForAdmin
                            hideActions
                            item={item}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  : <NoData msg={props.user.userType !== '5' ? t("ThereAreNoOffersFromThisCompany") : t("ThereAreNoOffersFromThisAdvertiser")} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CorpreateProfile;
