import React, { useEffect, useMemo, useState } from "react";
import {
  Divider,
  TableContainer,
  Paper,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useAuth } from "../../../helpers/context";
import { CITIES, DISTRICTS } from "../../../constants";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/global-components/loading-btn";
import { MatchedCustomersIcon } from "../../../constants/icons";

import MatchedDataDialog from "./components/matched-data-dialog";
import { renderCustomerSeriousness, renderCustomerType, renderInterestingType, renderPropertyType, trimPhone } from "../../../helpers";
import { connectWithWhatsApp, getCustomer, getUserProile } from "../../../api/userApi";
import md5 from "md5";
import { toast } from "react-toastify";
import SubHeader from "../../../components/global-components/sub-header";

const CustomerDetailsPage = () => {

    const { AccountType, token, UserType, vendor } = useAuth();
    const { t, i18n } = useTranslation();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const [client, setClient] = useState({});
    const [companyName, setCompanyName] = useState("");
    const { id } = useParams();
    const [superToken, setSuperToken] = useState('');

    const citiesOptions = useMemo(
        () => {
            return CITIES.map((city) => ({
                id: +city.CITY_ID,
                label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
                regionId: +city.REGION_ID,
            }))
        },// eslint-disable-next-line
        [i18n.language]);
    
    const districtsOptions = useMemo(
        () => {
            return DISTRICTS.map((district) => ({
                id: +district.DISTRICT_ID,
                label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
                regionId: +district.REGION_ID,
                cityId: +district.CITY_ID,
            }));
        }, // eslint-disable-next-line
        [i18n.language]);
    useEffect(() => {
        connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' });
    }, [token, i18n]);

    useEffect(() => {
        if (token) {
            getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(user => {
                if (user.resCode === 0) {
                    setCompanyName(user.response.userProfile.companyName);
                    if (UserType === "5") {
                        setSuperToken(md5(user.response.userProfile.superUserCreatedAt + user.response.userProfile.superUserId?.[0]));
                    }
                }
            });
          
        }
       
       
          
    },// eslint-disable-next-line
        [token, AccountType, i18n.language]);
    
    const getData = () => {
        getCustomer({
            "vendorName": vendor,
            "language": i18n.language === 'ar' ? '0' : '1',
            "customerId": id
        }).then((res) => {
            if (res.resCode === 0) {
                setClient({ ...res.response, interestingList: res.response.interests });
            } else {
                toast.error(res.resStr);
            }
        });
    }
      
    useEffect(
        () => {
            if (id)
                getData();
        }, // eslint-disable-next-line
        [id, vendor]
    );
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 15,
            fontWeight: 500,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        }
    }));
    const [matchedDataDialog, setMatchedDataDialog] = useState({ visible: false, customer: undefined });

    return (
        <>
            <SubHeader OKElement={<></>} />
            <div className="mb-5">
                

                <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                    {t("BasicInformation")}
                </Divider>
                <div className='row mx-3'>
                    {[
                        {
                            label: t("CustomerName"),
                            value: client.name
                        },
                        {
                            label: t("CustomerType"),
                            value: renderCustomerType(client.type)
                        },
                        {
                            label: t("PhoneNumber"),
                            value: client.phone
                        },
                        {
                            label: t("Email"),
                            value: client.email || t("NotAvailable")
                        },
                        {
                            label: t("CustomerSeriousness"),
                            value: renderCustomerSeriousness(client.seriousness) || t("NotAvailable")
                        },
                        AccountType === '2' ? {
                            label: t("AddedBy"),
                            value: client.owner === companyName ? t("MainAccount") : client.owner
                        } : null,
                        {
                            label: t("CreationDate"),
                            value: client.createdAt ? moment(client.createdAt).locale('en').format("DD-MM-YYYY") : t("NotAvailable")
                        },
                        {
                            label: t("UpdatedAt"),
                            value: client.updatedAt ? moment(new Date(client.updatedAt)).add(3, 'hours').fromNow() : t("NotAvailable")
                        },
                        {
                            label: t("HowDidYouFindUs"),
                            value: client.source ? (+client.source === 0 ? t("OfficeVisit") : +client.source === 1 ? t("PhoneCall") : t("ThroughTheWebsite")) : t("NotAvailable")
                        },
                        {
                            label: t("Notes"),
                            value: client.notes || t("NotAvailable")
                        },
               
                    ]?.map((item) => (
                        <div className={`${item?.label === t("Notes") ? `col-12 mb-4 mb-lg-3 ${item?.value?.length > 70 ? '' : 'col-md-6 col-lg-4'}` : 'col-12 col-md-6 col-lg-4 mb-4 mb-lg-3'} `}
                            key={item?.label} dir={item?.label === t("PhoneNumber") ? 'ltr' : 'rtl'}>
                            <strong>
                                {item?.label}
                            </strong>
                            <span className="d-block mt-2 mb-3">
                                {item?.label === t("PhoneNumber") ?
                                    <a href={`tel:${trimPhone(item?.value)}`}
                                        target={isLargeScreen ? '_blank' : "_self"}
                                        rel="noreferrer"
                                        style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(item?.value)}</a>
                                    : item?.value}
                            </span>
                        </div>
                    ))}
            
                </div>
           
                <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                    {t("ListOfInterests")}
                </Divider>
                <LoadingButton
                    classes="primary-btn btn px-2 mb-3 py-2 mr-3"
                    style={{ width: 'fit-content' }}
                    handleClick={() => setMatchedDataDialog({ visible: true, customer: client })}
                    label={t("ViewListOfMatchedCustomersAndADs")}
                    icon={<MatchedCustomersIcon />}
                />

                <MatchedDataDialog
                    companyName={companyName}
                    superToken={superToken}
                    setMatchedDataDialog={setMatchedDataDialog}
                    matchedDataDialog={matchedDataDialog}
                />

                <div className="row mx-3">
                    {client.interests && client.interests.length > 0 ? (
                        <TableContainer className="table-container responsive-table"
                            component={Paper}>
                            <Table sx={{ minWidth: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='right'>{t("TypeOfInterest")}</StyledTableCell>
                                        <StyledTableCell align='right'>{t("PropertyType")}</StyledTableCell>
                                        <StyledTableCell align='right'>{t("PriceRange")} ({t("SAR")})</StyledTableCell>
                                        <StyledTableCell align='right'>{t("Area")} {t("m2")}</StyledTableCell>
                                        <StyledTableCell align='right'>{t("Address")}</StyledTableCell>
                                        {client.type !== "مالك" && <StyledTableCell align="right">{t("Actions")}</StyledTableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {client.interests.map((item) => (
                                        <StyledTableRow key={item?.id}>
                                            <StyledTableCell align='right'>{renderInterestingType(item?.interestingType)}</StyledTableCell>
                                            <StyledTableCell align='right'>{renderPropertyType(item?.propertyType)}</StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {Intl.NumberFormat("en").format(item?.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item?.priceTo)}
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {item?.interestingType === "بيع" || item?.interestingType === "تأجير" ? (item?.areaFrom ? Intl.NumberFormat("en").format(item?.areaFrom) : t("NotAvailable")) :
                                                    (item?.areaFrom ? Intl.NumberFormat("en").format(item?.areaFrom) : t("NotAvailable")) + ' - ' + (item?.areaTo ? Intl.NumberFormat("en").format(item?.areaTo) : t("NotAvailable"))}
                                            </StyledTableCell>
                                            <StyledTableCell align='right'>
                                                {citiesOptions.filter(
                                                    (i) => +i.id === +item?.city
                                                )?.[0]?.label || null} {' - '}
                                                {districtsOptions.filter(
                                                    (i) => item?.neighbourhood?.includes(+i.id)
                                                )?.map((i, index) => i.label + (index === item?.neighbourhood?.length - 1 ? '' : ', ')) || null}
                                            </StyledTableCell>
                                            {/* {client.type !== "مالك" &&
                                            <StyledTableCell align="right">
                                                {(item?.interestingType === "شراء" || item?.interestingType === "استئجار") && (
                                                    <div className="actions-wrapper">
                                                        <Tooltip title={t("AddDeal")} placement="top">
                                                            <Link to={`/admin/add-deal/${client.id}/${item?.id}${propertyId ? `/${propertyId}` : ''}`}
                                                                className='custom-btn custom-btn2 btn-blue'>
                                                                <AutoGraphIcon />
                                                            </Link>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </StyledTableCell>
                                        } */}
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    ) : (<p className="p-2">{t("NoInterestsFound")}</p>)}
                </div>
            </div>
        </>
    );
}

export default CustomerDetailsPage;
