import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";
import T04AboutUS from "../pro/template-04/about-us";
import T05AboutUS from "../pro/template-05/about-us";
import T06AboutUS from "../pro/template-06/about-us";
import { useMediaQuery } from "@mui/material";
import { translateText } from "../../api/userApi";

const publicUrl = process.env.REACT_APP_URL + "/";

function Process(props) {
  let imagealt = "image";
  const [Data, setData] = useState({
    ...props.data,
    oldTitle1: props.data.title1,
    oldContent: props.data.content
  });
  const ImageTOView = publicUrl + "assets/img/logo/ejar-logo.svg";
  const { templateId, autoTranslate } = useAuth();
  const { t, i18n } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  return (
    <div className={`process-area ${props.isEdit ? 'admin' : ''}`}>
      <div className='row'>
        {MODERN_TEMPLATES.includes(+templateId) && <img src="/assets/img/shape-4.png" alt="sahpe" className="shape" />}
       
        <div className={`col-12 ${props.type === 'broker' ? 'col-lg-6 order-2 order-lg-1' : 'mb-4 mb-lg-0'}`}>
          {props.isEdit ? (
            <>
              {props.type !== "broker" && (
                <div className="d-flex align-items-center mt-4" style={{ gap: '5px' }}>
                  <TextField
                    label={t("Title")}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    // className='mt-4'
                    value={getLocalizedText(Data.title1, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title1, "ar");
                      let englishPart = getLocalizedText(Data.title1, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title1: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                </div>
              )}
                
              <TextField
                label={t("Description")}
                className='mt-3'
                InputLabelProps={{ shrink: true }}
                fullWidth
                inputProps={{ maxLength: 300 }}
                multiline
                rows={6}
                value={getLocalizedText(Data.content, i18n.language)}
                onChange={(v) => {
                  let arabicPart = getLocalizedText(Data.content, "ar");
                  let englishPart = getLocalizedText(Data.content, "en");
                  if (i18n.language === "ar")
                    arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                  else
                    englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                  let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                  setData({ ...Data, content: resolvePersianAndArabicNumbers(text) });
                }}
              />
              <h6 className='rightalign mt-2 mb-0'>
                {Data.content
                  ? parseInt(getLocalizedText(Data.content, i18n.language)?.length) + "/300"
                  : "0/300"}
              </h6>
              {/* {props.type === "broker" && (
                    <TextField
                      label='رابط غوغل'
                      variant='outlined'
                      fullWidth
                      className='mt-4'
                      value={Data.title1}
                      onChange={(v) => {
                        setData({ ...Data, title1: v.currentTarget.value });
                      }}
                    />)} */}
                 
            </>
          ) : CLASSIC_TEMPLATES.includes(+templateId) ? <T04AboutUS type={props.type} data={Data} /> :
            CLASSIC2_TEMPLATES.includes(+templateId) ? <T05AboutUS type={props.type} data={Data} /> :
              MODERN2_TEMPLATES.includes(+templateId) ? <T06AboutUS type={props.type} data={Data} /> :
                (
                  <div data-aos={isLargeScreen ? 'fade-up' : ''} className={`col-12 justify-content-center flex-column d-flex ${DARK_TEMPLATES.includes(+templateId) ? 'aboutus-card col-lg-9 mx-auto' : ''} ${props.type !== 'broker' ? (MODERN_TEMPLATES.includes(+templateId) ? !props?.half ? 'align-items-center' : "" : 'align-items-center') : "broker-flex"}`}>
                    {props.type !== 'broker' && (<>
                      <span className="section-prefix">{t("AboutUS1")}</span>
                      <h2 className='sec-title'>
                        {getLocalizedText(Data.title1, i18n.language, false)}
                      </h2>
                    </>)}
                    <p className={`sec-desc ${[...CLASSIC_TEMPLATES, ...MODERN2_TEMPLATES].includes(+templateId) ? 'col-lg-9' : 'col-lg-11'}`}>
                      {getLocalizedText(Data.content, i18n.language, false)}
                    </p>
                    <Link style={{ marginTop: '20px', maxWidth: 'fit-content' }} className='py-2 px-4 primary-btn' to={props.type !== 'broker' ? "About" : 'ejar'}>
                      {props.type !== 'broker' ? t("ReadTheFullStory") : t("GoToTheRentalAgreement")}
                    </Link>
                  </div>
                )}
        </div>
        {props.type === 'broker' && <div className='order-1 order-lg-2 col-lg-6 d-flex align-self-center mb-4'>
          <div
            className='m-auto'
            data-aos={!props.isEdit && isLargeScreen && "fade-up"}>
            <img
              // className='shadow-img'
              className="broker-img"
              src={ImageTOView} alt={imagealt} />
          </div>
        </div>}
      </div>
      {props.isEdit && <div className='mt-3 col-12'>
        <LoadingButton
          classes="primary-btn m-auto"
          handleClick={async () => {
            setIsSubmittingData(true);
            let oldArabicContent = getLocalizedText(Data.oldContent, "ar"),
              newArabicContent = getLocalizedText(Data.content, "ar"),
              oldArabicTitle1 = getLocalizedText(Data.oldTitle1, "ar"),
              newArabicTitle1 = getLocalizedText(Data.title1, "ar"),
              newContent = Data.content,
              newTitle1 = Data.title1;
            
            if (i18n.language === "ar" && autoTranslate === "1") {
              if (oldArabicContent !== newArabicContent && newArabicContent?.trim() !== "") { 
                let newEnglishContent = await translateText(newArabicContent);
                newEnglishContent = newEnglishContent?.length >= 300 ? newEnglishContent?.substring(0, 300) : newEnglishContent;
                newContent = newArabicContent + '~@~' + newEnglishContent;
              }

              if (oldArabicTitle1 !== newArabicTitle1 && newArabicTitle1?.trim() !=="") {
                let newEnglishTitle1 = await translateText(newArabicTitle1);
                newTitle1 = newArabicTitle1 + '~@~' + newEnglishTitle1;
              }
              setData({ ...Data, content: newContent, title1: newTitle1 });
              props.saveDetails({ data: { ...Data, content: newContent, title1: newTitle1 } });
              setIsSubmittingData(false);
            } else {
              props.saveDetails({ data: Data });
            }
          }}
          label={t("SaveChanges")}
          loading={props.loading || isSubmittingData}
        />
      </div>}
    </div>
  );
}

export default Process;
