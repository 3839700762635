import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditProperty from "./components/edit-property";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import { getAllParamsForAddProperty } from "../../../api/propertyApi";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";
import Forbidden from "../../shared/403";

const EditUnlicensedAD = () => {
  const { token, UserId } = useAuth();
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [user] = useState({
    userId: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  const [AllParams, setAllParams] = useState({});
  const [msg, setMsg] = useState("wait");
  const [UserVerified, setUserVerified] = useState(true);

  useEffect(() => {
    if (token)
      userApi.getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          if (_userInfo.response.userProfile.isUserVerified === "3") {
            userApi.getAdwithoutAdNumber({
              propertyId: id,
              language: i18n.language === 'ar' ? '0' : '1',
              userId: token,
            })
              .then((property) => {
                if (property.resCode === 0) {
                  getAllParamsForAddProperty({
                    propertyType: property.response.propertyType,
                    userId: token,
                    language: i18n.language === 'ar' ? '0' : '1',
                  })
                    .then((params) => {
                      if (params.resCode === 0) {
                        setAllParams(params.response);
                        setMsg("Done");
                      }
                    });
                  
                } else {
                  toast.error(property.resStr);
                }
              });
          } else {
            setUserVerified(false);
            setMsg(t("VerifyTheAccountSoThatYouCanEditTheRealEstateADOnAmakkn"));
          }
        } else {
          const _errors = {};
          _errors.phone = _userInfo.resStr;
        }
      });
  },
    // eslint-disable-next-line
    [UserId, id, token, user]);

  return (
    <AppLayout needAuth pageTitle={t("EditAD")} withoutNav={true}>
      {token && (
        <>
          {msg === "Done" ? <EditProperty
            userId={user.userId}
            propertyId={id}
            AllParams={AllParams}
            isEdit={true}
            isAdmin={true}
          /> : (
            <>
              {msg === 'wait' ?
                <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
                  <CircularProgress />
                </div>
                :
                UserVerified === false ?
                  <Forbidden btnLink={"/verify"} btnText={t("AccountVerification")} desc={msg} />
                  : <Forbidden btnLink={'/'} btnText={t("BackToHomePage")} desc={msg} />
              }
            </>
          )}
        </>
      )}
    </AppLayout>
  );
}

export default EditUnlicensedAD;
